import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TimeFrameSwitch } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { settings, useSetting } from '../../../../../../../../../settings';
import {
  TimeUnit,
  TimeUnitSwitch,
} from '../../../project-planning-view/components/time-unit-switch/TimeUnitSwitch';
import {
  TimelineZoom,
  ZoomValue,
} from '../../../project-planning-view/components/timeline-zoom/TimelineZoom';

interface IProps {
  showDateSelector?: boolean;
  showZoomControl?: boolean;
  showTimeUnitControl?: boolean;
  onDateChange: (startTime: Date, endTime: Date) => void;
  unit: 'day' | 'week' | 'month' | 'year';
  date: Date;
  zoom: ZoomValue;
  onZoomChange: (zoom: ZoomValue) => void;
  onTimeUnitChange: (unit: TimeUnit) => void;
}

export const ResourcesTimelineToolbar = ({
  showDateSelector = true,
  showZoomControl = true,
  showTimeUnitControl = true,
  unit,
  date,
  zoom,
  onDateChange,
  onZoomChange,
  onTimeUnitChange,
}: IProps) => {
  const { t } = useTranslation();

  const resourcePlanningUtilizationTimeUnit = useSetting(
    settings.resourcePlanningUtilizationTimeUnit()
  );

  return (
    <Stack direction="row" padding="0.5rem 0">
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        alignContent="center"
        gap="1rem"
      >
        {showDateSelector && (
          <TimeFrameSwitch
            timeUnit={unit}
            initialTime={new Date(date)}
            onChange={onDateChange}
            showTitle={true}
            todayAction={true}
          />
        )}
        <FormControl style={{ width: '7rem' }}>
          <InputLabel>{t('COMMON.CALCULATION')}</InputLabel>
          <Select
            onChange={(e) =>
              resourcePlanningUtilizationTimeUnit.set(
                e.target.value as 'day' | 'week' | 'month'
              )
            }
            value={resourcePlanningUtilizationTimeUnit.value}
            label="Berechnung"
          >
            {['day', 'week', 'month'].map((unit) => (
              <MenuItem value={unit}>
                <Typography mt="-0.5rem">
                  {' '}
                  {t(`COMMON.${unit.toUpperCase()}`)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        width="100%"
        direction="row"
        gap="1rem"
        padding="0.25rem 1rem"
        justifyContent="end"
        alignContent="center"
        alignItems="center"
      >
        {unit === 'year' && showZoomControl && (
          <>
            <TimelineZoom zoom={zoom} onZoomChange={onZoomChange} />
            <Divider orientation="vertical" style={{ padding: '0.5rem 0' }} />
          </>
        )}
        {showTimeUnitControl && (
          <TimeUnitSwitch unit={unit} onChange={onTimeUnitChange} />
        )}
      </Stack>
    </Stack>
  );
};
