import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { AppointmentAttachment } from './AppointmentAttachment.entity';
import { AppointmentAttendee } from './AppointmentAttendee.entity';
import { AppointmentState } from './AppointmentState.entity';
import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { Contract } from './Contract.entity';
import { CustomField } from './CustomField.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Position } from './Position.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class Appointment<T extends EMode = EMode.entity> {
  /** Alias: anhaenge */
  attachmentList?: AppointmentAttachment<T>[];
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: anzahlTageProjektplanung */
  amountProjectPlaning?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: besuchsberichtCode */
  visitationReportId?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: datumBis */
  endDate?: string;
  /** Alias: datumVon */
  startDate?: string;
  /** Alias: echterTermin */
  isRealAppointment?: boolean;
  /** Alias: erinnern */
  remind?: boolean;
  /** Alias: erinnerung */
  remindDate?: string;
  /** Alias: ersteller */
  creatorId?: number;
  /** Alias: exchangeConversationId */
  exchangeConversationId?: string;
  /** Alias: exchangeId */
  exchangeId?: string;
  /** Alias: exchangeSerientermin */
  exchangeSerialAppointment?: boolean;
  /** Alias: farbenCode */
  colorId?: number;
  /** Alias: ganztags */
  isWholeDay?: boolean;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kostenerfassungCode */
  costAccountingId?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: ort */
  city?: string;
  /** Alias: parentCode */
  parentId?: number;
  /** Alias: positionCode */
  positionId?: number;
  /** Alias: prioritaet */
  priority?: number;
  /** Alias: privat */
  privat?: boolean;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektPlanungArt */
  projectProcessKind?: number;
  /** Alias: projektPlanungCode */
  projectProcessId?: number;
  /** Alias: projektPlanungDauer */
  projectProcessDuration?: number;
  /** Alias: reisekostenabrechnungCode */
  travelExpensesId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: serientermin */
  isSerialAppointment?: boolean;
  /** Alias: serienterminDefinitionCode */
  serialAppointmentDefinitionId?: number;
  /** Alias: teilnehmer */
  appointmentAttendeeList?: AppointmentAttendee<T>[];
  /** Alias: ticketId */
  ticketId?: string;
  /** Alias: titel */
  title?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: urlaubCode */
  vacationId?: number;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: terminFarbe */
  appointmentState?: AppointmentState<T>;
  /** Alias: icsUid */
  icsUid?: string;
  /** Alias: exchangeMeeting */
  exchangeMeeting?: boolean;
  /** Alias: exchangeTeilnehmer */
  exchangeParticipant?: string;
  /** Alias: meetingUrl */
  meetingUrl?: string;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: fromAbsolute */
  fromAbsolute?: string;
  /** Alias: toAbsolute */
  toAbsolute?: string;
  /** Alias: lieferscheinCode */
  deliveryNoteId?: number;
  /** Alias: lieferscheinPositionenCode */
  deliveryNotePositionId?: number;
  /** Alias: auftragCode */
  contractId?: number;
  /** Alias: deliveryNote */
  deliveryNote?: DeliveryNote<T>;
  /** Alias: deliveryNotePosition */
  deliveryNotePosition?: Position<T>;
  /** Alias: contract */
  contract?: Contract<T>;
  /** Alias: contractPosition */
  contractPosition?: Position<T>;
  /** Alias: participantList */
  participantList?: string;

  __typename?: string;
}

const fields: FieldDefinitions<Appointment> = {
  attachmentList: {
    alias: 'anhaenge',
    entity: Entities.appointmentAttachment,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  amountProjectPlaning: {
    alias: 'anzahlTageProjektplanung',
  },
  articleId: {
    alias: 'artikelCode',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  visitationReportId: {
    alias: 'besuchsberichtCode',
  },
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  endDate: {
    alias: 'datumBis',
  },
  startDate: {
    alias: 'datumVon',
  },
  isRealAppointment: {
    alias: 'echterTermin',
  },
  remind: {
    alias: 'erinnern',
  },
  remindDate: {
    alias: 'erinnerung',
  },
  creatorId: {
    alias: 'ersteller',
  },
  exchangeConversationId: {
    alias: 'exchangeConversationId',
  },
  exchangeId: {
    alias: 'exchangeId',
  },
  exchangeSerialAppointment: {
    alias: 'exchangeSerientermin',
  },
  colorId: {
    alias: 'farbenCode',
  },
  isWholeDay: {
    alias: 'ganztags',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  insertTime: {
    alias: 'insertTime',
  },
  costAccountingId: {
    alias: 'kostenerfassungCode',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  note: {
    alias: 'notiz',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  city: {
    alias: 'ort',
  },
  parentId: {
    alias: 'parentCode',
  },
  positionId: {
    alias: 'positionCode',
  },
  priority: {
    alias: 'prioritaet',
  },
  privat: {
    alias: 'privat',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessKind: {
    alias: 'projektPlanungArt',
  },
  projectProcessId: {
    alias: 'projektPlanungCode',
  },
  projectProcessDuration: {
    alias: 'projektPlanungDauer',
  },
  travelExpensesId: {
    alias: 'reisekostenabrechnungCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  isSerialAppointment: {
    alias: 'serientermin',
  },
  serialAppointmentDefinitionId: {
    alias: 'serienterminDefinitionCode',
  },
  appointmentAttendeeList: {
    alias: 'teilnehmer',
    entity: Entities.appointmentAttendee,
  },
  ticketId: {
    alias: 'ticketId',
  },
  title: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  vacationId: {
    alias: 'urlaubCode',
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  appointmentState: {
    alias: 'terminFarbe',
    entity: Entities.appointmentState,
  },
  icsUid: {
    alias: 'icsUid',
  },
  exchangeMeeting: {
    alias: 'exchangeMeeting',
  },
  exchangeParticipant: {
    alias: 'exchangeTeilnehmer',
  },
  meetingUrl: {
    alias: 'meetingUrl',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  fromAbsolute: {
    alias: 'fromAbsolute',
  },
  toAbsolute: {
    alias: 'toAbsolute',
  },
  deliveryNoteId: {
    alias: 'lieferscheinCode',
  },
  deliveryNotePositionId: {
    alias: 'lieferscheinPositionenCode',
  },
  contractId: {
    alias: 'auftragCode',
  },
  deliveryNote: {
    alias: 'deliveryNote',
    entity: Entities.deliveryNote,
  },
  deliveryNotePosition: {
    alias: 'deliveryNotePosition',
    entity: Entities.position,
  },
  contract: {
    alias: 'contract',
    entity: Entities.contract,
  },
  contractPosition: {
    alias: 'contractPosition',
    entity: Entities.position,
  },
  participantList: {
    alias: 'participantList',
  },
  __typename: {
    alias: '__typename',
  },
};

export const appointmentEntityDefinition: EntitiyDefinition<Appointment> = {
  local: {},
  remote: {
    queryName: 'getTermine',
    fragmentName: 'Termin',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertTermin',
        args: [
          { name: 'input', type: 'InputTermin!' },
          { name: 'relations', type: 'InputTerminRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
