import styles from './ProjectDirectoryPopover.module.scss';

import { Button, RadioGroup, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useDataProvider, useFormPlus } from '@work4all/data';

import { FileServiceProviderConfig } from '@work4all/models/lib/Classes/FileServiceProviderConfig.entity';
import { InputProjektRelation } from '@work4all/models/lib/Classes/InputProjektRelation.entity';
import { ProjectGroup } from '@work4all/models/lib/Classes/ProjectGroup.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { CreateProjectDirectoryType } from '@work4all/models/lib/Enums/CreateProjectDirectoryType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDebounce } from '@work4all/utils/lib/hooks/use-debounce';

import { ControllerPlus } from '../../containers/mask-overlays/form-plus/controller-plus';
import { ControlWrapper } from '../../containers/mask-overlays/mask-overlay/components';

import { useValidateDirectoryPath } from './use-validate-directory-path';

type ProjectDirectoryValues = {
  directoryType: CreateProjectDirectoryType;
  directoryName: string | null;
  customDirectoryName: string | null;
  fspId: string | null;
};

interface ProjectDirectoryPopoverProps {
  open: boolean;
  onSelect: (directoryRelations: InputProjektRelation) => void;
  onClose: () => void;
  projectNumber?: string;
  projectName: string;
  predefinedGroupDirectory?: string;
  predefinedCustomerDirectory?: string;
}

export const FILE_SERVICE_PROVIDER_DATA: FileServiceProviderConfig = {
  id: null,
  name: null,
  directoryNameConvertPath: null,
  directoryNameReplacePath: null,
  isWorkDirectory: null,
  providerType: null,
};

export const PROJECT_GROUPS_DATA: ProjectGroup = {
  id: null,
  name: null,
  projectDirectoryPath: null,
};

export const ProjectDirectoryPopover = (
  props: ProjectDirectoryPopoverProps
) => {
  if (!props.open) {
    return null;
  }

  return <ProjectDirectoryPopoverContent {...props} />;
};

export const ProjectDirectoryPopoverContent = ({
  open,
  onSelect,
  onClose,
  projectNumber,
  projectName,
  predefinedGroupDirectory,
  predefinedCustomerDirectory,
}: ProjectDirectoryPopoverProps) => {
  const { t } = useTranslation();

  const defaultDirectoryName = `${
    projectNumber ?? ''
  } ${projectName}`.trimStart();
  const form = useFormPlus<ProjectDirectoryValues>({
    defaultValues: {
      directoryType: CreateProjectDirectoryType.NEW_PROJEKT_DIR,
      directoryName: defaultDirectoryName,
    },
  });

  const { control, register, handleSubmit, watch } = form;

  const directoryType = watch('directoryType');
  const customDirectoryName = watch('customDirectoryName');

  const fileServiceProviderRequest = useMemo<DataRequest>(
    () => ({
      data: FILE_SERVICE_PROVIDER_DATA,
      entity: Entities.fileServiceProviderConfig,
      completeDataResponse: true,
      skip: false,
      operationName: 'GetFileServiceProvider',
    }),
    []
  );
  const { data: fileServiceProviderData } = useDataProvider(
    fileServiceProviderRequest
  );

  const predefinedGroupDirectoryFsp = fileServiceProviderData
    .find((data) =>
      predefinedGroupDirectory?.includes(data?.directoryNameConvertPath?.at(-1))
    )
    ?.directoryNameConvertPath?.at(-1);
  const predefinedPath = predefinedGroupDirectory
    ?.substring(predefinedGroupDirectory?.indexOf(predefinedGroupDirectoryFsp))
    ?.replace(/\\\\/g, '\\');

  const onSubmit = useCallback(
    async (data: ProjectDirectoryValues) => {
      const relations: InputProjektRelation = {
        setProjectDirectory: {
          createProjectDirectoryType: data.directoryType,
          projectDirectoryName:
            data.directoryType === CreateProjectDirectoryType.EXISTING_DIRECTORY
              ? data.customDirectoryName
              : undefined,
        },
      };

      onSelect(relations);
    },
    [onSelect]
  );

  const [visited, setVisited] = useState<
    Set<ProjectDirectoryValues['directoryType']>
  >(new Set([CreateProjectDirectoryType.NEW_PROJEKT_DIR]));

  const parts = predefinedCustomerDirectory?.split('\\') || [];
  const predefinedCustomerPath = parts[parts.length - 1];
  const debounced = useDebounce(
    customDirectoryName?.length > 3 ? customDirectoryName : '',
    300
  );
  const { isValid: isExstingValid, loading: isExstingLoading } =
    useValidateDirectoryPath(debounced);
  const { isValid: isProjectValid, loading: isProjectLoading } =
    useValidateDirectoryPath(predefinedGroupDirectory);
  const { isValid: isCustomerValid, loading: isCustomerLoading } =
    useValidateDirectoryPath(predefinedCustomerDirectory);

  const loading = isExstingLoading || isProjectLoading || isCustomerLoading;

  const disabled =
    loading ||
    (directoryType === CreateProjectDirectoryType.NEW_PROJEKT_DIR &&
      !isProjectValid) ||
    (directoryType === CreateProjectDirectoryType.CUSTOMER_DIRECTORY &&
      !isCustomerValid) ||
    (directoryType === CreateProjectDirectoryType.EXISTING_DIRECTORY &&
      !isExstingValid);
  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        onClose={onClose}
        closeButton={false}
        title={t('PROJECT_DIRECTORY.DIALOG_TITLE')}
        classes={{ dialog: { paper: styles.dialog } }}
      >
        <DialogContent>
          <ControllerPlus
            control={control}
            name="directoryType"
            render={({ field }) => (
              <ControlWrapper as={RadioGroup} grid paddingTop paddingBottom>
                <Stack gap="2rem">
                  <CheckboxRadioItem
                    horizontalPadding={false}
                    label={t('PROJECT_DIRECTORY.DEFAULT.LABEL')}
                    caption={
                      <Stack gap="0.5rem" mt="0.5rem">
                        <Typography
                          className={styles.caption}
                          variant="caption"
                        >
                          {t('PROJECT_DIRECTORY.DEFAULT.CAPTION')}
                        </Typography>
                      </Stack>
                    }
                    value={CreateProjectDirectoryType.DEFAULT}
                    control="radio"
                    onChange={(e) => {
                      setVisited((prevVisited) =>
                        new Set(prevVisited).add(
                          CreateProjectDirectoryType.DEFAULT
                        )
                      );
                      field.onChange(e.target.value);
                    }}
                    checked={field.value === CreateProjectDirectoryType.DEFAULT}
                  />
                  {predefinedGroupDirectory && (
                    <CheckboxRadioItem
                      horizontalPadding={false}
                      label={t('PROJECT_DIRECTORY.NEW.LABEL')}
                      caption={
                        <Stack gap="0.5rem" mt="0.5rem">
                          <Typography
                            className={styles.caption}
                            variant="caption"
                          >
                            {t('PROJECT_DIRECTORY.NEW.CAPTION')}
                          </Typography>
                          {((!isProjectValid &&
                            visited.has(
                              CreateProjectDirectoryType.CUSTOMER_DIRECTORY
                            )) ||
                            directoryType ===
                              CreateProjectDirectoryType.NEW_PROJEKT_DIR) && (
                            <LabeledInput
                              disabled
                              label={t('FIELDS.folder')}
                              required
                              startAdornment={
                                <span className={styles.startAdornment}>
                                  {predefinedPath}
                                </span>
                              }
                              className={styles.inputValue}
                              autoComplete="off"
                              autoFocus={true}
                              {...register('directoryName')}
                            />
                          )}
                          {!isProjectValid &&
                            visited.has(
                              CreateProjectDirectoryType.CUSTOMER_DIRECTORY
                            ) && (
                              <Typography variant="caption" color="error">
                                {t('PROJECT_DIRECTORY.ERROR')}
                              </Typography>
                            )}
                        </Stack>
                      }
                      value={CreateProjectDirectoryType.NEW_PROJEKT_DIR}
                      control="radio"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.setValue('directoryName', defaultDirectoryName);
                        setVisited((prevVisited) =>
                          new Set(prevVisited).add(
                            CreateProjectDirectoryType.NEW_PROJEKT_DIR
                          )
                        );
                      }}
                      checked={
                        field.value ===
                        CreateProjectDirectoryType.NEW_PROJEKT_DIR
                      }
                    />
                  )}
                  {predefinedCustomerDirectory && (
                    <CheckboxRadioItem
                      horizontalPadding={false}
                      label={t('PROJECT_DIRECTORY.CUSTOMER.LABEL')}
                      caption={
                        <Stack gap="0.5rem" mt="0.5rem">
                          <Typography
                            className={styles.caption}
                            variant="caption"
                          >
                            {t('PROJECT_DIRECTORY.CUSTOMER.CAPTION')}
                          </Typography>
                          {((!isCustomerValid &&
                            visited.has(
                              CreateProjectDirectoryType.CUSTOMER_DIRECTORY
                            )) ||
                            directoryType ===
                              CreateProjectDirectoryType.CUSTOMER_DIRECTORY) && (
                            <LabeledInput
                              disabled
                              label={t('FIELDS.folder')}
                              required
                              startAdornment={
                                <span className={styles.startAdornment}>
                                  {predefinedCustomerPath}
                                </span>
                              }
                              className={styles.inputValue}
                              autoComplete="off"
                              autoFocus={true}
                              {...register('directoryName')}
                            />
                          )}
                          {!isCustomerValid &&
                            visited.has(
                              CreateProjectDirectoryType.CUSTOMER_DIRECTORY
                            ) && (
                              <Typography variant="caption" color="error">
                                {t('PROJECT_DIRECTORY.ERROR')}
                              </Typography>
                            )}
                        </Stack>
                      }
                      value={CreateProjectDirectoryType.CUSTOMER_DIRECTORY}
                      control="radio"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.setValue('directoryName', defaultDirectoryName);
                        setVisited((prevVisited) =>
                          new Set(prevVisited).add(
                            CreateProjectDirectoryType.CUSTOMER_DIRECTORY
                          )
                        );
                      }}
                      checked={
                        field.value ===
                        CreateProjectDirectoryType.CUSTOMER_DIRECTORY
                      }
                    />
                  )}

                  <CheckboxRadioItem
                    horizontalPadding={false}
                    label={t('PROJECT_DIRECTORY.EXISTING.LABEL')}
                    caption={
                      <Stack gap="0.5rem" mt="0.5rem">
                        <Typography
                          className={styles.caption}
                          variant="caption"
                        >
                          {t('PROJECT_DIRECTORY.EXISTING.CAPTION')}
                        </Typography>
                        {directoryType ===
                          CreateProjectDirectoryType.EXISTING_DIRECTORY && (
                          <>
                            <LabeledInput
                              label={t('FIELDS.folder')}
                              required
                              className={styles.inputValue}
                              autoComplete="off"
                              autoFocus={true}
                              {...register('customDirectoryName')}
                            />
                            {!isExstingValid &&
                              customDirectoryName?.length > 3 && (
                                <Typography variant="caption" color="error">
                                  {t('PROJECT_DIRECTORY.ERROR')}
                                </Typography>
                              )}
                          </>
                        )}
                      </Stack>
                    }
                    value={CreateProjectDirectoryType.EXISTING_DIRECTORY}
                    control="radio"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    checked={
                      field.value ===
                      CreateProjectDirectoryType.EXISTING_DIRECTORY
                    }
                  />
                </Stack>
              </ControlWrapper>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button size="large" color="primary" type="button" onClick={onClose}>
            {t('COMMON.ABORT')}
          </Button>
          <Button
            size="large"
            color="primary"
            type="button"
            disabled={disabled}
            onClick={handleSubmit(onSubmit)}
          >
            {t('COMMON.CREATE')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};
