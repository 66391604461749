import { inputClasses } from '@mui/material/Input';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

import { breakpoints } from '@work4all/utils/lib/variables';

import { colorSchemes } from './color-schemes';

export const theme = extendTheme({
  colorSchemes: colorSchemes,
  spacing: 7,
  breakpoints: {
    values: breakpoints,
  },
  typography: {
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.85rem',
      lineHeight: 1.33,
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.375rem',
      lineHeight: '2rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'body1' && {
            color: theme.vars.palette.text.primary,
          }),
          ...(ownerState.variant === 'caption' && {
            color: theme.vars.palette.text.tertiary,
          }),
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.vars.palette.ui7.main,
          color: theme.vars.palette.text.inverse,
          fontSize: '0.75rem',
        }),
        arrow: ({ theme }) => ({
          color: theme.vars.palette.ui7.main,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: theme.vars.palette.primary.main,
              color: theme.vars.palette.primary.main,
            }),
          ...(ownerState.variant === 'text' && {
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: 1.5,
          }),
          ...(ownerState.size === 'large' && {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 400,
            paddingTop: '0.75rem',
            paddingRight: '1rem',
            paddingBottom: '0.75rem',
            paddingLeft: '1rem',
          }),
          ...(ownerState.size === 'large' &&
            ownerState.variant === 'contained' && {
              fontWeight: 900,
            }),
        }),
        containedPrimary: {
          color: 'white',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            ...(ownerState.color === 'default' && {
              color: 'var(--text01)',
            }),
            '&.Mui-disabled': {
              color: 'var(--ui07)',
              opacity: 0.26,
            },
          };
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          ...(ownerState.color === 'primary' && { color: 'white' }),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.text.tertiary,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        select: {
          paddingTop: 21,
          paddingBottom: 4,
        },
      },
    },
    MuiDivider: {
      defaultProps: { textAlign: 'left' },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderColor: theme.vars.palette.ui4.main,

          ...(ownerState.orientation === 'horizontal' &&
            ownerState.textAlign === 'left' && {
              '&::before': {
                display: 'none',
              },
            }),

          ...(ownerState.orientation === 'horizontal' &&
            ownerState.textAlign === 'right' && {
              '&::after': {
                display: 'none',
              },
            }),
        }),
        wrapper: ({ theme, ownerState }) => ({
          ...theme.typography.caption,
          color: theme.vars.palette.text.tertiary,

          ...(ownerState.orientation === 'horizontal' &&
            ownerState.textAlign === 'left' && {
              paddingLeft: 0,
            }),

          ...(ownerState.orientation === 'horizontal' &&
            ownerState.textAlign === 'right' && {
              paddingRight: 0,
            }),
        }),

        textAlignLeft: {},
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.ui5.main,
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          [`&.${inputClasses.disabled}`]: {
            WebkitTextFillColor: 'var(--text03)',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: 30,
          color: theme.vars.palette.text.tertiary,
        }),
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 30,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.vars.palette.ui1.main,
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          color: 'white',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '2.5rem',
        },
        flexContainer: {
          gap: '0.5rem',
        },
      },
      defaultProps: {
        TabIndicatorProps: {
          hidden: true,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.vars.palette.text.primary,
          borderColor: theme.vars.palette.ui4.main,
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.vars.palette.text.tertiary,
          },
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          hover: ({ theme }) => ({
            backgroundColor: theme.vars.palette.brand02,
          }),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.vars.palette.ui3.main,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: 'auto',
          minHeight: 'auto',
          textTransform: 'unset',
          opacity: 1,
          padding: '0.5rem',
          color: theme.vars.palette.text.primary,
          fontSize: '1rem',
          lineHeight: 1.5,
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
          borderRadius: '0.25rem',

          '&.Mui-selected': {
            '&::after': {
              content: '""',
              position: 'absolute',
              inset: 0,
              backgroundColor: 'currentColor',
              opacity: 0.1,
              pointerEvents: 'none',
            },
          },

          '&.Mui-disabled': {
            color: 'var(--text03)',
            opacity: 0.8,
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.disabled && {
            opacity: 0.5,
          }),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--ui01)',
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.vars.palette.text.tertiary + ' !important',
          },
        }),
      },
    },
  },
});
