import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DueDateClassified } from '../Enums/DueDateClassified.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { PaymentStatus } from '../Enums/PaymentStatus.enum';
import { ReAccountingStatus } from '../Enums/ReAccountingStatus.enum';
import { REBelegart } from '../Enums/REBelegart.enum';
import { RePermitStatus } from '../Enums/RePermitStatus.enum';

import { BankDetails } from './BankDetails.entity';
import { Currency } from './Currency.entity';
import { File } from './File.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { InboundInvoicePayment } from './InboundInvoicePayment.entity';
import { Order } from './Order.entity';
import { PaymentKind } from './PaymentKind.entity';
import { RELedgerAccountSplit } from './RELedgerAccountSplit.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class ReViewModel<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: exchangeRate */
  exchangeRate?: number;
  /** Alias: currencyCode */
  currencyId?: number;
  /** Alias: deductionDiscount */
  deductionDiscount?: number;
  /** Alias: isPayed */
  isPayed?: boolean;
  /** Alias: amountGross */
  amountGross?: number;
  /** Alias: dateOfReceipt */
  dateOfReceipt?: string;
  /** Alias: permitByUserCode */
  approvedByUserId?: number;
  /** Alias: text */
  note?: string;
  /** Alias: netValue */
  netValue?: number;
  /** Alias: discountPerCent */
  discountPerCent?: number;
  /** Alias: discountDate */
  discountDate?: string;
  /** Alias: dueDate */
  dueDate?: string;
  /** Alias: originalCurrencyValue */
  originalCurrencyValue?: number;
  /** Alias: userCode */
  userId?: number;
  /** Alias: bookingDate */
  bookingDate?: string;
  /** Alias: inAccounting */
  inAccounting?: boolean;
  /** Alias: originalInvoiceNumber */
  originalInvoiceNumber?: string;
  /** Alias: valueVat */
  valueVat?: number;
  /** Alias: reference */
  reference?: string;
  /** Alias: paymentStatus */
  paymentStatus?: PaymentStatus;
  /** Alias: accountingStatus */
  accountingStatus?: ReAccountingStatus;
  /** Alias: currency */
  currency?: Currency<T>;
  /** Alias: permitByUser */
  approvedByUser?: User<T>;
  /** Alias: user */
  user?: User<T>;
  /** Alias: supplier */
  supplier?: Supplier<T>;
  /** Alias: invoiceNumber */
  invoiceNumber?: number;
  /** Alias: invoiceDate */
  invoiceDate?: string;
  /** Alias: valueNotPayed */
  valueNotPayed?: number;
  /** Alias: invoiceType */
  invoiceKind?: REBelegart;
  /** Alias: paymentCode */
  paymentId?: number;
  /** Alias: dueDays */
  dueDays?: number;
  /** Alias: dueClassified */
  dueDateClassified?: DueDateClassified;
  /** Alias: permitNote */
  permitNote?: string;
  /** Alias: permitState */
  permitStatus?: RePermitStatus;
  /** Alias: payment */
  paymentKind?: PaymentKind<T>;
  /** Alias: bookings */
  bookings?: RELedgerAccountSplit<T>[];
  /** Alias: receipts */
  receipts?: File<T>[];
  /** Alias: payments */
  payments?: InboundInvoicePayment<T>[];
  /** Alias: blockedByUserCode */
  blockedByUserId?: number;
  /** Alias: paymentTermDays */
  paymentTermDays?: number;
  /** Alias: planedDateOfDelivery */
  plannedDeliveryDate?: string;
  /** Alias: dateOfDelivery */
  deliveryDate?: string;
  /** Alias: bankAccountCode */
  bankAccountId?: number;
  /** Alias: approvalUserCode */
  releasedByUserId?: number;
  /** Alias: discountDays */
  discountDays?: number;
  /** Alias: discountDays2 */
  discount2Days?: number;
  /** Alias: discount2 */
  discount2?: number;
  /** Alias: discountDate2 */
  discount2Date?: string;
  /** Alias: blockedByUser */
  blockedByUser?: User<T>;
  /** Alias: approvalUser */
  releasedByUser?: User<T>;
  /** Alias: bankAccount */
  bankAccount?: BankDetails<T>;
  /** Alias: deliveryNotes */
  inboundDeliveryNotes?: InboundDeliveryNote<T>[];
  /** Alias: orders */
  orders?: Order<T>[];
  /** Alias: valuePayed */
  paidValue?: number;
  /** Alias: datevDate */
  datevDate?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ReViewModel> = {
  id: {
    alias: 'code',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  exchangeRate: {
    alias: 'exchangeRate',
  },
  currencyId: {
    alias: 'currencyCode',
  },
  deductionDiscount: {
    alias: 'deductionDiscount',
  },
  isPayed: {
    alias: 'isPayed',
  },
  amountGross: {
    alias: 'amountGross',
  },
  dateOfReceipt: {
    alias: 'dateOfReceipt',
  },
  approvedByUserId: {
    alias: 'permitByUserCode',
  },
  note: {
    alias: 'text',
  },
  netValue: {
    alias: 'netValue',
  },
  discountPerCent: {
    alias: 'discountPerCent',
  },
  discountDate: {
    alias: 'discountDate',
  },
  dueDate: {
    alias: 'dueDate',
  },
  originalCurrencyValue: {
    alias: 'originalCurrencyValue',
  },
  userId: {
    alias: 'userCode',
  },
  bookingDate: {
    alias: 'bookingDate',
  },
  inAccounting: {
    alias: 'inAccounting',
  },
  originalInvoiceNumber: {
    alias: 'originalInvoiceNumber',
  },
  valueVat: {
    alias: 'valueVat',
  },
  reference: {
    alias: 'reference',
  },
  paymentStatus: {
    alias: 'paymentStatus',
  },
  accountingStatus: {
    alias: 'accountingStatus',
  },
  currency: {
    alias: 'currency',
    entity: Entities.currency,
  },
  approvedByUser: {
    alias: 'permitByUser',
    entity: Entities.user,
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  invoiceNumber: {
    alias: 'invoiceNumber',
  },
  invoiceDate: {
    alias: 'invoiceDate',
  },
  valueNotPayed: {
    alias: 'valueNotPayed',
  },
  invoiceKind: {
    alias: 'invoiceType',
  },
  paymentId: {
    alias: 'paymentCode',
  },
  dueDays: {
    alias: 'dueDays',
  },
  dueDateClassified: {
    alias: 'dueClassified',
  },
  permitNote: {
    alias: 'permitNote',
  },
  permitStatus: {
    alias: 'permitState',
  },
  paymentKind: {
    alias: 'payment',
    entity: Entities.paymentKind,
  },
  bookings: {
    alias: 'bookings',
    entity: Entities.rELedgerAccountSplit,
  },
  receipts: {
    alias: 'receipts',
    entity: Entities.file,
  },
  payments: {
    alias: 'payments',
    entity: Entities.inboundInvoicePayment,
  },
  blockedByUserId: {
    alias: 'blockedByUserCode',
  },
  paymentTermDays: {
    alias: 'paymentTermDays',
  },
  plannedDeliveryDate: {
    alias: 'planedDateOfDelivery',
  },
  deliveryDate: {
    alias: 'dateOfDelivery',
  },
  bankAccountId: {
    alias: 'bankAccountCode',
  },
  releasedByUserId: {
    alias: 'approvalUserCode',
  },
  discountDays: {
    alias: 'discountDays',
  },
  discount2Days: {
    alias: 'discountDays2',
  },
  discount2: {
    alias: 'discount2',
  },
  discount2Date: {
    alias: 'discountDate2',
  },
  blockedByUser: {
    alias: 'blockedByUser',
    entity: Entities.user,
  },
  releasedByUser: {
    alias: 'approvalUser',
    entity: Entities.user,
  },
  bankAccount: {
    alias: 'bankAccount',
    entity: Entities.bankDetails,
  },
  inboundDeliveryNotes: {
    alias: 'deliveryNotes',
    entity: Entities.inboundDeliveryNote,
  },
  orders: {
    alias: 'orders',
    entity: Entities.order,
  },
  paidValue: {
    alias: 'valuePayed',
  },
  datevDate: {
    alias: 'datevDate',
  },
  __typename: {
    alias: '__typename',
  },
};

export const reViewModelEntityDefinition: EntitiyDefinition<ReViewModel> = {
  local: {},
  remote: {
    queryName: 'getIncomingInvoiceViewModel',
    fragmentName: 'ReViewModel',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
