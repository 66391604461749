import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardWidget } from '@work4all/components/lib/dataDisplay/card-widget';

import { useDataProvider, useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { mutationEntityToViewEntity } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import {
  widgetColsByEntity,
  widgetDataByEntity,
} from './default-entity-widget-data';
import { widgetSortOptionsByEntity } from './default-entity-widget-sort-options';
import { HomeWidget } from './HomeWidgetsContextProvider';

const DEFAULT_FILTER = [];
export const EntityWidget = (
  props: HomeWidget & {
    onDeleteClick?: () => void;
    onEditClick?: () => void;
    pageSize?: number;
    onCollapsedChange?: (value: boolean) => void;
    collapsible?: boolean;
    collapsed?: boolean;
  }
) => {
  const { t } = useTranslation();

  const {
    pageSize,
    definition,
    onEditClick,
    onDeleteClick,
    title,
    collapsed,
    onCollapsedChange,
  } = props;

  const { filter = DEFAULT_FILTER, tableUrl } = definition;

  const searchParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('settings', tableUrl);

    return searchParams;
  }, [tableUrl]);

  // We are doing this for backward compatibility for entities like `inboundInvoice`.
  const viewEntity = mutationEntityToViewEntity(definition?.entity);

  const prefilter = useDeepMemo(
    () => props.definition.prefilter,
    [props.definition.prefilter]
  );

  const customizedTitle =
    title ||
    definition.variant ||
    t(`COMMON.${definition.entity.toUpperCase()}`, {
      count: 2,
    });

  const requestData = useMemo<DataRequest>(() => {
    const incomingFilter = [...(filter ?? []), ...(prefilter ?? [])];

    return {
      filter: incomingFilter,
      entity: viewEntity,
      data: { id: null, ...widgetDataByEntity[viewEntity] },
      sort: widgetSortOptionsByEntity[viewEntity],
    };
  }, [filter, viewEntity, prefilter]);

  const { data, total } = useDataProvider(requestData, false, pageSize);

  const { handleClick, ...popoverState } = usePopoverState('bottom');

  const navigate = useNavigate();

  const listHrefEntitiy = useMemo(() => {
    if (definition?.variant) return `${viewEntity}(${definition?.variant})`;

    return viewEntity;
  }, [definition?.variant, viewEntity]);

  return (
    <>
      <Popover
        {...popoverState}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <List disablePadding>
          {onEditClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onEditClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('SETTINGS.SETTINGS')} />
              </ListItemButton>
            </ListItem>
          )}
          {onDeleteClick && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onDeleteClick();
                  popoverState.onClose();
                }}
              >
                <ListItemText primary={t('COMMON.REMOVE')} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
      <CardWidget
        entity={viewEntity}
        columns={widgetColsByEntity[viewEntity]}
        getItemHref={() => ''}
        listHref={`/more/entity/${listHrefEntitiy}?${searchParams.toString()}`}
        maxRows={total}
        newHref={`/more/entity/${viewEntity}/details/new`}
        items={data}
        title={
          customizedTitle || t(`COMMON.${viewEntity.toUpperCase()}_plural`)
        }
        totalCount={total}
        collapsible={true}
        collapsed={collapsed}
        onItemClick={(item) => {
          navigate(`/more/entity/${viewEntity}/details/${item.id}`);
        }}
        onCollapsedChange={onCollapsedChange}
        additionalActions={
          <IconButton size="medium" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        }
      />
    </>
  );
};
