import _, { partition } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import { useHistoryStackTitleUpdate } from '@work4all/components/lib/navigation/hooks/use-history-stack-title-update';

import { useDataMutation, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputCrmAnhangAttachementsRelation.entity';
import { InputTerminRelation } from '@work4all/models/lib/Classes/InputTerminRelation.entity';
import { InputTerminTeilnehmerAdd } from '@work4all/models/lib/Classes/InputTerminTeilnehmerAdd.entity';
import { InputTerminTeilnehmerRelation } from '@work4all/models/lib/Classes/InputTerminTeilnehmerRelation.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { fixAppointmentStartEndDates } from '@work4all/utils';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { getEdgeDate } from '../../../../calendar/hooks/use-calendar-hours-config';
import { EmailTemplateButtonProvider } from '../../../components/email-template-button/EmailTemplateButtonProvider';
import { EmailTemplateIconButton } from '../../../components/email-template-button/EmailTemplateIconButton';
import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import { useMaskConfig } from '../../hooks/mask-context';
import { useAfterSave } from '../../hooks/use-after-save';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { useAppointmentDefaultData } from './create-appointment-default-data';
import { useAppointmentDataRequest } from './hooks/use-appointment-data-request';
import { useAppointmentFormUpdate } from './hooks/use-appointment-form-update';
import { AppointmentMaskFormValue } from './types';
import { mapAppointmentToEmailParams } from './utils/mapAppointmentToEmailParams';

export const AppointmentOverlayController = (props: MaskControllerProps) => {
  const user = useUser();

  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: Entities.appointment });

  const customRules = useCallback(
    (data) => {
      const atendees: AppointmentAttendee[] =
        data.appointmentAttendeeList || [];
      const hasAtLeastOneUser = atendees.find((el) => el.user || el.ressource);
      if (!hasAtLeastOneUser) {
        return {
          appointmentAttendeeList: {
            message: t('ERROR.MIN_ONE_USER_PER_APPOINTMENT'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const request = useAppointmentDataRequest(mask.id);
  const newEntityData = useAppointmentDefaultData(mask);

  const getTempFileInitialData = useCallback(
    (data: AppointmentMaskFormValue) => {
      return data?.attachmentList;
    },
    []
  );

  const normalizeData = useCallback(
    (
      entity: AppointmentMaskFormValue,
      newEntity: AppointmentMaskFormValue,
      isCreateMode: boolean
    ) => {
      const dataRaw = isCreateMode ? newEntity : entity ?? newEntity;
      return dataRaw ? fixAppointmentStartEndDates(dataRaw) : dataRaw;
    },
    []
  );

  const overlay = useMaskOverlay<AppointmentMaskFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    customFields,
    customRules,
    getSubTitle: (x) => x.title,
    getTempFileInitialData,
    normalizeData,
  });

  const { form, data, initialFormValue, tempFileManager } = overlay;

  const { formState, getValues, watch, getFieldState, setValue } = form;

  const onAfterSave = useAfterSave(
    props.amplitudeEntryPoint || Entities.appointment
  );

  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      tempFileManager,
      Entities.appointmentAttachment,
      'id'
    );

  const getReminderDate = useCallback(() => {
    const { isWholeDay, startDate } = watch();

    const reminder = DateTime.fromISO(startDate);
    let resultingRemindDate = null;
    if (isWholeDay) {
      resultingRemindDate = reminder
        .startOf('day')
        .minus({ day: 1 })
        .set({ hour: 13 })
        .toISO();
    } else {
      resultingRemindDate = reminder.minus({ minutes: 15 }).toISO();
    }
    return resultingRemindDate;
  }, [watch]);

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const [mutate] = useDataMutation<
    Appointment,
    EMode.upsert,
    InputTerminRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data as unknown as Appointment<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave ? props.onAfterSave(data) : onAfterSave();
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const isSubmitting = isSaving || form.formState.isSubmitting;

  useAppointmentFormUpdate(form, data);

  const { dirtyFields } = formState;

  const isDirty =
    Object.keys(dirtyFields).length > 0 || attachementsRelation?.isDirty;

  const handleSubmit = useCallback(
    async (
      data: AppointmentMaskFormValue,
      _event?: unknown,
      skipOnComplete = false
    ) => {
      const appointment = { ...data };

      if (!appointment.remindDate) delete appointment.remindDate;

      const current = appointment.appointmentAttendeeList;
      const previous = initialFormValue.value?.appointmentAttendeeList || [];

      const currentIds = new Set(
        previous.filter(({ id }) => Boolean(id)).map(({ id }) => id)
      );

      // When a new attendee is added using a picker, the `id` is null.
      const [remaining, added] = partition(current, ({ id }) => id != null);

      remaining.forEach(({ id }) => {
        currentIds.delete(id);
      });

      const removed = [...currentIds];

      const appointmentAttendeeList: InputTerminTeilnehmerRelation =
        removed.length > 0 || added.length > 0
          ? {
              remove: removed,
              add: added.map((attendee) => ({
                benutzerCode: attendee.userId,
                ansprechpartnerCode: attendee.contactId,
                sdObjMemberCode: attendee.businessPartnerId,
                sdObjType: attendee.businessPartnerType,
              })) as InputTerminTeilnehmerAdd[],
            }
          : null;

      const userIsAttendee = current.find(
        (attendee) => attendee.userId === user.benutzerCode
      );

      const topic: InputTopicMarkRelation = {
        set: appointment?.topicMarkList?.[0]?.id || 0,
      };

      const relations: InputTerminRelation = {
        attachements: attachementsRelation?.attachements,
      };

      if (appointmentAttendeeList) {
        relations.teilnehmer = appointmentAttendeeList;
      }
      if (topic) {
        relations.topic = topic;
      }

      const updateRaw = mask.isCreateMode
        ? { ...appointment, userId: userIsAttendee ? userIsAttendee.userId : 0 }
        : pickUpdateFields(appointment, formState.dirtyFields);

      let updateMapped = prepareInputWithCustomFields(updateRaw);

      if (appointment.isWholeDay) {
        updateMapped = {
          ...updateMapped,
          endDate: getEdgeDate('end', 'day', appointment.endDate).toISO(),
        };
      }
      return await mutate(updateMapped, relations ? { relations } : undefined, {
        skipOnComplete: skipOnComplete === true,
      });
    },
    [
      attachementsRelation,
      formState.dirtyFields,
      initialFormValue.value?.appointmentAttendeeList,
      mask.isCreateMode,
      mutate,
      user.benutzerCode,
    ]
  );

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  const historyStack = useHistoryStack();
  const { setObjectionListener } = historyStack;

  const updateMaskState = useHistoryStackTitleUpdate(mask.isCreateMode);
  const getEmailParams = useCallback(async () => {
    let inputData = getValues();
    if (isDirty) {
      setIsSaving(true);
      inputData = (await handleSubmit(inputData, null, true)) as Appointment;
      setIsSaving(false);

      form.reset(inputData);

      if (!inputData?.id) return;
      setObjectionListener(null);

      setTimeout(() => {
        updateMaskState(inputData.id, inputData.title);
      }, 0);
    }
    const entityTemplate = {
      entity: Entities.appointment,
      id: inputData.id,
    };

    return {
      entityTemplate,
      params: mapAppointmentToEmailParams(inputData, {
        hourKey: t('COMMON.CLOCK'),
        wholeDay: t('COMMON.WHOLEDAYEVENT'),
      }),
    };
  }, [
    getValues,
    isDirty,
    handleSubmit,
    setObjectionListener,
    t,
    updateMaskState,
  ]);

  overlay.submitButtonProps.disabled =
    overlay.submitButtonProps.disabled || isSubmitting;

  return (
    <EmailTemplateButtonProvider
      eMailTemplateKind={EMailTemplateKind.APPOINTMENT_NOTIFICATION}
      noTemplate
      getEmailParams={getEmailParams}
    >
      <OverlayController<AppointmentMaskFormValue>
        {...overlay}
        onSubmit={async (data) => {
          setIsSaving(true);
          await handleSubmit(data);
        }}
        actions={<EmailTemplateIconButton disabled={isSubmitting} />}
        tabs={
          <AppointmentTabs
            isCreateMode={mask.isCreateMode}
            renderIndividualTab={shouldRenderIndividualTab}
          />
        }
      >
        <Content renderIndividualTab={shouldRenderIndividualTab} />
      </OverlayController>
    </EmailTemplateButtonProvider>
  );
};

const AppointmentTabs = memo(function AppointmentTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab value="attachments" label={t('MASK.ATTACHMENTS')}></MaskTab>
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

export function sortAttendees(attendees: AppointmentAttendee<EMode.entity>[]) {
  /**
   * group them by businesspartner
   * then sort them by name
   * */
  const userGroupKey = 'work4all2.0_users';
  const businesspartnerGroups = _.groupBy(attendees, (attendee) => {
    return attendee.user
      ? userGroupKey
      : attendee.contact?.businessPartner?.data.name ||
          attendee.businessPartner?.data.name;
  });

  let result: AppointmentAttendee<EMode.entity>[] = [];
  Object.keys(businesspartnerGroups)
    .sort((a, b) => {
      if (a === userGroupKey) return -1;
      if (b === userGroupKey) return 1;
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    })
    .forEach((groupKey) => {
      const sortedResult = [...businesspartnerGroups[groupKey]];
      sortedResult.sort((a, b) => {
        const aString: string =
          a.user?.lastName + a.user?.firstName ||
          a.contact?.businessPartner.data.name + a.contact?.name ||
          a.businessPartner?.data.name ||
          '';
        const bString: string =
          b.user?.lastName + b.user?.firstName ||
          b.contact?.businessPartner.data.name + b.contact?.name ||
          b.businessPartner?.data.name ||
          '';
        return aString.toLowerCase() < bString.toLowerCase() ? -1 : 1;
      });
      result = result.concat(sortedResult);
    });
  return result;
}

export function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    if (businessPartner.__typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}
