import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useProjectAppointments = (props: {
  projectIdList: number[];
  skip?: boolean;
}) => {
  const { projectIdList, skip } = props;

  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.appointment,
      data: {
        id: null,
        startDate: null,
        user: {
          id: null,
          displayName: null,
        },
        projectProcessDuration: null,
        projectId: null,
        projectProcess: {
          id: null,
          ressourceClassId: null,
        },
      } as Appointment,
      sort: [
        {
          field: 'startDate',
          direction: SortDirection.DESCENDING,
        },
      ],
      filter: [
        {
          projectId: {
            $in: projectIdList,
          },
        },
      ],
    };
  }, [projectIdList]);

  return useDataProvider<Appointment>(request, skip);
};
