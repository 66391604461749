import styles from '../ResourcesTimeline.module.scss';

import { MbscCalendarEvent } from '@mobiscroll/react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceUtilizationInfo } from '@work4all/models/lib/Classes/ResourceUtilizationInfo.entity';

import { formatAsFloat } from '@work4all/utils/lib/number-parser/number-utils';

import { settings, useSetting } from '../../../../../../../../settings';
import { getFactor } from '../../../../../../planning-columns/utils';

function getChunksOfYear(year: number, unit: 'month' | 'week'): Date[][] {
  const result: Date[][] = [];
  const startDate = new Date(year, 0, 1); // January 1st of the given year
  const endDate = new Date(year + 1, 0, 1); // January 1st of the next year

  let currentChunk: Date[] = [];
  const currentDate = new Date(startDate); // Create a copy of startDate to avoid mutating it

  while (currentDate < endDate) {
    currentChunk.push(new Date(currentDate)); // Add current date to the week

    // If it's Sunday or the last day of the year, push the current week to the weeks array
    if (
      (unit === 'week' && currentDate.getDay() === 0) ||
      currentDate.getTime() === endDate.getTime() - 1
    ) {
      result.push(currentChunk);
      currentChunk = [];
    }

    if (
      unit === 'month' &&
      currentDate.getMonth() !==
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1
        ).getMonth()
    ) {
      result.push(currentChunk);
      currentChunk = [];
    }

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
}

export const useUtilizationEvents = (props: {
  utilizationData: ResourceUtilizationInfo[];
}) => {
  const { utilizationData } = props;

  const { value: resourcePlanningUtilizationTimeUnit } = useSetting(
    settings.resourcePlanningUtilizationTimeUnit()
  );

  const { value: resourcePlanningColoumnTimeUnit } = useSetting(
    settings.resourcePlanningColoumnTimeUnit()
  );

  const factor = getFactor('hour', resourcePlanningColoumnTimeUnit);

  const { t } = useTranslation();

  const events = useMemo<MbscCalendarEvent[]>(() => {
    let result: MbscCalendarEvent[] = [];

    if (resourcePlanningUtilizationTimeUnit === 'day') {
      result = utilizationData
        .filter((x) => Boolean(x.utilizedValue))
        .map((info) => {
          const tooltip = [
            info.vacationList.length ? t('COMMON.VACATION') : undefined,
            info.appointmentList?.length
              ? info.appointmentList?.length +
                ' ' +
                t(
                  info.appointmentList?.length !== 1
                    ? 'COMMON.APPOINTMENT_plural'
                    : 'COMMON.APPOINTMENT'
                )
              : undefined,
            info.absenceList.length ? t('COMMON.SICKNESSDAY') : undefined,
          ]
            .filter(Boolean)
            .join('\n');

          return {
            id: info.userId + '-' + info.date.toString(),
            start: info.date,
            end: info.date,
            title: info.utilizedValue
              ? formatAsFloat(info.utilizedValue * factor, {
                  maximumFractionDigits: 2,
                })
              : '',
            resource: info.userId,
            color: 'transparent',
            tooltip,
            data: [info],
          };
        });

      return result;
    }

    const timeChunks = getChunksOfYear(
      new Date(utilizationData?.[0]?.date).getFullYear(),
      resourcePlanningUtilizationTimeUnit
    );
    const userIdList = [...new Set(utilizationData.map((x) => x.userId))];

    result = [];
    userIdList.forEach((userId) => {
      const chunk = timeChunks.map((timeChunk) => {
        const relevantInfo = utilizationData.filter(
          (item) =>
            item.userId === userId &&
            timeChunk.find((date) =>
              DateTime.fromJSDate(date).hasSame(
                DateTime.fromISO(item.date),
                'day'
              )
            )
        );

        const totalWorkHours = relevantInfo
          .map((x) => x.planedValue)
          .reduce((a, b) => {
            return a + b;
          }, 0);

        const totalUtilizedHours = parseFloat(
          relevantInfo
            .map((item) => item.utilizedValue)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
        );

        const progress = Math.min(
          100,
          parseInt(((totalUtilizedHours / totalWorkHours) * 100).toFixed(2))
        );

        return {
          id:
            relevantInfo?.[0]?.userId +
            '-' +
            relevantInfo?.[0]?.date.toString(),
          start: DateTime.fromJSDate(timeChunk[0]).startOf('day'),
          end: DateTime.fromJSDate(timeChunk[timeChunk.length - 1]).endOf(
            'day'
          ),
          title: `${formatAsFloat(totalUtilizedHours * factor, {
            maximumFractionDigits: 2,
          })}/${formatAsFloat(totalWorkHours * factor, {
            maximumFractionDigits: 2,
          })} `,
          resource: userId,
          color: progress >= 100 ? 'var(--userShade7)' : 'var(--ui01)',
          cssClass: clsx(
            { [styles['progress-' + progress]]: progress < 100 },
            styles.bordered
          ),
          data: relevantInfo,
        };
      });
      result.push(...chunk);
    });

    return result;
  }, [factor, resourcePlanningUtilizationTimeUnit, t, utilizationData]);

  return events;
};
