import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useInaccessibleFields } from '@work4all/data';

import { PreviewTitle } from '../../../preview-title/PreviewTitle';
import { usePreviewInput, usePreviewInputsContext } from '../../hooks';
import { PreviewTitleInputProps } from '../../types';

export const PreviewTitleInput = ({
  // TODO: WW-3803 make default TITILE if not just throw error!
  accessor,
  onClose,
  onVisibilityToggle,
  ...rest
}: PreviewTitleInputProps) => {
  const ref = useRef<PreviewTitle>(null);
  const { t } = useTranslation();

  const { isMultiselect, entries, entity } = usePreviewInputsContext();

  const { isInaccessible } = useInaccessibleFields();
  const titleIsAccessible = !isInaccessible(entity, accessor);

  const value = rest.value
    ? rest.value
    : entries.length === 1
    ? entries[0][accessor]
    : '';
  const disabled = isMultiselect || !titleIsAccessible;

  const {
    previewValue,
    onClick,
    setValue,
    CircularProgress,
    canEditAllSelected,
    isActive,
    isLoading,
    onPopoverClose,
    onEdit,
  } = usePreviewInput<string>({
    formattedValue: value,
    accessor,
    disabled,
    ref,
    value,
    renderPreviewValue: ({ isMultiselect, numberOfSelectedRows }) => {
      return isMultiselect
        ? `${t('COMMON.SELECTION')}: ${numberOfSelectedRows} ${t(
            'COMMON.ELEMENTS'
          )}`
        : null;
    },
  });

  return (
    <PreviewTitle
      {...rest}
      ref={ref}
      onClick={onClick}
      actions={isActive && isLoading ? <CircularProgress /> : undefined}
      label={previewValue}
      onVisibilityToggle={onVisibilityToggle}
      onClose={(e) => {
        if (e.target.value !== value) {
          setValue(e.target.value);
          if (!onClose) onEdit({ [accessor]: e.target.value });
        }

        if (onClose) onClose(e);
        else onPopoverClose();
      }}
      showEdit={!isMultiselect && canEditAllSelected}
    >
      {previewValue}
    </PreviewTitle>
  );
};
