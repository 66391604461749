import deepmerge from 'deepmerge';
import { concat, get } from 'lodash';
import { useCallback, useDebugValue, useEffect, useMemo } from 'react';
import { SortingRule } from 'react-table';

import {
  CustomFilterMapping,
  ITableStateBag,
  usePrepareFilters,
  useQueryTableData,
} from '@work4all/components';

import {
  useDataMutation,
  useRefetchOnEntityChanges,
  useUser,
} from '@work4all/data';
import { useDisableApolloAutoInvalidation } from '@work4all/data//lib/apollo-extras/use-disable-apollo-auto-invalidation';
import { useCustomFieldsConfigContext } from '@work4all/data/lib/custom-fields';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import {
  DataRequest,
  SortDirection,
  SortOption,
} from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import {
  IColumnNumberFilterConfig,
  ICustomCellConfigBase,
} from '@work4all/models/lib/table-schema/table-schema';

import { viewEntityToMutationEntity } from '@work4all/utils';

import { DataTableColumnConfig } from './table/DataTableColumnConfig';
import { useSelectedTableRows } from './use-selected-table-rows';
import { useTableColumns } from './use-table-Columns';
import {
  isReadStatusModifier,
  IUseTableConfigOptions,
  useTableConfig,
} from './use-table-config';
import { useTableFooterData } from './use-table-footer-data';
import { useTransformedFilterFields } from './use-transformed-filter-fields';

export type IUseDataTableOptions<
  CustomColumns extends Record<string, ICustomCellConfigBase>
> = Omit<IUseTableConfigOptions<CustomColumns>, 'layout' | 'customFields'> & {
  layout?: IUseTableConfigOptions<CustomColumns>['layout'];
  tableStateBag: ITableStateBag;
  prefilter?: unknown[];
  defaultSort?: SortOption[];
  forceRequestFields?: Work4AllEntity;
  customFilterMapping?: CustomFilterMapping;
  additionalColumns?: DataTableColumnConfig[];
  /**
   * If set to true, will load the footer data for supported column types.
   *
   * @default false
   */
  enableFooter?: boolean;
  /**
   * We use that with Entities that we can't query directly
   * e.g. `RaPayment` you can query it from the `RaViewModel`.
   *
   * Full Context: https://work4all.slack.com/archives/C03LXTT1RL4/p1726658196853819
   */
  innerEntityQuery?: {
    parentEntity: Entities;
    fieldName: string;
  };
  pageSize?: number;
  skipSyncBag?: boolean;
};

export function useDataTable<
  Entity extends Work4AllEntity,
  CustomColumns extends Record<string, ICustomCellConfigBase>
>(options: IUseDataTableOptions<CustomColumns>) {
  const {
    layout = 'table',
    schema,
    tableStateBag,
    prefilter,
    defaultSort = null,
    cells,
    cellProcessors,
    forceRequestFields,
    customFilterMapping,
    additionalColumns,
    enableFooter = false,
    innerEntityQuery,
    groupLabelElements,
    skipSyncBag = false,
    pageSize = 100,
  } = options;

  const entityType = innerEntityQuery?.parentEntity ?? schema.entity;

  useDisableApolloAutoInvalidation();

  const { benutzerCode: userId } = useUser();

  const transformedTableFilterFields = useTransformedFilterFields(schema);

  const { filter, sort } = usePrepareFilters(
    transformedTableFilterFields,
    tableStateBag.tableState?.sortBy,
    customFilterMapping,
    entityType
  );

  const { customFields } = useCustomFieldsConfigContext();

  const {
    fields: extractedFields,
    columnConfigs,
    cardConfig,
    prepareRowDisplayModifiers,
  } = useTableConfig({
    layout,
    schema,
    customFields,
    cells,
    cellProcessors,
    additionalColumns,
    groupLabelElements,
  });

  const fields = useMemo(() => {
    //eslint-disable-next-line
    //@ts-ignore
    const mergedFields = deepmerge(extractedFields, forceRequestFields || {});

    if (innerEntityQuery) {
      return { id: null, [innerEntityQuery.fieldName]: [mergedFields] };
    }

    return mergedFields;
  }, [extractedFields, forceRequestFields, innerEntityQuery]);

  const searchFilter = useMemo(() => {
    if (
      !tableStateBag?.searchFilterText ||
      tableStateBag?.searchFilterText.length === 0
    ) {
      return [];
    } else {
      const filter = [];

      schema.columns.forEach((col) => {
        const isNumber =
          (col.filterable as IColumnNumberFilterConfig)?.type === 'Number';

        if (col.quickSearchable) {
          if (!isNumber || parseInt(tableStateBag?.searchFilterText))
            filter.push({
              [col.accessor]: {
                $eq: isNumber
                  ? '%' + parseInt(tableStateBag?.searchFilterText) + '%'
                  : '%' + tableStateBag?.searchFilterText?.trim() + '%',
              },
            });
        }
      });
      return filter;
    }
  }, [schema.columns, tableStateBag?.searchFilterText]);

  const finalFilter = useMemo(() => {
    const finalFilter = concat(...[filter, prefilter].filter(Boolean));

    if (searchFilter.length) {
      finalFilter.push({
        $or: searchFilter,
      });
    }

    return finalFilter;
  }, [filter, prefilter, searchFilter]);

  const requestData = useMemo(() => {
    const completeDataResponse =
      entityDefinition[entityType]?.remote?.withPaginationWrapper === false;

    const getSortOptions = () => {
      if (!sort) {
        return defaultSort;
      }

      return sort.map((option) => {
        const column = schema.columns.find((column) => column.id === option.id);

        return {
          // sortColumn?.accessor is not defined when prefilter is made on column that is not schema
          field: column?.accessor ?? option.id,
          direction: option.desc
            ? SortDirection.DESCENDING
            : SortDirection.ASCENDING,
        };
      });
    };

    const request: DataRequest = {
      filter: finalFilter.length > 0 ? finalFilter : undefined,
      entity: entityType as Entities,
      completeDataResponse,
      data: fields,
      sort: getSortOptions(),
    };

    return request;
  }, [entityType, schema.columns, sort, finalFilter, fields, defaultSort]);

  const footerData = useTableFooterData({
    enabled: enableFooter,
    entity: schema.entity as Entities,
    columns: columnConfigs,
    filter: finalFilter,
  });

  const allColumns = useTableColumns<CustomColumns>(
    schema.columns,
    customFields
  );

  const getGroupKeyFields = useCallback(
    (columnId: string) => {
      const column = allColumns.find((column) => {
        const id = column.id ?? column.accessor;
        return id === columnId;
      });

      if (!column) {
        throw new Error(`Could not find a column with id "${columnId}".`);
      }

      const { accessor, id = accessor } = column;

      return {
        id: id as string,
        label: typeof accessor === 'string' ? accessor : null,
      };
    },
    [allColumns]
  );

  const tableDataColumns = useMemo(() => {
    return columnConfigs
      .filter((column) => !column.disableFooterSum && column.Footer != null)
      .map((column) => column.accessor as string);
  }, [columnConfigs]);

  const tableData = useQueryTableData(
    requestData,
    getGroupKeyFields,
    pageSize,
    customFields,
    tableDataColumns,
    enableFooter
  );

  const innerQueryTableData = useMemo(() => {
    if (!innerEntityQuery) return tableData;

    const data = tableData?.data?.[0]?.[innerEntityQuery.fieldName] ?? [];

    return {
      ...tableData,
      data,
      total: data.length,
    };
  }, [innerEntityQuery, tableData]);

  const mutationEntity = viewEntityToMutationEntity(entityType);
  useRefetchOnEntityChanges({
    entity: mutationEntity,
    refetch: innerQueryTableData.refetch,
  });

  const readStatusModifier = useMemo(() => {
    const modifiers = schema.rowModifiers.filter(isReadStatusModifier);

    if (modifiers.length > 1) {
      console.warn(
        'Multiple ReadStatus modifiers found.' +
          ' This is a misconfiguration in the table schema.' +
          ' Only the first one will be used.'
      );
    }

    if (modifiers.length > 0) {
      return modifiers[0];
    } else {
      return null;
    }
  }, [schema]);

  const { selectedEntity, selectedEntities } = useSelectedTableRows<Entity>({
    data: innerQueryTableData.data,
    tableStateBag,
    skipSyncBag,
  });

  const responseData = useMemo(
    () =>
      readStatusModifier
        ? {
            id: null,
            [readStatusModifier.params.field]: null,
          }
        : {},
    [readStatusModifier]
  );

  const [mutateEntity] = useDataMutation({
    entity: mutationEntity,
    mutationType: EMode.upsert,
    responseData,
  });

  useEffect(() => {
    if (
      readStatusModifier !== null &&
      selectedEntity !== null &&
      get(selectedEntity, readStatusModifier.params.field) === false &&
      get(selectedEntity, readStatusModifier.params.ownerIdField) === userId &&
      'id' in selectedEntity
    ) {
      mutateEntity({
        id: selectedEntity.id,
        [readStatusModifier.params.field]: true,
      });
    }
  }, [mutateEntity, selectedEntity, userId, readStatusModifier]);

  const initialSortBy = useMemo<SortingRule<object>[]>(() => {
    if (defaultSort == null) {
      return [];
    }

    return defaultSort.map(({ field, direction }) => {
      return { id: field, desc: direction === SortDirection.DESCENDING };
    });
  }, [defaultSort]);

  useDebugValue({
    ...innerQueryTableData,
    columnConfigs,
    cardConfig,
    prepareRowDisplayModifiers,
    selectedEntity,
    initialSortBy,
  });

  return {
    ...innerQueryTableData,
    columnConfigs,
    cardConfig,
    prepareRowDisplayModifiers,
    selectedEntity,
    selectedEntities,
    initialSortBy,
    footerData,
    entityType: schema.entity,
  };
}
