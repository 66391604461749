import { DateTime } from 'luxon';

const makeEmptyNowDate = () => {
  const d = DateTime.now();
  return d.set({
    minute: 0,
    hour: 0,
    second: 0,
    millisecond: 0,
  });
};
export const getTimeValue = (inputValue: string) => {
  if (!inputValue) return makeEmptyNowDate();

  const value =
    inputValue !== '0001-01-01T00:00:00'
      ? DateTime.fromISO(inputValue)
      : makeEmptyNowDate();
  return value;
};
