import styles from './styles.module.scss';

import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  checkModuleRight,
  Link,
  useModuleRights,
  useUser,
} from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';
import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';

import { currencyAsSign, formatNumberAsCurrency } from '@work4all/utils';

import {
  stringifyTableSettings,
  TableSettings,
} from '../../../../../components/data-tables/make-table-settings';
import { CurrencyExchangeInfoContext } from '../../../../mask-overlays/mask-overlay/views/inbound-invoice/currency-exchange-info-context';
import { IFileInfoPanelItems } from '../file-info-panel/types';

import { InlineData } from './InlineData';

type OpenDueCardProps = {
  partner: Customer | Supplier;
  isCustomer: boolean;
  loading: boolean;
  item: IFileInfoPanelItems;
};

export const OpenDueCard: FC<OpenDueCardProps> = ({
  partner,
  isCustomer,
  loading,
  item,
}) => {
  const { t } = useTranslation();

  const user = useUser();
  const hasUserRights = isCustomer
    ? checkUserRight(UserRights.OffenePostenKundenEinsehen, user.benutzerRechte)
    : true;

  const { rights } = useModuleRights();
  const hasVisibilityMAR = checkModuleRight(
    rights,
    ModuleAccessRightType.FEATURE_ORDER_MANAGEMENT_MODULE
  );
  const hasInvoiceRedirectionMAR = checkModuleRight(
    rights,
    isCustomer
      ? ModuleAccessRightType.FEATURE_OUTGOING_INVOICE_LEDGER
      : ModuleAccessRightType.FEATURE_INCOMING_INVOICE_LEDGER
  );

  const currencyExchangeInfo = useContext(CurrencyExchangeInfoContext);
  const currency = currencyAsSign(currencyExchangeInfo?.defaultCurrency?.name);

  const vacantPositions = partner?.kpis?.vacantPositions;
  const openPosition = formatNumberAsCurrency(vacantPositions || 0);
  const dueOpenPositions = partner?.kpis?.dueOpenValue;
  const duePosition = formatNumberAsCurrency(dueOpenPositions || 0);

  const settings = {
    filter: [
      {
        id: isCustomer ? 'customer.id' : 'supplier.id',
        value: [
          {
            id: partner?.id.toString(),
            name: partner?.name,
          },
        ],
      },
    ],
  };
  const openSettings = {
    filter: [
      ...settings.filter,
      {
        id: 'paymentStatus',
        value: [
          {
            id: PaymentStatus.OPEN,
            name: t('PAYMENT_STATUSES.OPEN'),
          },
          {
            id: PaymentStatus.OPEN_CREDIT,
            name: t('PAYMENT_STATUSES.OPEN_CREDIT'),
          },
          {
            id: PaymentStatus.DUE,
            name: t('PAYMENT_STATUSES.DUE'),
          },
          {
            id: PaymentStatus.PARTIAL_PAYED,
            name: t('PAYMENT_STATUSES.PARTIAL_PAYED'),
          },
          {
            id: PaymentStatus.PARTIAL_PAYED_CREDIT,
            name: t('PAYMENT_STATUSES.PARTIAL_PAYED_CREDIT'),
          },
        ],
      },
    ],
  };
  const dueSettings = {
    filter: [
      ...settings.filter,
      {
        id: 'paymentStatus',
        value: [
          {
            id: PaymentStatus.DUE,
            name: t('PAYMENT_STATUSES.DUE'),
          },
        ],
      },
    ],
  };
  const listParams = new URLSearchParams();

  const createListParams = (due: boolean) => {
    if (!due) {
      listParams.set(
        'settings',
        stringifyTableSettings(openSettings as TableSettings)
      );
    }
    if (due) {
      listParams.set(
        'settings',
        stringifyTableSettings(dueSettings as TableSettings)
      );
    }
    return listParams.toString();
  };

  const invoiceType = isCustomer ? 'raViewModel' : 'reViewModel';

  return !hasUserRights || !hasVisibilityMAR ? null : (
    <InlineData
      key={item}
      label={t('FILE_CONTACTS.OPEN_POSITIONS')}
      content={
        loading || !currency ? (
          '- | -'
        ) : (
          <Trans
            i18nKey={t('FILE_CONTACTS.OPEN_POSITIONS.CONTENT')}
            values={{
              open: vacantPositions ? `${openPosition} ${currency}` : '-',
              due: dueOpenPositions
                ? `${t('COMMON.RA.DUE')}: ${duePosition} ${currency}`
                : '-',
            }}
            components={{
              openAnchor:
                vacantPositions && hasInvoiceRedirectionMAR ? (
                  <Link
                    className={styles.link}
                    to={`/more/entity/${invoiceType}?${createListParams(
                      false
                    )}`}
                  />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                ),
              dueAnchor:
                dueOpenPositions && hasInvoiceRedirectionMAR ? (
                  <Link
                    className={styles.link}
                    to={`/more/entity/${invoiceType}?${createListParams(true)}`}
                  />
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                ),
            }}
          />
        )
      }
    />
  );
};
