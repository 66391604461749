import styles from './ProjectPlanningView.module.scss';

import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventDragEvent,
} from '@mobiscroll/react';
import { EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uuid } from 'short-uuid';

import { EntityGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { ExpandButton } from '@work4all/components/lib/input/expand-button/ExpandButton';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useDataMutation, useDataProvider, useNavigate } from '@work4all/data';
import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { InputProjectStepRelation } from '@work4all/models/lib/Classes/InputProjectStepRelation.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { ProjectPlanningPageSearchParamKeys } from '../../../../../../../containers/mask-overlays/mask-overlay/views/project-planning/ProjectPlanningPage';
import { settings, useSetting } from '../../../../../../../settings';
import { ResourcesTimeline } from '../resources-timeline/ResourcesTimeline';
import { ZoomableTimeline } from '../zoomable-timeline/ZoomableTimeline';

import { ProjectPlanningToolbar } from './components/project-planning-toolbar/ProjectPlanningToolbar';
import { ProjectProcessQuickCreateDialog } from './components/project-process-quick-create-dialog/ProjectProcessQuickCreateDialog';
import { ProjectProcessTemplate } from './components/project-process-quick-create-dialog/types';
import { PROJECT_PROCESS_DATA } from './components/project-process-quick-create-dialog/utils';
import { TimeUnit } from './components/time-unit-switch/TimeUnitSwitch';
import { ZoomValue } from './components/timeline-zoom/TimelineZoom';
import { useColors } from './hooks/use-colors';
import { useConnections } from './hooks/use-connections';
import { useEvents } from './hooks/use-events';
import { useProjectWithProcesses } from './hooks/use-project-with-processes';
import { useRenderResource } from './hooks/use-render-resource';
import { useRenderResourceHeader } from './hooks/use-render-resource-header';
import { useResources } from './hooks/use-resources';
import { useSyncTimelines } from './hooks/use-sync-timelines';
import { ProjectProcessColumn } from './types';
import {
  DEFAULT_LEFT_AREA_COLS,
  mergePersistedProjectProcessCols,
} from './utils';

interface IProps {
  projectIdList: number[];
  timeUnit?: TimeUnit;
  zoom?: ZoomValue;
  leftAreaCollapsed?: boolean;
  leftAreaColumns?: ProjectProcessColumn[];
  showToolbar?: boolean;
  showAddAction?: boolean;
  showPrintAction?: boolean;
  showDateSelector?: boolean;
  showZoomControl?: boolean;
  showResourceFilter?: boolean;
  showTimeUnitControl?: boolean;
  showFullscreenAction?: boolean;
  showLayoutSelector?: boolean;
  onProjectIdsChange?: (ids: number[]) => void;
  layout?: 'gantt' | 'stacked';
}

export const ProjectPlanningView = (props: IProps) => {
  const {
    projectIdList: initialProjectIdList,
    timeUnit: initialTimeUnit = 'year',
    zoom: initialZoom = 3,
    leftAreaCollapsed: initialLeftAreaCollapsed = false,
    leftAreaColumns: initialLeftAreaColumns = DEFAULT_LEFT_AREA_COLS,
    showAddAction = true,
    showDateSelector = true,
    showFullscreenAction = true,
    layout = 'gantt',
    showPrintAction = true,
    showTimeUnitControl = true,
    showToolbar = true,
    showZoomControl = true,
    showResourceFilter = true,
    onProjectIdsChange,
  } = props;

  const [bottomSectionHeight, setBottomSectionHeight] = useState(300);
  const [bottomSectionCollapsed, setBottomSectionCollapsed] = useState(true);
  const compactView = useMemo(() => {
    return layout === 'stacked';
  }, [layout]);
  const [projectIdList, setProjectIdList] = useState([]);
  const [unit, setUnit] = useState(initialTimeUnit);
  const [zoom, setZoom] = useState(initialZoom);
  const [leftAreaCollapsed, setLeftAreaCollapsed] = useState(
    initialLeftAreaCollapsed
  );
  const [leftAreaColumns, setLeftAreaColumns] = useState(
    mergePersistedProjectProcessCols(initialLeftAreaColumns)
  );
  const [date, setDate] = useState(new Date());
  const [resourceClasses, setResourceClasses] = useState<LookUp[]>([]);
  const [ganttInst, setGanttInst] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [focusedResourceId, setFocusedResourceId] = useState<number>(null);

  const {
    data: projectList,
    refetch,
    pending,
  } = useProjectWithProcesses(projectIdList);

  const timelineClass = useMemo(() => `timeline-${uuid()}`, []);
  const resourceTimelineClass = useMemo(() => `timeline-${uuid()}`, []);

  const syncedTimelineProps = useSyncTimelines(
    timelineClass,
    resourceTimelineClass
  );

  const [maxEventStack, setMaxEventStack] = useState(3);

  const [selectedEvents, setSelectedEvents] = useState<MbscCalendarEvent[]>([]);
  const [focusedProjectId, setFocusedProjectId] = useState<number>(null);
  const focusedProject = useMemo(
    () => projectList.find((pr) => pr.id === focusedProjectId),
    [focusedProjectId, projectList]
  );
  const [focusedProjectProcess, setFocusedProjectProcess] =
    useState<ProjectProcess>(null);

  const { value: resourceTimelineUserIds, set: setResourceTimelineUserIds } =
    useSetting(settings.resourcePlanningUserIds());

  const {
    value: resourcePlanningShowConnections,
    set: setResourcePlanningShowConnections,
  } = useSetting(settings.resourcePlanningShowConnections());

  const [projectGroupsPanelOpen, setProjectGroupsPanelOpen] = useState(false);
  const [selectedProjectGroups, setSelectedProjectGroups] = useState<Group[]>(
    []
  );

  const projectIdRequest = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
      } as Project,
      entity: Entities.project,
      filter: [
        {
          groupId: {
            $in: selectedProjectGroups?.map((pr) => pr.id),
          },
        },
      ],
    };
  }, [selectedProjectGroups]);

  const projectIdResult = useDataProvider(
    projectIdRequest,
    selectedProjectGroups?.length === 0
  );

  useEffect(() => {
    setProjectIdList(projectIdResult?.data?.map((pr) => pr.id));
  }, [projectIdResult?.data]);

  useEffect(() => {
    setProjectIdList(initialProjectIdList);
  }, [initialProjectIdList]);

  const view = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        type: unit,
        startDay: 1,
        endDay: 0,
        eventList: true,
        weekNumbers: true,
        maxEventStack: projectList.length > 1 ? maxEventStack : undefined,
      },
    }),
    [maxEventStack, projectList.length, unit]
  );

  useEffect(() => {
    if (!projectIdList.find((id) => id === focusedProjectId)) {
      setFocusedProjectId(projectIdList[0] || null);
    }
  }, [focusedProjectId, projectIdList, projectList, setFocusedProjectId]);

  const [mutate] = useDataMutation<
    ProjectProcess,
    EMode.upsert,
    InputProjectStepRelation
  >({
    entity: Entities.projectProcess,
    mutationType: EMode.upsert,
    responseData: PROJECT_PROCESS_DATA,
  });

  const events = useEvents({
    projectList,
    compact: compactView,
    resourceClassIdList: resourceClasses.map((x) => x.id),
  });

  const resources = useResources({
    projectList,
    compact: compactView,
    resourceClassIdList: resourceClasses.map((x) => x.id),
    eventList: events || [],
  });
  const connections = useConnections({ projectList });

  const printView = () => {
    ganttInst.print();
  };
  const projectIdsChanged = useRef(true);

  useEffect(() => {
    setZoom(initialZoom);
  }, [initialZoom]);

  useEffect(() => {
    setUnit(initialTimeUnit);
  }, [initialTimeUnit]);

  useEffect(() => {
    projectIdsChanged.current = true;
  }, [projectIdList]);

  useEffect(() => {
    if (!projectIdsChanged.current || events.length === 0) {
      return;
    }
    const newStartDate = events
      ?.filter((x) => x.start && !x.children)
      .sort((a, b) => {
        return a.start > b.start ? 1 : -1;
      })?.[0]?.start;
    if (
      newStartDate instanceof Date &&
      !isNaN(newStartDate as unknown as number)
    )
      setDate(newStartDate as Date);
    projectIdsChanged.current = false;
  }, [events]);

  const { t } = useTranslation();

  const renderResource = useRenderResource({
    onDateChange: setDate,
    leftAreaColumns,
    onProjectRemoved: (projectId: number) => {
      const ids = projectIdList.filter((x) => x !== projectId);
      setProjectIdList(ids);
      onProjectIdsChange?.(ids);
    },
    focusedResourceId,
    setFocusedResourceId,
  });

  const renderResourceHeader = useRenderResourceHeader({
    leftAreaColumns,
    onLeftAreaColumnsChanged: setLeftAreaColumns,
    leftAreaCollapsed,
    onLeftAreaCollapsedChanged: setLeftAreaCollapsed,
    onProjectSelected: (project) => {
      if (project) {
        const ids = [...projectIdList, project.id];
        setProjectIdList(ids);
        onProjectIdsChange?.(ids);
      }
    },
    showTableHeaders: !compactView,
  });

  const leftAreaWidth = useMemo(() => {
    const leftOffset = remToPx(5);
    return Math.round(
      leftAreaColumns
        .filter((x) => !x.hidden)
        .map((x) => x.width)
        .reduce((a, b) => a + b) + leftOffset
    );
  }, [leftAreaColumns]);

  const overlayInitialView = useMemo<IStackItem>(() => {
    return {
      view: (
        <ProjectPlanningViewOverlayController
          {...props}
          projectIdList={projectIdList}
          leftAreaCollapsed={leftAreaCollapsed}
          leftAreaColumns={leftAreaColumns}
          timeUnit={unit}
          zoom={zoom}
        />
      ),
      title: t('COMMON.PROJECT_PLANNING'),
    };
  }, [leftAreaCollapsed, leftAreaColumns, projectIdList, props, t, unit, zoom]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [projectProcessTemplate, setProjectProcessTemplate] =
    useState<ProjectProcessTemplate>(null);
  const [projectProcessId, setProjectProcessId] = useState<number>(null);

  const openEventDialog = useCallback((e: MbscEventClickEvent) => {
    const event = e.event;
    setProjectProcessId(parseInt(event.id.toString()));
    setDialogOpen(true);
  }, []);

  const handleEventDrag = useCallback(
    async (args: MbscEventDragEvent, inst: EventcalendarBase) => {
      const { event } = args;

      const existingEvent = typeof event.id === 'number';

      if (existingEvent) {
        await mutate(
          {
            id: event.id,
            startDatum: DateTime.fromJSDate(event.start as Date).toISO(),
            endDateInner: DateTime.fromJSDate(event.end as Date)
              .startOf('day')
              .toISO(),
            __typename: undefined,
          } as ProjectProcess,
          {
            relations: {},
          }
        );

        refetch();
      } else if (event.resource.toString().startsWith('project')) {
        const projectId =
          parseInt(event.resource.toString().split('-')?.[1]) || null;
        if (!projectId) {
          return;
        }
        const project = projectList.find((pr) => pr.id === projectId);
        const parentProcess = project.projectProcessList.find((pp) => {
          if (pp.kindId !== ProjectProcessKind.GLIEDERUNGSPUNKT) {
            return false;
          }
          if (
            new Date(pp.startDatum) <= event.start &&
            new Date(pp.endDateInner) >= event.end
          ) {
            return true;
          }
          return false;
        });

        setProjectProcessTemplate({
          projectId: projectId,
          project: project,
          kindId: ProjectProcessKind.VORGANG,
          startDatum: DateTime.fromJSDate(event.start as Date)
            .startOf('day')
            .toISO(),
          endDateInner: DateTime.fromJSDate(event.end as Date)
            .startOf('day')
            .toISO(),
          parent: parentProcess,
          parentId: parentProcess?.id,
        });
        setDialogOpen(true);
        inst.removeEvent(event.id);
      } else {
        //if (event.id.toString().startsWith('mbsc')) {
        const process = projectList
          .find((p) =>
            p.projectProcessList.find((pp) => pp.id === event.resource)
          )
          ?.projectProcessList.find((pp) => pp.id === event.resource);

        if (!process) {
          return;
        }

        setProjectProcessTemplate({
          projectId: process.projectId,
          project: process.project,
          kindId: ProjectProcessKind.VORGANG,
          startDatum: DateTime.fromJSDate(event.start as Date)
            .startOf('day')
            .toISO(),
          endDateInner: DateTime.fromJSDate(event.end as Date)
            .startOf('day')
            .toISO(),
          parent: process.parent,
          parentId: process.parentId,
        });
        setDialogOpen(true);
        inst.removeEvent(event.id);
      }
    },
    [mutate, projectList, refetch]
  );

  const handleSelectedDateChange = useCallback((args: { date: Date }) => {
    setDate(args.date);
  }, []);

  const navigate = useNavigate();

  const handleFullscreenClick = useCallback(() => {
    const pathname = '/more/project-planning';
    const search = new URLSearchParams({
      [ProjectPlanningPageSearchParamKeys.layout]: layout,
      [ProjectPlanningPageSearchParamKeys.projectIds]: projectIdList.join(','),
    }).toString();

    navigate({ pathname, search });
  }, [layout, navigate, projectIdList]);

  const defaultColors = useColors({ resources, focusedResourceId });

  const colors = useMemo<MbscCalendarColor[]>(() => {
    return [
      ...defaultColors,
      ...(projectIdList?.length > 1
        ? [
            {
              resource: 'project-' + focusedProjectId,
              background: 'var(--bgShade1)',
              recurring: {
                repeat: 'weekly',
                weekDays: 'MO,TU,WE,TH,FR',
              },
            } as MbscCalendarColor,
            {
              resource: 'project-' + focusedProjectId,
              background: 'var(--userShade1)',
              recurring: {
                repeat: 'weekly',
                weekDays: 'SA,SU',
              },
            } as MbscCalendarColor,
          ]
        : []),
    ];
  }, [defaultColors, focusedProjectId, projectIdList?.length]);

  const timeline = useMemo(() => {
    return (
      <ZoomableTimeline
        clickToCreate={false}
        dragToCreate={true}
        dragToMove={true}
        dragBetweenResources={false}
        dragInTime={true}
        dragToResize={true}
        eventDelete={false}
        showControls={false}
        date={date}
        onSelectedDateChange={(e) => {
          e && handleSelectedDateChange({ date: e.date as Date });
        }}
        componentRef={setGanttInst}
        onEventDoubleClick={openEventDialog}
        onEventDragEnd={handleEventDrag}
        resources={resources}
        events={events}
        view={view}
        renderResource={renderResource}
        renderResourceHeader={renderResourceHeader}
        connections={
          !compactView && resourcePlanningShowConnections ? connections : []
        }
        zoom={zoom}
        leftAreaWidth={leftAreaWidth}
        leftAreaCollapsed={leftAreaCollapsed}
        externalDrag
        className={timelineClass}
        onEventClick={(e) => {
          setSelectedEvents([e.event]);
          setFocusedProjectProcess(e.event?.process as ProjectProcess);
          setFocusedProjectId((e.event?.process as ProjectProcess)?.projectId);
        }}
        newEventText={t('COMMON.NEW_PROJECT_PROCESS')}
        colors={colors}
        {...syncedTimelineProps.first}
      />
    );
  }, [
    date,
    openEventDialog,
    handleEventDrag,
    resources,
    events,
    view,
    renderResource,
    renderResourceHeader,
    compactView,
    resourcePlanningShowConnections,
    connections,
    zoom,
    leftAreaWidth,
    leftAreaCollapsed,
    timelineClass,
    t,
    colors,
    syncedTimelineProps.first,
    handleSelectedDateChange,
    setSelectedEvents,
    setFocusedProjectProcess,
    setFocusedProjectId,
  ]);

  const resourceTimeline = useMemo(() => {
    return (
      <ResourcesTimeline
        view={view}
        date={date}
        leftAreaCollapsed={leftAreaCollapsed}
        leftAreaWidth={leftAreaWidth}
        zoom={zoom}
        className={resourceTimelineClass}
        highlightedDateRanges={selectedEvents.map((x) => ({
          start: new Date((x.process as ProjectProcess).startDatum),
          end: new Date((x.process as ProjectProcess).endDateInner),
        }))}
        focusedProjectProcess={focusedProjectProcess}
        focusedProject={focusedProject}
        userIds={resourceTimelineUserIds}
        onUsersChange={(users) => {
          setResourceTimelineUserIds(users?.map((x) => x.id));
        }}
        {...syncedTimelineProps.second}
      />
    );
  }, [
    view,
    date,
    leftAreaCollapsed,
    leftAreaWidth,
    zoom,
    resourceTimelineClass,
    selectedEvents,
    focusedProjectProcess,
    focusedProject,
    resourceTimelineUserIds,
    syncedTimelineProps.second,
    setResourceTimelineUserIds,
  ]);

  return (
    <>
      {dialogOpen && (
        <ProjectProcessQuickCreateDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setProjectProcessId(null);
            refetch();
          }}
          onOpenMask={() => null}
          template={projectProcessTemplate}
          id={projectProcessId}
        />
      )}
      {overlayOpen && (
        <NavigationOverlay
          initialView={overlayInitialView}
          open={overlayOpen}
          close={() => {
            setOverlayOpen(false);
          }}
          withBreadcrumbs
          classes={{
            wrapperSmall: styles.wrapperFullscreen,
          }}
        />
      )}

      {pending && (
        <Box position="absolute" left={0} right={0} zIndex={100} top="auto">
          <LinearProgress />
        </Box>
      )}
      {showToolbar && (
        <ProjectPlanningToolbar
          date={date}
          unit={unit}
          zoom={zoom}
          onAddProcessClick={() => {
            setProjectProcessTemplate({
              projectId: projectList?.[0]?.id,
              project: projectList?.[0],
              kindId: ProjectProcessKind.VORGANG,
            });
            setDialogOpen(true);
          }}
          onDateChange={(start) => handleSelectedDateChange({ date: start })}
          showAddAction={projectList.length ? showAddAction : false}
          onFullscreenBtnClick={handleFullscreenClick}
          onPrintClick={printView}
          onTimeUnitChange={setUnit}
          onZoomChange={setZoom}
          showDateSelector={showDateSelector}
          showFullscreenAction={showFullscreenAction}
          showPrintAction={showPrintAction}
          showTimeUnitControl={showTimeUnitControl}
          showZoomControl={showZoomControl}
          resourceClasses={resourceClasses}
          showResourceFilter={showResourceFilter}
          onRessourceClassesChange={setResourceClasses}
          onConnectionToggleChange={setResourcePlanningShowConnections}
          maxEventStack={maxEventStack}
          onMaxEventStackChange={setMaxEventStack}
          showConnections={resourcePlanningShowConnections}
          showConnectionToggle={!compactView}
          showMaxEventStackSelector={
            projectList.length > 1 ? compactView : false
          }
        />
      )}
      <Stack direction="row" height="100%" overflow="hidden">
        {projectGroupsPanelOpen ? (
          <Stack
            borderRight="1px solid var(--ui04)"
            borderTop="1px solid var(--ui04)"
          >
            <Stack direction="row" alignItems="center" width="100%">
              <Typography variant="h4" pl="1rem" flexGrow={1}>
                {t('COMMON.GROUPS')}
              </Typography>
              <IconButton onClick={() => setProjectGroupsPanelOpen(false)}>
                <ChevronLeft />
              </IconButton>
            </Stack>
            <Box pr="1rem">
              <EntityGroupPicker
                entity={Entities.projectGroup}
                value={selectedProjectGroups}
                multiple={false}
                onChange={setSelectedProjectGroups}
              />
            </Box>
          </Stack>
        ) : (
          <Box
            borderTop="1px solid var(--ui04)"
            borderRight="1px solid var(--ui04)"
          >
            <ExpandButton
              icon={
                !projectGroupsPanelOpen ? <ChevronRight /> : <ChevronLeft />
              }
              textStart="top"
              title={t('COMMON.GROUPS')}
              color="text03"
              onClick={() => setProjectGroupsPanelOpen(!projectGroupsPanelOpen)}
            />
          </Box>
        )}
        {leftAreaCollapsed && (
          <Box
            borderTop="1px solid var(--ui04)"
            borderRight="1px solid var(--ui04)"
          >
            <ExpandButton
              icon={leftAreaCollapsed ? <ChevronRight /> : <ChevronLeft />}
              textStart="top"
              title={t('COMMON.DETAILS')}
              color="text03"
              onClick={() => setLeftAreaCollapsed(!leftAreaCollapsed)}
            />
          </Box>
        )}
        <Stack height="100%" width="100%">
          {timeline}
          {bottomSectionCollapsed ? (
            <BottomTabs
              collapsed={bottomSectionCollapsed}
              onCollapsedChanged={setBottomSectionCollapsed}
            />
          ) : (
            <Box flexGrow={1}>
              <ResizableArea
                handles={'top'}
                size={{ height: bottomSectionHeight }}
                direction="vertical"
                onResize={(e) => setBottomSectionHeight(e.height)}
              >
                <Stack height="100%" minHeight={bottomSectionHeight}>
                  <BottomTabs
                    collapsed={bottomSectionCollapsed}
                    onCollapsedChanged={setBottomSectionCollapsed}
                  />
                  {resourceTimeline}
                </Stack>
              </ResizableArea>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export const BottomTabs = (props: {
  collapsed: boolean;
  onCollapsedChanged: (collapsed: boolean) => void;
}) => {
  const { collapsed, onCollapsedChanged } = props;
  const { t } = useTranslation();
  return (
    <Stack direction="row" padding="0.5rem 0">
      <IconButton
        onClick={() => {
          onCollapsedChanged(!collapsed);
        }}
      >
        {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      <Tabs
        value={collapsed ? false : 'resources'}
        onChange={() => {
          onCollapsedChanged(false);
        }}
      >
        <Tab value={'resources'} label={t('COMMON.UTILIZATION')} />;
      </Tabs>
    </Stack>
  );
};

export const ProjectPlanningViewOverlayController = (props: IProps) => {
  return (
    <ProjectPlanningView
      {...props}
      showFullscreenAction={false}
      showAddAction
      showDateSelector
      showPrintAction
      showTimeUnitControl
      showToolbar
      showZoomControl
      showLayoutSelector
      showResourceFilter
      leftAreaCollapsed={false}
    />
  );
};
