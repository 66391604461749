import styles from './DateTimeInputPicker.module.scss';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { dateTimeFromString } from '@work4all/utils/lib/date-utils/dateTimeFromString';
import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';
import { validDate } from '@work4all/utils/lib/date-utils/validDate';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { Caption } from '../../typography/caption/Caption';
import { LabeledDateInputWithDropdown } from '../labeled-date-input';
import { LabeledTimeInputWithDropdown } from '../labeled-time-input';
import { MultiStepControls, Step } from '../multi-step-controls';
import { timeUtils } from '../time-input-picker/timeUtils';

import { useInputState } from './hooks/useInputState';
import { triggerChangeEvent } from './hooks/useOnChangeTrigger';
import { usePickerVisibilityState } from './hooks/usePickerVisibilityState';
import { IDateTimePickerProps } from './types';

export const validateDateString = (dateString?: string) => {
  if (!dateString) {
    return false;
  }
  const dt = dateTimeFromString(dateString);
  return dt.isValid ? dt : false;
};

const dateToString = (date?: DateTime) => {
  if (!date) {
    return '';
  }

  return date.toLocaleString(DateTimeCustom.DATE_SHORT);
};

const parseDateString = (dateString: string | Date) => {
  let value: Date | DateTime | string;
  if (typeof dateString === 'string') {
    value = DateTime.fromISO(dateString);
  } else {
    value = DateTime.fromJSDate(dateString);
  }
  return value && value.isValid && validDate(value.toJSDate()) ? value : null;
};

const EnhancedDateInput: React.FC<
  IDateTimePickerProps & {
    hiddenInputField: HTMLInputElement;
    anchorEl: HTMLDivElement;
  }
> = (props) => {
  const {
    withTime = true,
    dateLabel,
    timeLabel,
    required = false,
    anchorCenterElRef,
    anchorEl,
    hiddenInputField,
    clearable = true,
    disabled,
    disabledDate,
  } = props;

  const { t } = useTranslation();
  const [activeValue, setActiveValue] = useState<string>();

  const activeRef = useRef<string>();
  const dateInputRef = useRef<HTMLInputElement>();
  const timeInputRef = useRef<HTMLInputElement>();
  activeRef.current = activeValue;

  const {
    showPicker: showDatePicker,
    onShowPicker: onShowDatePicker,
    onHidePicker: onHideDatePicker,
  } = usePickerVisibilityState();

  const {
    showPicker: showTimePicker,
    onShowPicker: onShowTimePicker,
    onHidePicker: onHideTimePicker,
  } = usePickerVisibilityState();

  const { defaultDate, defaultTime, defaultValue } = useMemo(() => {
    return {
      defaultDate: parseDateString(activeValue),
      defaultTime: parseDateString(activeValue),
      defaultValue: parseDateString(activeValue),
    };
  }, [activeValue]);

  const { date, setDate, dateString, ...dateInputStateProps } = useInputState(
    validateDateString,
    dateToString,
    defaultDate,
    (date) => emitChange({ date })
  );

  const {
    date: time,
    setDate: setTime,
    dateString: timeString,
    ...timeInputProps
  } = useInputState(
    timeUtils.validateTimeString,
    timeUtils.timeToString,
    defaultTime,
    (time) => emitChange({ time })
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //this needs to be performed always as a sideeffect to be evaluated in the next renderframe
    if (hiddenInputField?.value !== activeRef.current) {
      setActiveValue(hiddenInputField?.value);
    }
  });

  // Emit change event on the input when date/time is changed.
  function emitChange({
    date: newDate,
    time: newTime,
  }: {
    date?: DateTime;
    time?: DateTime;
  }) {
    triggerChangeEvent(
      defaultValue,
      hiddenInputField,
      newDate ?? date,
      newTime ?? time
    );
  }

  const dateInputTestId =
    props['data-testid'] && `${props['data-testid']}-date`;
  const timeInputTestId =
    props['data-testid'] && `${props['data-testid']}-time`;

  function makeHandleKeyDown(handler: () => void) {
    return function handleKeyDown(event: React.KeyboardEvent) {
      if (
        event.key === 'Enter' ||
        event.key === 'Escape' ||
        event.key === 'Tab'
      ) {
        handler();
      }
    };
  }

  return (
    <MultiStepControls>
      <Step active={true} index={0}>
        <LabeledDateInputWithDropdown
          disabled={disabled || disabledDate}
          anchorEl={anchorEl}
          anchorCenterEl={anchorCenterElRef?.current}
          required={required}
          inputProps={{
            'data-testid': dateInputTestId,
            ref: dateInputRef,
          }}
          value={dateString}
          label={
            dateLabel ||
            (date
              ? t('COMMON.DATE')
              : `${t('COMMON.DATE')} / ${t('COMMON.TIME')}`)
          }
          showDropdown={showDatePicker}
          onHideDropdown={onHideDatePicker}
          onShowDropdown={(e) => {
            e.preventDefault();
            onShowDatePicker();
          }}
          onDateSelect={(date) => {
            setDate(date);
            emitChange({ date });
            dateInputRef?.current?.focus();
          }}
          onClear={
            clearable === true
              ? () => {
                  setDate(null);
                  emitChange({ date: null });
                }
              : undefined
          }
          onIconClick={onShowDatePicker}
          onKeyDown={makeHandleKeyDown(onHideDatePicker)}
          {...dateInputStateProps}
          {...props.dateInputProps}
          style={disabledDate ? { cursor: 'default' } : null}
        />
      </Step>
      <Step
        active={
          /*date needs to be set first to activate timepicker*/ Boolean(date) &&
          withTime
        }
        index={1}
      >
        <LabeledTimeInputWithDropdown
          disabled={disabled}
          anchorEl={anchorEl}
          anchorCenterEl={anchorCenterElRef?.current}
          required={required}
          inputProps={{
            'data-testid': timeInputTestId,
            ref: timeInputRef,
          }}
          value={timeString}
          label={timeLabel || t('COMMON.TIME')}
          placeholder="hh:mm"
          showDropdown={showTimePicker}
          onHideDropdown={onHideTimePicker}
          onShowDropdown={onShowTimePicker}
          onTimeSelect={(time) => {
            setTime(time);
            emitChange({ time });
            timeInputRef?.current?.focus();
          }}
          onKeyDown={makeHandleKeyDown(onHideTimePicker)}
          {...props.timeInputProps}
          {...timeInputProps}
        />
      </Step>
    </MultiStepControls>
  );
};

export const DateTimeInputPicker = React.forwardRef<
  HTMLInputElement,
  IDateTimePickerProps
>(function DateTimeInputPicker(props, ref) {
  const {
    value = '',
    error,
    disabled,
    disabledDate: _disabledDate,
    dateLabel: _dateLabel,
    withTime: _withTime,
    timeLabel: _timeLabel,
    clearable: _clearable,
    anchorCenterElRef: _anchorCenterElRef,
    ...inputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const anchorElRef = useRef<HTMLDivElement>();
  const [mounted, setMounted] = useState(false);

  //we only do render the enhanced markup after we have the ref to our inputfield so that we can safley determine if the initial value is the default value or stems from the field
  useEffect(() => {
    setMounted(true);
  }, []);

  const forceUpdate = useForceUpdate();

  // Force the component to render when something (form) changes the value via the ref.
  useImperativeHandle(
    ref,
    () => {
      return {
        get value() {
          return inputRef.current?.value ?? '';
        },

        set value(newValue) {
          if (inputRef.current) {
            inputRef.current.value = newValue;
            forceUpdate();
          }
        },

        focus() {
          inputRef.current.focus();
        },

        // Add other properties if needed.
      } as HTMLInputElement;
    },
    [forceUpdate]
  );

  const inputValue = toInputValue(value);

  return (
    <div
      ref={anchorElRef}
      className={clsx(styles.wrapper, {
        [styles.error]: error,
      })}
    >
      {error !== undefined && (
        <div className={styles.errorMessage}>
          <Caption color="error">{error}</Caption>
        </div>
      )}
      {/** input holds string (a custom dateformat or ISO by default) thats created by merging "date" and "time" objects */}
      <input ref={inputRef} {...inputProps} hidden value={inputValue} />
      {mounted && (
        <EnhancedDateInput
          {...props}
          disabled={disabled}
          anchorEl={anchorElRef.current}
          hiddenInputField={inputRef.current}
        />
      )}
    </div>
  );
});

function toInputValue(date: string | Date): string | undefined {
  if (!date) {
    return undefined;
  }

  if (typeof date === 'string') {
    return date;
  }

  return DateTime.fromJSDate(date).toISO();
}
