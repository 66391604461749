import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';

import { usePaymentMask } from '../../../hooks/use-payment-mask';
import { useBottomViews } from '../../bottom-views';
import { DueDateClassifiedCell } from '../DueDateClassifiedCell';
import { DueDaysCell } from '../DueDaysCell';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { InvoiceKindCell } from '../InvoiceKindCell';
import { PaymentStatusCell } from '../PaymentStatusCell';
import { PermitStatusCell } from '../PermitStatusCell';
import { ReAccountingStatusCell } from '../ReAccountingStatusCell';
import schema from '../schemata/reViewModel-table-schema.json';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';

const defaultSort = [
  { field: 'invoiceDate', direction: SortDirection.DESCENDING },
];

const DISABLED_COLUMNS = ['unused'];

const DELETABLE_STATUS = [
  PaymentStatus.ZERO_INVOICE,
  PaymentStatus.OPEN_CREDIT,
  PaymentStatus.OPEN,
  PaymentStatus.DUE,
];

const forceRequestFields: ReViewModel = {
  invoiceNumber: null,
  datevDate: null,
};
export const ReTable = React.forwardRef<TableInstance, IEntityTable>(
  function ReTable(_props, ref) {
    const { entity: entityType } = schema as never;

    const dataTable = useEntityDataTable<ReViewModel, never>({
      schema: schema as never,
      defaultSort,
      enableFooter: true,
      cells: {
        PaymentStatus: PaymentStatusCell,
        ReAccountingStatus: ReAccountingStatusCell,
        PermitStatus: PermitStatusCell,
        InvoiceKind: InvoiceKindCell,
        DueDateClassified: DueDateClassifiedCell,
        DueDays: DueDaysCell,
      },
      forceRequestFields,
    });

    const template = useErpHandlersTemplate('supplier.id', Entities.supplier);

    const { t } = useTranslation();

    const openPaymentMaskAction = usePaymentMask(
      Entities.reViewModel,
      dataTable.refetch
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.inboundInvoice,
    });

    const prefilter = [{ id: { $eq: dataTable.selectedEntity?.id } }];
    const bottomArea = useBottomViews({
      skip: !dataTable.selectedEntity,
      views: [
        {
          type: 'table',
          entity: Entities.rELedgerAccountSplit,
          titleTranslationKey: 'COMMON.BOOKINGS',
          options: {
            prefilter,
          },
        },
        {
          type: 'table',
          entity: Entities.inboundInvoicePayment,
          titleTranslationKey: 'COMMON.PAYMENTS',
          options: {
            prefilter,
          },
        },
      ],
    });

    useEntityEvents((event) => {
      if (event.type === 'delete' && event.entity === Entities.inboundInvoice) {
        dataTable.refetch();
      }
    });

    return (
      <EntityTable
        displayFooter
        ref={ref}
        template={template}
        areas={{
          bottom: bottomArea,
        }}
        actions={{
          custom: { left: [openPaymentMaskAction] },
          remove: {
            ...deleteConfig,
            canDeleteEntity: (id) => {
              const entity = dataTable.selectedEntities.find(
                (item) => item.id === Number(id)
              );
              const canDelete =
                DELETABLE_STATUS.includes(entity.paymentStatus) &&
                !entity.datevDate;

              const preventMessage = !DELETABLE_STATUS.includes(
                entity.paymentStatus
              )
                ? t('ALERTS.OUTGOING_INVOICE_PAYMENTS_EXIST', {
                    number: entity?.invoiceNumber,
                  })
                : entity.datevDate
                ? t('ALERTS.OUTGOING_INVOICE_HANDOVER', {
                    number: entity?.invoiceNumber,
                  })
                : null;

              return {
                value: canDelete,
                preventMessage: preventMessage,
              };
            },
          },
        }}
        {...dataTable}
        {..._props}
        disabledColumns={DISABLED_COLUMNS}
      />
    );
  }
);
