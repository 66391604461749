import styles from './RowSizePicker.module.scss';

import { ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { PickerTargetButton } from '../../../../../../../../locked-inputs';

export const RowSizePicker = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const erpSize = useSetting(settings.erpRowSize());

  const ref = useRef();

  const actions: (typeof erpSize.value)[] = ['SINGLE', 'SINGLE-AUTO', 'AUTO'];

  const onClick = (action: typeof erpSize.value) => {
    setOpen(false);
    erpSize.set(action);
  };

  return (
    <div ref={ref}>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        classes={{ paper: styles.root }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {actions.map((action) => (
          <ListItem key={action} disablePadding className={styles.pickerSize}>
            <ListItemButton role={undefined} onClick={() => onClick(action)}>
              <ListItemText primary={t(`POSITIONS.ROW_SIZE.${action}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </Popover>
      <PickerTargetButton
        className={styles.pickerSize}
        label={t('POSITIONS.ROW_SIZE.TOOLTIP')}
        value={t(`POSITIONS.ROW_SIZE.${erpSize.value}`)}
        onClick={() => setOpen(true)}
      />
    </div>
  );
};
