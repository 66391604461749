import styles from './ProjectRessourceClassCell.module.scss';

import {
  Box,
  Button,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatAsFloat } from '@work4all/utils';

import { settings, useSetting } from '../../../../settings';

const erpAccessors = {
  [Entities.calculation]: {
    number: 'number',
    date: 'date',
  },
  [Entities.contract]: {
    number: 'contractNumber',
    date: 'contractDate',
  },
};

export const ProjectRessourceClassCell = (props: {
  ressourceClass: LookUp;
  target: number;
  planned: number;
  actual: number;
  targetLoading: boolean;
  plannedLoading: boolean;
  actualLoading: boolean;
  onClick: () => void;
  selected: boolean;
  appointmentList?: Appointment[];
  timeTrackingList?: TimeTracking[];
  erpList?: (Contract | Calculation)[];
  erpEntityType: Entities.contract | Entities.calculation;
  onUtilizationClick?: (userIds: number[]) => void;
}) => {
  const projectPlanningColumns = useSetting(settings.projectPlanningColumns());

  const {
    actual,
    planned,
    target,
    targetLoading,
    actualLoading,
    plannedLoading,
    onClick,
    selected = false,
    appointmentList = [],
    timeTrackingList = [],
    erpList = [],
    erpEntityType = Entities.contract,
    onUtilizationClick,
  } = props;

  const { t } = useTranslation();

  const projectPlanningTargetSource = useSetting(
    settings.projectPlanningTargetSource()
  );

  const groupedAppointments = useMemo<Record<string, Appointment[]>>(() => {
    return appointmentList.reduce((acc, item) => {
      const key = DateTime.fromISO(item.startDate).startOf('day').toISODate();
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {}) as Record<string, Appointment[]>;
  }, [appointmentList]);

  const contractTable = useMemo(() => {
    return (
      <>
        <Typography variant="h4" p="0.5rem 0 0 1rem">
          {t(`COMMON.${projectPlanningTargetSource.value.toUpperCase()}`, {
            count: 2,
          })}
        </Typography>
        <table
          className={styles.tooltipTable}
          cellPadding={0}
          cellSpacing={remToPx(1)}
          width="100%"
        >
          {erpList.map((contract) => (
            <tr>
              <td> {contract[erpAccessors[erpEntityType].number]} </td>
              <td> | </td>
              <td style={{ overflow: 'hidden', maxWidth: '12rem' }}>
                {contract.note}
              </td>
            </tr>
          ))}
        </table>
      </>
    );
  }, [erpEntityType, erpList, projectPlanningTargetSource.value, t]);

  const appointmentTable = useMemo(() => {
    return (
      <>
        <Stack direction="row" width="100%">
          <Typography variant="h4" p="0.5rem 0 0 1rem" flex={1}>
            {t('COMMON.APPOINTMENT', { count: 2 })}
          </Typography>
          <Button
            variant="outlined"
            onClick={() =>
              onUtilizationClick?.([
                ...new Set(appointmentList.map((x) => x.user.id)),
              ])
            }
          >
            {t('COMMON.UTILIZATION')}
          </Button>
        </Stack>
        <table
          className={styles.tooltipTable}
          cellPadding={0}
          cellSpacing={remToPx(1)}
        >
          {Object.keys(groupedAppointments).map((date) => {
            const appointments = groupedAppointments[date];
            const total = appointments.reduce(
              (a, b) => a + b.projectProcessDuration,
              0
            );
            return (
              <tr>
                <td>{DateTime.fromISO(date).toFormat('dd.MM.yy')}</td>
                <td>
                  <Stack direction="row" gap="0.5rem">
                    {[...new Set(appointments.map((x) => x.user.id))].map(
                      (userId) => (
                        <HookedUserIcon userId={userId} size="m" />
                      )
                    )}
                  </Stack>
                </td>
                <td align="right">
                  {formatAsFloat(total, {
                    maximumFractionDigits: 2,
                  })}{' '}
                  {t('COMMON.DAY_SHORT')}{' '}
                </td>
              </tr>
            );
          })}
        </table>
      </>
    );
  }, [groupedAppointments, t, onUtilizationClick, appointmentList]);

  const timetrackingTable = useMemo(() => {
    return (
      <>
        <Typography variant="h4" p="0.5rem 0 0 1rem">
          {t('COMMON.PROJECTTIME')}
        </Typography>
        <table
          className={styles.tooltipTable}
          cellPadding={0}
          cellSpacing={remToPx(1)}
        >
          {timeTrackingList.map((tr) => (
            <tr>
              <td>{DateTime.fromISO(tr.startDateTime).toFormat('dd.MM.yy')}</td>
              <td style={{ maxWidth: '8rem' }}>{tr.user?.displayName}</td>
              <td align="right">
                {formatAsFloat(tr.amount / 8, {
                  maximumFractionDigits: 2,
                })}
              </td>
              <td> {t('COMMON.DAY_SHORT')} </td>
            </tr>
          ))}
        </table>
      </>
    );
  }, [timeTrackingList, t]);

  return (
    <div
      className={clsx(styles.ressourceClassCell, {
        [styles.selected]: selected,
      })}
      onClick={onClick}
    >
      <Stack direction="row" width="100%">
        {projectPlanningColumns.value.target && (
          <Box
            flex="1"
            justifyContent="center"
            justifyItems="center"
            borderRight="1px solid var(--ui04)"
          >
            {!target && targetLoading ? (
              <Skeleton width="1.5rem" height="1.5rem" />
            ) : (
              <Tooltip title={contractTable}>
                <Typography>
                  {target
                    ? formatAsFloat(target, { maximumFractionDigits: 2 })
                    : ''}
                </Typography>
              </Tooltip>
            )}
          </Box>
        )}
        {projectPlanningColumns.value.planned && (
          <Box
            flex="1"
            justifyContent="center"
            justifyItems="center"
            borderRight="1px solid var(--ui04)"
          >
            {!planned && plannedLoading ? (
              <Skeleton width="1.5rem" height="1.5rem" />
            ) : (
              <Tooltip title={appointmentTable}>
                <Typography
                  flex="1"
                  style={{
                    color: planned >= target ? 'var(--success)' : undefined,
                  }}
                >
                  {planned
                    ? formatAsFloat(planned, { maximumFractionDigits: 2 })
                    : ''}
                </Typography>
              </Tooltip>
            )}
          </Box>
        )}
        {projectPlanningColumns.value.rest && (
          <Box
            flex="1"
            justifyContent="center"
            justifyItems="center"
            borderRight="1px solid var(--ui04)"
          >
            {!planned && (plannedLoading || targetLoading) ? (
              <Skeleton width="1.5rem" height="1.5rem" />
            ) : (
              <Tooltip title={appointmentTable}>
                <Typography
                  flex="1"
                  style={{
                    color:
                      target - planned === 0 ? 'var(--success)' : undefined,
                  }}
                >
                  {planned
                    ? formatAsFloat(target - planned, {
                        maximumFractionDigits: 2,
                      })
                    : ''}
                </Typography>
              </Tooltip>
            )}
          </Box>
        )}
        {projectPlanningColumns.value.actual && (
          <Box flex="1" justifyContent="center" justifyItems="center">
            {!actual && actualLoading ? (
              <Skeleton width="1.5rem" height="1.5rem" />
            ) : (
              <Tooltip title={timetrackingTable}>
                <Typography
                  flex="1"
                  style={{
                    color: actual > target ? 'var(--error)' : undefined,
                  }}
                >
                  {actual
                    ? formatAsFloat(actual, { maximumFractionDigits: 2 })
                    : ''}
                </Typography>
              </Tooltip>
            )}
          </Box>
        )}
      </Stack>
    </div>
  );
};
