import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { Customer } from './Customer.entity';
import { FileEntity } from './FileEntity.entity';
import { LookUp } from './LookUp.entity';
import { Project } from './Project.entity';
import { Supplier } from './Supplier.entity';
import { TalkingPoint } from './TalkingPoint.entity';
import { User } from './User.entity';
import { VisitationReportAttendee } from './VisitationReportAttendee.entity';

export class VisitationReport<T extends EMode = EMode.entity> {
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: ansprechpartnerKunde */
  customerContact?: Contact<T>;
  /** Alias: ansprechpartnerLieferant */
  supplierContact?: Contact<T>;
  /** Alias: art */
  kind?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kNType */
  sdObjMemberType?: number;
  /** Alias: klassifizierung */
  classification?: LookUp<T>;
  /** Alias: klassifizierungCode */
  classificationId?: number;
  /** Alias: kunde */
  customer?: Customer<T>;
  /** Alias: lieferant */
  supplier?: Supplier<T>;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: ort */
  city?: string;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: templateName */
  templateName?: string;
  /** Alias: thema */
  topic?: string;
  /** Alias: uhrzeitBis */
  endTime?: string;
  /** Alias: uhrzeitVon */
  startTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: datei */
  file?: string;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: teilnehmer */
  attendeeList?: VisitationReportAttendee<T>[];
  /** Alias: gespraechspunkte */
  talkingPointList?: TalkingPoint<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<VisitationReport> = {
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  customerContact: {
    alias: 'ansprechpartnerKunde',
    entity: Entities.contact,
  },
  supplierContact: {
    alias: 'ansprechpartnerLieferant',
    entity: Entities.contact,
  },
  kind: {
    alias: 'art',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  insertTime: {
    alias: 'insertTime',
  },
  sdObjMemberType: {
    alias: 'kNType',
  },
  classification: {
    alias: 'klassifizierung',
    entity: Entities.lookUp,
  },
  classificationId: {
    alias: 'klassifizierungCode',
  },
  customer: {
    alias: 'kunde',
    entity: Entities.customer,
  },
  supplier: {
    alias: 'lieferant',
    entity: Entities.supplier,
  },
  note: {
    alias: 'notiz',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  city: {
    alias: 'ort',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  templateName: {
    alias: 'templateName',
  },
  topic: {
    alias: 'thema',
  },
  endTime: {
    alias: 'uhrzeitBis',
  },
  startTime: {
    alias: 'uhrzeitVon',
  },
  updateTime: {
    alias: 'updateTime',
  },
  file: {
    alias: 'datei',
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  attendeeList: {
    alias: 'teilnehmer',
    entity: Entities.visitationReportAttendee,
  },
  talkingPointList: {
    alias: 'gespraechspunkte',
    entity: Entities.talkingPoint,
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const visitationReportEntityDefinition: EntitiyDefinition<VisitationReport> =
  {
    local: {},
    remote: {
      queryName: 'getBesuchsberichte',
      fragmentName: 'Besuchsbericht',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertBesuchsbericht',
          args: [
            { name: 'input', type: 'InputBesuchsbericht!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
