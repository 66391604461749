import { DateTime } from 'luxon';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation } from '@work4all/data';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { InputDailyTimeTargets } from '@work4all/models/lib/Classes/InputDailyTimeTargets.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { GeneralTabPanel } from './components/general/GeneralTabPanel';
import { PasswordChangeButton } from './components/password-change/PasswordChangeButton';
import { PersonalTabPanel } from './components/personal/PersonalTabPanel';
import { WorkTimeTabPanel } from './components/work-time/WorkTimeTabPanel';
import { useUserFormUpdate } from './hooks/use-user-form-update';
import { useUserRequest } from './hooks/use-user-request';
import { makeOnMutationError } from './MakeOnMutationError';
import { UserFormValue } from './types';

type UserFormRelation = {
  dailyTimeTargets?: InputDailyTimeTargets;
  userImageFromTempFile?: string;
};
const newEntityData: FullUser = {};

export const UserOverlayController: React.FC<MaskControllerProps> = (props) => {
  const mask = useMaskConfig(props);
  const request = useUserRequest({ id: mask.id });

  const normalizeData = useCallback((input: UserFormValue) => {
    const output = { ...input };
    if (output.birthDate === '0001-01-01T00:00:00') output.birthDate = null;
    if (output.enterDate === '0001-01-01T00:00:00') output.enterDate = null;
    if (output.exitDate === '0001-01-01T00:00:00') output.exitDate = null;

    const getDay = (targets, weekday) => {
      const target = (targets || []).find((entry) => entry.weekday === weekday);
      return target ? target.dailytarget : 0; // Default to 0 if not found
    };
    const userWorkTimeDaylieTargets = output.userWorkTimeDaylieTargets;
    output.dailyTargetWorkHoursMonday = getDay(userWorkTimeDaylieTargets, 0);
    output.dailyTargetWorkHoursTuesday = getDay(userWorkTimeDaylieTargets, 1);
    output.dailyTargetWorkHoursWednesday = getDay(userWorkTimeDaylieTargets, 2);
    output.dailyTargetWorkHoursThursday = getDay(userWorkTimeDaylieTargets, 3);
    output.dailyTargetWorkHoursFriday = getDay(userWorkTimeDaylieTargets, 4);
    output.dailyTargetWorkHoursSaturday = getDay(userWorkTimeDaylieTargets, 5);
    output.dailyTargetWorkHoursSunday = getDay(userWorkTimeDaylieTargets, 6);
    output.earliestCommingTime = DateTime.now()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .plus({ minutes: output.comesGoesMinimumStartTime || 0 })
      .toISO();

    return output;
  }, []);

  const { t } = useTranslation();

  const customRules = useCallback(
    (data: UserFormValue) => {
      const requiredFields = ['eMail', 'name'];
      for (const field of requiredFields) {
        if (!data[field]) {
          return {
            [field]: {
              message: t('ERROR.FIELD_REQUIRED'),
              type: 'customValidation',
            },
          };
        }
      }

      return true;
    },
    [t]
  );

  const overlay = useMaskOverlay<UserFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    getSubTitle: (x) => x.displayName,
    normalizeData,
    customRules,
  });

  useUserFormUpdate(overlay.form);

  const [mutate] = useDataMutation<FullUser, EMode.upsert, UserFormRelation>({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: (data) => {
      props.onAfterSave(data);
    },
    onError: makeOnMutationError<UserFormValue>(
      overlay.form,
      Entities.fullUser
    ),
  });

  const onSubmit = async (formData: UserFormValue) => {
    const toModify = pickUpdateFields(
      formData,
      overlay.form.formState.dirtyFields
    );
    const relations = mapUserRelations(toModify);

    if (toModify.earliestCommingTime) {
      toModify.comesGoesMinimumStartTime =
        DateTime.fromISO(toModify.earliestCommingTime).hour * 60 +
        DateTime.fromISO(toModify.earliestCommingTime).minute;
      delete toModify.earliestCommingTime;
    }
    mutate(toModify, { relations });
  };

  return (
    <OverlayController<UserFormValue>
      {...overlay}
      onSubmit={onSubmit}
      actions={<PasswordChangeButton />}
      tabs={<Tabs />}
    >
      <Content />
    </OverlayController>
  );
};

const Tabs = memo(function () {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')} />
      <MaskTab value="misc" label={t('USER.PERSONAL_DATA_TAB')} />
      <MaskTab value="prices" label={t('USER.WORK_TIME_TAB')} />
    </MaskTabs>
  );
});

const Content = memo(function () {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="misc">
        <PersonalTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="prices">
        <WorkTimeTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});

const getDailyTimeTargets = (input: UserFormValue) => {
  const values = [
    input.dailyTargetWorkHoursMonday ?? 0,
    input.dailyTargetWorkHoursTuesday ?? 0,
    input.dailyTargetWorkHoursThursday ?? 0,
    input.dailyTargetWorkHoursFriday ?? 0,
    input.dailyTargetWorkHoursSaturday ?? 0,
    input.dailyTargetWorkHoursSunday ?? 0,
  ];

  const allZero = values.every((value) => value === 0);
  return { allZero, values };
};
const mapUserRelations = (input: UserFormValue): UserFormRelation => {
  const { values, allZero } = getDailyTimeTargets(input);
  const userImageFromTempFile = input.userImageFromTempFile;
  if (allZero) {
    return {
      userImageFromTempFile,
    };
  }

  return {
    dailyTimeTargets: {
      monday: values[0],
      tuesday: values[1],
      wednesday: values[2],
      thursday: values[3],
      friday: values[4],
      saturday: values[5],
      sunday: values[6],
    },
    userImageFromTempFile,
  };
};
