import { useMemo } from 'react';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export function useUserRequest({ id }: { id?: string | number }) {
  const request = useMemo(() => {
    const filter = [{ id: { $eq: id } }];

    const data: FullUser = {
      id: null,
      letterSalutation: null,
      salutation: {
        id: null,
        name: null,
        standardletterSalutation: null,
      },
      title: null,
      shortName: null,
      firstName: null,
      lastName: null,
      role: null,

      prefix: null,
      iA: null,
      iV: null,
      alternativeSalutation: null,
      displayName: null,
      name: null,
      eMail: null,
      mobileNumber: null,
      phoneNumber: null,
      faxNumber: null,
      vacationApprover: {
        id: null,
        displayName: null,
      },
      departmentName: null,
      supplier: {
        id: null,
        name: null,
      },
      supervisor: {
        id: null,
        displayName: null,
      },
      branch: {
        id: null,
        name: null,
      },
      objGrId: null,
      role2: null,
      group: {
        id: null,
        name: null,
      },
      costCenter: {
        id: null,
        name: null,
      },
      typeofRemuneration: null,
      defaultGroupForCustomer: {
        id: null,
        name: null,
      },
      ressourceClass: {
        id: null,
        name: null,
      },
      note: null,
      designation: null,
      // personal data
      street: null,
      number: null,
      nationality: null,
      birthDate: null,
      placeOfBirth: null,
      socialSecurityNumber: null,
      healthInsuranceCompany: null,
      enterDate: null,
      exitDate: null,
      bankData: null,
      iban: null,
      bic: null,
      wageRate1: null,
      wageRate2: null,
      wageRate3: null,
      postalCode: null,
      location: null,
      state: null,
      eMailPrivate: null,
      telefonPrivate: null,
      mobilPrivate: null,
      telefaxPrivate: null,
      // work times

      timestampTargetMinutesPerDay: null,
      // missing whole row

      maxWorkingHours: null,
      maxWorkHoursMonday: null,
      maxWorkHoursTuesday: null,
      maxWorkHoursWednesday: null,
      maxWorkHoursThursday: null,
      maxWorkHoursFriday: null,
      maxWorkHoursSaturday: null,
      maxWorkHoursSunday: null,
      vacationValue: null,
      // no working time profile & skipped for now
      timestampRecordingVariant: null,
      timing: null,
      markHolidaysAsWorkingHours: null,
      timestampRoundingMorning: null,
      timestampRoundingEvening: null,
      timestampRoundingFromTime: null,
      roundingForHour: null,
      earliestCommingTime: null,
      timestampIncludedMinutesPerDay: null,
      comesGoesInclWeek: null,
      timestampIncludedMinutesPerMonth: null,
      comesGoesMinimalPause: null,
      comesGoesMinimumStartTime: null,
      userWorkTimeDaylieTargets: [
        {
          id: null,
          dailytarget: null,
          weekday: null,
        },
      ],
      workTimeTrackingBreakRule: {
        id: null,
        name: null,
      },
    };

    const request: DataRequest = {
      entity: Entities.fullUser,
      data,
      filter,
    };

    return request;
  }, [id]);

  return request;
}
