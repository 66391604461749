import { gql, useMutation } from '@apollo/client';

import { InputGroup } from '@work4all/models/lib/Classes/InputGroup.entity';
import { MutateProjectGroups } from '@work4all/models/lib/Classes/MutateProjectGroups.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationAreaEntityGroups } from '../types';

const PROJECT_GROUP_MUTATION = gql`
  mutation MutateProjectGroups($input: MutateProjectGroups) {
    mutateProjectGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const CUSTOMER_GROUP_MUTATION = gql`
  mutation MutateCustomerGroups($input: MutateCustomerGroups) {
    mutateCustomerGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const SUPPLIER_GROUP_MUTATION = gql`
  mutation MutateSupplierGroups($input: MutateSupplierGroups) {
    mutateSupplierGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const ARTICLE_GROUP_MUTATION = gql`
  mutation MutateArticleGroups($input: MutateArticleGroups) {
    mutateArticleGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

export type MutateProjectGroupsResponse = {
  mutateProjectGroups: InputGroup[];
};

export type MutateCustomerGroupsResponse = {
  mutateCustomerGroups: InputGroup[];
};

export type MutateSupplierGroupsResponse = {
  mutateSupplierGroups: InputGroup[];
};

export type MutateArticleGroupsResponse = {
  mutateArticleGroups: InputGroup[];
};

export type MutateGroupsVars = {
  input: MutateProjectGroups;
};

export const useMutateGroups = (groupEntity: OrganisationAreaEntityGroups) => {
  const [mutateProjectGroups] = useMutation<
    MutateProjectGroupsResponse,
    MutateGroupsVars
  >(PROJECT_GROUP_MUTATION);

  const [mutateCustomerGroups] = useMutation<
    MutateCustomerGroupsResponse,
    MutateGroupsVars
  >(CUSTOMER_GROUP_MUTATION);

  const [mutateSupplierGroups] = useMutation<
    MutateSupplierGroupsResponse,
    MutateGroupsVars
  >(SUPPLIER_GROUP_MUTATION);

  const [mutateArticleGroups] = useMutation<
    MutateArticleGroupsResponse,
    MutateGroupsVars
  >(ARTICLE_GROUP_MUTATION);

  switch (groupEntity) {
    case Entities.projectGroup:
      return {
        mutateGroups: mutateProjectGroups,
        type: 'mutateProjectGroups',
      };
    case Entities.customerGroup:
      return {
        mutateGroups: mutateCustomerGroups,
        type: 'mutateCustomerGroups',
      };
    case Entities.supplierGroup:
      return {
        mutateGroups: mutateSupplierGroups,
        type: 'mutateSupplierGroups',
      };
    case Entities.articleGroup:
      return {
        mutateGroups: mutateArticleGroups,
        type: 'mutateArticleGroups',
      };
    default:
      return;
  }
};
