import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useGroups } from '../../../hooks/use-groups';
import { GrouPickerItem, GroupPicker } from '../GroupPicker';

export interface EntityGroupPickerProps {
  value: GrouPickerItem<number>[] | null;
  onChange: (value: GrouPickerItem<number>[]) => void;
  multiple?: boolean;
  entity: Entities;
}

export function EntityGroupPicker(props: EntityGroupPickerProps) {
  const { value, onChange, entity } = props;

  const response = useGroups(entity);

  const groups = useMemo(() => {
    if (entity !== Entities.userGroup) return response.data?.groups ?? null;

    if (response.data?.groups) {
      const cloned = cloneDeep(response.data.groups);
      return cloned
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((x, index) => ({ ...x, index }));
    }

    return [];
  }, [entity, response.data?.groups]);

  return (
    <GroupPicker
      value={value}
      onChange={onChange}
      groups={groups}
      multiple={props.multiple}
    />
  );
}

export const ProjectGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.projectGroup} {...props} />;

export const ArticleGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.articleGroup} {...props} />;

export const CustomerGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.customerGroup} {...props} />;

export const SupplierGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.supplierGroup} {...props} />;

export const UserGroupPicker = (
  props: Omit<EntityGroupPickerProps, 'entity'>
) => <EntityGroupPicker entity={Entities.userGroup} {...props} />;
