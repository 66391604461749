import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { CustomField } from '@work4all/models/lib/Classes/CustomField.entity';
import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

import { ControlWrapper } from '../control-wrapper';

import { encodeCustomFieldName } from './custom-field-name';
import { SimpleCustomCheckboxField } from './fields/SimpleCustomCheckboxField';
import { SimpleCustomDateField } from './fields/SimpleCustomDateField';
import { SimpleCustomNumberField } from './fields/SimpleCustomNumberField';
import { SimpleCustomPickerField } from './fields/SimpleCustomPickerField';
import { SimpleCustomTextField } from './fields/SimpleCustomTextField';
import { SimpleCustomFieldProps } from './fields/types';
import {
  CustomFieldGroup,
  CustomFieldSubgroup,
  groupCustomFields,
} from './group-custom-fields';
import { getDefaultValueForType } from './normalize-custom-fields';

type CustomFieldsProps = {
  /**
   * The number of columns in the grid container for custom fields.
   *
   * @default 12
   */
  columns?: number;
  customFields: ParsedCustomFieldConfig[];
  entity: { customFieldList?: CustomField[] };
  onChange?: (props: { id: string; value: number | string | boolean }) => void;
  onBlur?: (props: { id: string; value: number | string | boolean }) => void;
};

export function CustomFieldsWrapper({
  columns,
  customFields,
  entity,
  onChange,
  onBlur,
}: CustomFieldsProps) {
  const { t } = useTranslation();

  const groupedFields = useMemo(() => {
    return groupCustomFields(customFields);
  }, [customFields]);

  const renderGroup = (group: CustomFieldGroup) => {
    const title = group.isDefault
      ? t('INPUTS.ADDITIONAL_INFORMATION')
      : group.name;

    return (
      <Collapse key={`group:${group.id}`} title={title} defaultOpen={true}>
        <Stack direction="column" gap={'1rem'}>
          {group.subgroups.map(renderSubgroup)}
        </Stack>
      </Collapse>
    );
  };

  const renderSubgroup = (subgroup: CustomFieldSubgroup) => {
    return (
      <Box
        key={`subgroup:${subgroup.id}`}
        display="flex"
        flexWrap="wrap"
        gap="1rem"
        // container
        // spacing={2}
        // columns={columns}
      >
        {subgroup.fields.map((field) => {
          return (
            // <Grid key={`field:${field.id}`} item xs={12} md={6} lg={4}>
            <Box
              flexGrow="1"
              minWidth="150px"
              // maxWidth="300px"
            >
              {renderField(field)}
            </Box>
            // </Grid>
          );
        })}
      </Box>
    );
  };

  const renderField = (field: ParsedCustomFieldConfig) => {
    const name = `_customFields.${encodeCustomFieldName(field.id)}` as const;
    const { name: label, options, fieldType } = field;

    if (!entity.customFieldList) return null;
    const property = entity.customFieldList.find((x) => x.key === field.id);

    const CustomFieldComponent = getCustomFieldComponent(fieldType);
    if (!CustomFieldComponent) return null;

    return (
      <ControlWrapper>
        <CustomFieldComponent
          name={name}
          label={label}
          options={options}
          value={
            property?.value
              ? JSON.parse(property.value)
              : getDefaultValueForType(fieldType)
          }
          onChange={(
            event: React.ChangeEvent<HTMLInputElement> | string | boolean
          ) => {
            if (event === undefined) return;

            onChange({
              id: `customFieldList.${field.id.toString()}`,
              value: typeof event === 'object' ? event.target.value : event,
            });
            if (typeof event !== 'object') {
              onBlur({
                id: `customFieldList.${field.id.toString()}`,
                value: event,
              });
            }
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            onBlur({
              id: `customFieldList.${field.id.toString()}`,
              value: event.target.value,
            });
          }}
        />
      </ControlWrapper>
    );
  };

  if (!entity.customFieldList) return null;

  return groupedFields.groups.map(renderGroup) as unknown as JSX.Element;
}

const CUSTOM_FIELDS_MAP: Partial<
  Record<IndividualFieldControlType, React.FC<SimpleCustomFieldProps>>
> = {
  [IndividualFieldControlType.TEXT]: SimpleCustomTextField,
  [IndividualFieldControlType.NUMERIC]: SimpleCustomNumberField,
  [IndividualFieldControlType.DATE_TYPE]: SimpleCustomDateField,
  [IndividualFieldControlType.CHECK]: SimpleCustomCheckboxField,
  [IndividualFieldControlType.COMBO]: SimpleCustomPickerField,
};

function getCustomFieldComponent(fieldType: IndividualFieldControlType) {
  return CUSTOM_FIELDS_MAP[fieldType] ?? null;
}
