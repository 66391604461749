import { useTranslation } from 'react-i18next';

import { Card } from '@work4all/components/lib/dataDisplay/card';
import { DateTimeInputPicker } from '@work4all/components/lib/input/date-time-input-picker';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { NumberInput } from '@work4all/components/lib/input/number-input';

import { CountryPickerField } from '../../../../../../../components/entity-picker/CountryPickerField';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { Collapse, ControlWrapper, Panel } from '../../../../components';
import { UserFormValue } from '../../types';

export const PersonalTabPanel = () => {
  const { t } = useTranslation();
  const { register, control } = useFormContextPlus<UserFormValue>();

  return (
    <Panel style={{ height: '100%' }}>
      <Collapse defaultOpen title={t('USER.GENERAL_PERSONAL_DATA')}>
        <Card>
          <ControlWrapper columns={3}>
            <LabeledInput
              {...register('number', { valueAsNumber: true })}
              type="number"
              label={t('INPUTS.PERSONAL_NUMBER')}
            />
            <LabeledInput
              {...register('nationality')}
              label={t('INPUTS.NATIONALITY')}
            />

            <ControlWrapper gap={'0'} columns={2} paddingBottom={false}>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.DATE_OF_BIRTH')}
                withTime={false}
                clearable={true}
                dateInputProps={{
                  multientry: 'left',
                }}
                {...register('birthDate')}
              />

              <LabeledInput
                {...register('placeOfBirth')}
                label={t('INPUTS.PLACE_OF_BIRTH')}
                multientry="right"
              />
            </ControlWrapper>
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <LabeledInput
              {...register('socialSecurityNumber')}
              label={t('INPUTS.SOCIAL_SECURITY_NUMBER')}
            />
            <LabeledInput
              {...register('healthInsuranceCompany')}
              label={t('INPUTS.HEALTH_INSURANCE_COMPANY')}
            />
            <ControlWrapper gap={'0'} columns={2} paddingBottom={false}>
              <DateTimeInputPicker
                dateLabel={t('INPUTS.ENTRY_DATE')}
                withTime={false}
                clearable={true}
                dateInputProps={{
                  multientry: 'left',
                }}
                {...register('enterDate')}
              />
              <DateTimeInputPicker
                dateLabel={t('INPUTS.EXIT_DATE')}
                withTime={false}
                clearable={true}
                dateInputProps={{
                  multientry: 'right',
                }}
                {...register('exitDate')}
              />
            </ControlWrapper>
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.BASNK_DETAILS')}>
        <Card>
          <ControlWrapper columns={3}>
            <LabeledInput {...register('bankData')} label={t('INPUTS.BANK')} />
            <LabeledInput {...register('iban')} label={t('INPUTS.IBAN')} />
            <LabeledInput {...register('bic')} label={t('INPUTS.BIC')} />
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.WAGE_RATES')}>
        <Card>
          <ControlWrapper columns={3}>
            <ControllerPlus
              name="wageRate1"
              control={control}
              render={({ field }) => {
                return (
                  <NumberInput {...field} label={t('INPUTS.WAGE_RATE_1')} />
                );
              }}
            />
            <ControllerPlus
              name="wageRate2"
              control={control}
              render={({ field }) => {
                return (
                  <NumberInput {...field} label={t('INPUTS.WAGE_RATE_2')} />
                );
              }}
            />
            <ControllerPlus
              name="wageRate3"
              control={control}
              render={({ field }) => {
                return (
                  <NumberInput {...field} label={t('INPUTS.WAGE_RATE_3')} />
                );
              }}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.PRIVATE_COMMUNICATION')}>
        <Card>
          <ControlWrapper columns={3}>
            <LabeledInput {...register('street')} label={t('COMMON.STREET')} />
            <ControlWrapper columns={2} paddingBottom={false}>
              <LabeledInput
                {...register('postalCode')}
                label={t('INPUTS.POSTAL_CODE')}
              />
              <LabeledInput
                {...register('location')}
                label={t('INPUTS.LOCATION')}
              />
            </ControlWrapper>

            <ControllerPlus
              name="state"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CountryPickerField
                    {...field}
                    error={fieldState.error?.message}
                    value={field.value ? { shortName: field.value } : null}
                    onChange={(result) => {
                      field.onChange(result?.shortName || '');
                    }}
                  />
                );
              }}
            />

            <LabeledInput {...register('state')} label={t('INPUTS.COUNTRY')} />
          </ControlWrapper>
          <ControlWrapper columns={4}>
            <LabeledInput
              {...register('eMailPrivate')}
              label={t('INPUTS.EMAIL')}
            />
            <LabeledInput
              {...register('telefonPrivate')}
              label={t('INPUTS.PHONE')}
            />
            <LabeledInput
              {...register('mobilPrivate')}
              label={t('INPUTS.MOBILE')}
            />
            <LabeledInput
              {...register('telefaxPrivate')}
              label={t('INPUTS.FAX')}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
    </Panel>
  );
};
