import { EntitiyDefinitionMap } from '../DataProvider';
import { Entities } from '../Enums/Entities.enum';

import { absenceOverviewEntityDefinition } from './AbsenceOverview.entity';
import { addressConnectionEntityDefinition } from './AddressConnection.entity';
import { appearanceEntityDefinition } from './Appearance.entity';
import { appearanceBackgroundImageEntityDefinition } from './AppearanceBackgroundImage.entity';
import { appearanceSettingsInputEntityDefinition } from './AppearanceSettingsInput.entity';
import { appearanceSettingsInputAddBackgroundImageEntityDefinition } from './AppearanceSettingsInputAddBackgroundImage.entity';
import { appointmentEntityDefinition } from './Appointment.entity';
import { appointmentAttachmentEntityDefinition } from './AppointmentAttachment.entity';
import { appointmentAttendeeEntityDefinition } from './AppointmentAttendee.entity';
import { appointmentStateEntityDefinition } from './AppointmentState.entity';
import { archivePdfEntityDefinition } from './ArchivePdf.entity';
import { articleEntityDefinition } from './Article.entity';
import { articleCategoryEntityDefinition } from './ArticleCategory.entity';
import { articleCategoryMarkEntityDefinition } from './ArticleCategoryMark.entity';
import { articleGroupEntityDefinition } from './ArticleGroup.entity';
import { articleImageEntityDefinition } from './ArticleImage.entity';
import { articleImageUnionEntityDefinition } from './ArticleImageUnion.entity';
import { articleInventoryInfoEntityDefinition } from './ArticleInventoryInfo.entity';
import { articleLedgerAccountEntityDefinition } from './ArticleLedgerAccount.entity';
import { articlePricesEntityDefinition } from './ArticlePrices.entity';
import { articlePurchaseInformationEntityDefinition } from './ArticlePurchaseInformation.entity';
import { articleTextEntityDefinition } from './ArticleText.entity';
import { bankDetailsEntityDefinition } from './BankDetails.entity';
import { baseDataAttachmentEntityDefinition } from './BaseDataAttachment.entity';
import { baseDataLanguageEntityDefinition } from './BaseDataLanguage.entity';
import { bOMComponentEntityDefinition } from './BOMComponent.entity';
import { bulkPriceEntityDefinition } from './BulkPrice.entity';
import { bulkPriceTierEntityDefinition } from './BulkPriceTier.entity';
import { bulkPriceTierItemEntityDefinition } from './BulkPriceTierItem.entity';
import { businessPartnerEntityDefinition } from './BusinessPartner.entity';
import { businessPartnerContactCombinedEntityDefinition } from './BusinessPartnerContactCombined.entity';
import { businessPartnerUnionEntityDefinition } from './BusinessPartnerUnion.entity';
import { bzObjectEntityDefinition } from './BzObject.entity';
import { bzObjectConversionResultEntityDefinition } from './BzObjectConversionResult.entity';
import { bzObjectMutationRequestEntityDefinition } from './BzObjectMutationRequest.entity';
import { bzObjectMutationResponseEntityDefinition } from './BzObjectMutationResponse.entity';
import { bzObjectRelationEntityDefinition } from './BzObjectRelation.entity';
import { bzObjectReleaseInformationEntityDefinition } from './BzObjectReleaseInformation.entity';
import { calculationEntityDefinition } from './Calculation.entity';
import { callMemoEntityDefinition } from './CallMemo.entity';
import { callMemoAttachmentEntityDefinition } from './CallMemoAttachment.entity';
import { categoryEntityDefinition } from './Category.entity';
import { categoryAssignmentEntityDefinition } from './CategoryAssignment.entity';
import { categoryClassEntityDefinition } from './CategoryClass.entity';
import { changeEventEntityDefinition } from './ChangeEvent.entity';
import { changeEventChangeInfoEntityDefinition } from './ChangeEventChangeInfo.entity';
import { checkListEntityDefinition } from './CheckList.entity';
import { checkListPositionEntityDefinition } from './CheckListPosition.entity';
import { childItemCreatedEntityDefinition } from './ChildItemCreated.entity';
import { childItemCreatedObjectEntityDefinition } from './ChildItemCreatedObject.entity';
import { chronoFileItemEntityDefinition } from './ChronoFileItem.entity';
import { chronoFileItemUnionEntityDefinition } from './ChronoFileItemUnion.entity';
import { commentEventEntityDefinition } from './CommentEvent.entity';
import { companyKpisEntityDefinition } from './CompanyKpis.entity';
import { contactEntityDefinition } from './Contact.entity';
import { contactTelephonenumbersEntityDefinition } from './ContactTelephonenumbers.entity';
import { contactUnionEntityDefinition } from './ContactUnion.entity';
import { contactUnionWrapperEntityDefinition } from './ContactUnionWrapper.entity';
import { contractEntityDefinition } from './Contract.entity';
import { convertTempFileResultEntityDefinition } from './ConvertTempFileResult.entity';
import { copyPositionEntityDefinition } from './CopyPosition.entity';
import { costCenterEntityDefinition } from './CostCenter.entity';
import { countryEntityDefinition } from './Country.entity';
import { createCrystalReportRequestEntityDefinition } from './CreateCrystalReportRequest.entity';
import { createEventEntityDefinition } from './CreateEvent.entity';
import { createReportResultEntityDefinition } from './CreateReportResult.entity';
import { crystalReportFormularEntityDefinition } from './CrystalReportFormular.entity';
import { currencyEntityDefinition } from './Currency.entity';
import { customerEntityDefinition } from './Customer.entity';
import { customerGroupEntityDefinition } from './CustomerGroup.entity';
import { customerIndividualPriceEntityDefinition } from './CustomerIndividualPrice.entity';
import { customFieldEntityDefinition } from './CustomField.entity';
import { customFieldDefinitionEntityDefinition } from './CustomFieldDefinition.entity';
import { defaultErpTextEntityDefinition } from './DefaultErpText.entity';
import { deleteEntityResultEntityDefinition } from './DeleteEntityResult.entity';
import { deleteErrorMessageEntityDefinition } from './DeleteErrorMessage.entity';
import { deliveryKindEntityDefinition } from './DeliveryKind.entity';
import { deliveryNoteEntityDefinition } from './DeliveryNote.entity';
import { demandEntityDefinition } from './Demand.entity';
import { departmentEntityDefinition } from './Department.entity';
import { dhlShipmentEntityDefinition } from './DhlShipment.entity';
import { documentEntityDefinition } from './Document.entity';
import { documentClassEntityDefinition } from './DocumentClass.entity';
import { documentTemplateGroupEntityDefinition } from './DocumentTemplateGroup.entity';
import { duplicatedObjectUnionEntityDefinition } from './DuplicatedObjectUnion.entity';
import { duplicateRequestEntityDefinition } from './DuplicateRequest.entity';
import { duplicateResponseEntityDefinition } from './DuplicateResponse.entity';
import { eMailEntityDefinition } from './EMail.entity';
import { eMailAttachmentEntityDefinition } from './EMailAttachment.entity';
import { eMailSignatureEntityDefinition } from './EMailSignature.entity';
import { eMailTemplateEntityDefinition } from './EMailTemplate.entity';
import { eMailTemplateAttachmentEntityDefinition } from './EMailTemplateAttachment.entity';
import { eMailTemplateGroupEntityDefinition } from './EMailTemplateGroup.entity';
import { entityAccessRightsEntityDefinition } from './EntityAccessRights.entity';
import { entityAccessRightsStateEntityDefinition } from './EntityAccessRightsState.entity';
import { entityChangedInfoEntityDefinition } from './EntityChangedInfo.entity';
import { entitySchemaEntityDefinition } from './EntitySchema.entity';
import { erpAttachmentEntityDefinition } from './ErpAttachment.entity';
import { erpObjectConversionPositionDetailsEntityDefinition } from './ErpObjectConversionPositionDetails.entity';
import { erpObjectConversionRequestEntityDefinition } from './ErpObjectConversionRequest.entity';
import { erpObjectConversionRequestOptionsEntityDefinition } from './ErpObjectConversionRequestOptions.entity';
import { eventEntityDefinition } from './Event.entity';
import { exchangeRateEntityDefinition } from './ExchangeRate.entity';
import { fileEntityDefinition } from './File.entity';
import { fileEntityEntityDefinition } from './FileEntity.entity';
import { fileLinkEntityDefinition } from './FileLink.entity';
import { fileMetaInfoEntityDefinition } from './FileMetaInfo.entity';
import { fileServiceProviderConfigEntityDefinition } from './FileServiceProviderConfig.entity';
import { fullUserEntityDefinition } from './FullUser.entity';
import { generalSettingsEntityDefinition } from './GeneralSettings.entity';
import { groupEntityDefinition } from './Group.entity';
import { groupFieldDefinitionEntityDefinition } from './GroupFieldDefinition.entity';
import { groupQueryEntityDefinition } from './GroupQuery.entity';
import { groupQueryResultEntityDefinition } from './GroupQueryResult.entity';
import { groupQueryResultItemEntityDefinition } from './GroupQueryResultItem.entity';
import { groupQueryResultRowEntityDefinition } from './GroupQueryResultRow.entity';
import { groupQuerySortInfoEntityDefinition } from './GroupQuerySortInfo.entity';
import { holidayEntityDefinition } from './Holiday.entity';
import { inboundDeliveryNoteEntityDefinition } from './InboundDeliveryNote.entity';
import { inboundInvoiceEntityDefinition } from './InboundInvoice.entity';
import { inboundInvoicePaymentEntityDefinition } from './InboundInvoicePayment.entity';
import { inboundPositionEntityDefinition } from './InboundPosition.entity';
import { incomingMailBlockedSenderEntityDefinition } from './IncomingMailBlockedSender.entity';
import { inputAddressConnectionRelationEntityDefinition } from './InputAddressConnectionRelation.entity';
import { inputAddressConnectionRelationAddEntityDefinition } from './InputAddressConnectionRelationAdd.entity';
import { inputAdressVerknuepfungEntityDefinition } from './InputAdressVerknuepfung.entity';
import { inputAngebotEntityDefinition } from './InputAngebot.entity';
import { inputAnsprechpartnerEntityDefinition } from './InputAnsprechpartner.entity';
import { inputAnsprechpartnerRelationEntityDefinition } from './InputAnsprechpartnerRelation.entity';
import { inputArticleAttachmentEntityDefinition } from './InputArticleAttachment.entity';
import { inputArticleAttachmentAddEntityDefinition } from './InputArticleAttachmentAdd.entity';
import { inputArticleAttachmentModifyEntityDefinition } from './InputArticleAttachmentModify.entity';
import { inputArticleBomComponentsEntityDefinition } from './InputArticleBomComponents.entity';
import { inputArticleLedgerAccountsAssignmentsRelationEntityDefinition } from './InputArticleLedgerAccountsAssignmentsRelation.entity';
import { inputArticlePricesEntityDefinition } from './InputArticlePrices.entity';
import { inputArticlePricesSetSimplePriceEntityDefinition } from './InputArticlePricesSetSimplePrice.entity';
import { inputArticleRelationEntityDefinition } from './InputArticleRelation.entity';
import { inputArticleSetLedgerAccountsAssignmentRelationEntityDefinition } from './InputArticleSetLedgerAccountsAssignmentRelation.entity';
import { inputArticleTextAddRelationEntityDefinition } from './InputArticleTextAddRelation.entity';
import { inputArticleTextModifyRelationEntityDefinition } from './InputArticleTextModifyRelation.entity';
import { inputArticleTextRelationEntityDefinition } from './InputArticleTextRelation.entity';
import { inputArticleUpsertBomComponentEntityDefinition } from './InputArticleUpsertBomComponent.entity';
import { inputArtikelEntityDefinition } from './InputArtikel.entity';
import { inputAttachementAddEntityDefinition } from './InputAttachementAdd.entity';
import { inputAufgabeEntityDefinition } from './InputAufgabe.entity';
import { inputAufgabeAnhangEntityDefinition } from './InputAufgabeAnhang.entity';
import { inputAufgabeRelationEntityDefinition } from './InputAufgabeRelation.entity';
import { inputAuftragEntityDefinition } from './InputAuftrag.entity';
import { inputBankAccountRelationEntityDefinition } from './InputBankAccountRelation.entity';
import { inputbankAccountRelationAddModifyEntityDefinition } from './InputbankAccountRelationAddModify.entity';
import { inputBedarfsanforderungEntityDefinition } from './InputBedarfsanforderung.entity';
import { inputBenutzerEntityDefinition } from './InputBenutzer.entity';
import { inputBenutzerRelationEntityDefinition } from './InputBenutzerRelation.entity';
import { inputBestellungEntityDefinition } from './InputBestellung.entity';
import { inputBesuchsberichtEntityDefinition } from './InputBesuchsbericht.entity';
import { inputBriefEntityDefinition } from './InputBrief.entity';
import { inputBriefRelationEntityDefinition } from './InputBriefRelation.entity';
import { inputCategoryMarkRelationEntityDefinition } from './InputCategoryMarkRelation.entity';
import { inputCategoryMarkRelationAddEntityDefinition } from './InputCategoryMarkRelationAdd.entity';
import { inputCategoryMarkRelationModifyEntityDefinition } from './InputCategoryMarkRelationModify.entity';
import { inputChecklisteEntityDefinition } from './InputCheckliste.entity';
import { inputChecklistePositionEntityDefinition } from './InputChecklistePosition.entity';
import { inputChecklisteRelationEntityDefinition } from './InputChecklisteRelation.entity';
import { inputChecklistPositionRelationEntityDefinition } from './InputChecklistPositionRelation.entity';
import { inputCompanyDefaultErpTextEntityDefinition } from './InputCompanyDefaultErpText.entity';
import { inputCrmAnhangAttachementAddEntityDefinition } from './InputCrmAnhangAttachementAdd.entity';
import { inputCrmAnhangAttachementModifyEntityDefinition } from './InputCrmAnhangAttachementModify.entity';
import { inputCrmAnhangAttachementsRelationEntityDefinition } from './InputCrmAnhangAttachementsRelation.entity';
import { inputCustomFieldEntityDefinition } from './InputCustomField.entity';
import { inputDefaultErpTextAddModifyEntityDefinition } from './InputDefaultErpTextAddModify.entity';
import { inputDokumentEntityDefinition } from './InputDokument.entity';
import { inputDokumentRelationEntityDefinition } from './InputDokumentRelation.entity';
import { inputEingangslieferscheinEntityDefinition } from './InputEingangslieferschein.entity';
import { inputEingangsrechnungEntityDefinition } from './InputEingangsrechnung.entity';
import { inputEingangsrechnungRelationEntityDefinition } from './InputEingangsrechnungRelation.entity';
import { inputEMailEntityDefinition } from './InputEMail.entity';
import { inputEMailAnhangAttachementAddEntityDefinition } from './InputEMailAnhangAttachementAdd.entity';
import { inputEMailAnhangAttachementsRelationEntityDefinition } from './InputEMailAnhangAttachementsRelation.entity';
import { inputEMailRelationEntityDefinition } from './InputEMailRelation.entity';
import { inputEMailSignaturEntityDefinition } from './InputEMailSignatur.entity';
import { inputEMailTemplateGroupEntityDefinition } from './InputEMailTemplateGroup.entity';
import { inputEMailVorlageEntityDefinition } from './InputEMailVorlage.entity';
import { inputEMailVorlagenAnhangAddEntityDefinition } from './InputEMailVorlagenAnhangAdd.entity';
import { inputEMailVorlagenAnhangRelationEntityDefinition } from './InputEMailVorlagenAnhangRelation.entity';
import { inputEMailVorlagenRelationEntityDefinition } from './InputEMailVorlagenRelation.entity';
import { inputErpAnhangAttachementModifyEntityDefinition } from './InputErpAnhangAttachementModify.entity';
import { inputErpAnhangAttachementsRelationEntityDefinition } from './InputErpAnhangAttachementsRelation.entity';
import { inputFullUserEntityDefinition } from './InputFullUser.entity';
import { inputGroupEntityDefinition } from './InputGroup.entity';
import { inputIncomingMailBlockedSenderEntityDefinition } from './InputIncomingMailBlockedSender.entity';
import { inputKalkulationEntityDefinition } from './InputKalkulation.entity';
import { inputKommtGehtZeitstempelEntityDefinition } from './InputKommtGehtZeitstempel.entity';
import { inputKrankheitEntityDefinition } from './InputKrankheit.entity';
import { inputKundeEntityDefinition } from './InputKunde.entity';
import { inputKundeRelationEntityDefinition } from './InputKundeRelation.entity';
import { inputLayoutEntityDefinition } from './InputLayout.entity';
import { inputLetterDocumentFileRelationEntityDefinition } from './InputLetterDocumentFileRelation.entity';
import { inputLieferantEntityDefinition } from './InputLieferant.entity';
import { inputLieferantRelationEntityDefinition } from './InputLieferantRelation.entity';
import { inputLieferscheinEntityDefinition } from './InputLieferschein.entity';
import { inputModifyAddSupplierDemandAssignEntityDefinition } from './InputModifyAddSupplierDemandAssign.entity';
import { inputModifySupplierDemandAssignEntityDefinition } from './InputModifySupplierDemandAssign.entity';
import { inputNotificationKeyEntityDefinition } from './InputNotificationKey.entity';
import { inputNotizEntityDefinition } from './InputNotiz.entity';
import { inputNotizAnhangEntityDefinition } from './InputNotizAnhang.entity';
import { inputNotizRelationEntityDefinition } from './InputNotizRelation.entity';
import { inputObjectGroupRelationEntityDefinition } from './InputObjectGroupRelation.entity';
import { inputPositionEntityDefinition } from './InputPosition.entity';
import { inputProjectAccessGroupEntityDefinition } from './InputProjectAccessGroup.entity';
import { inputProjectAccessGroupGroupRelationEntityDefinition } from './InputProjectAccessGroupGroupRelation.entity';
import { inputProjectAccessGroupProjectRelationEntityDefinition } from './InputProjectAccessGroupProjectRelation.entity';
import { inputProjectAccessGroupRelationEntityDefinition } from './InputProjectAccessGroupRelation.entity';
import { inputProjectAccessGroupUserRelationEntityDefinition } from './InputProjectAccessGroupUserRelation.entity';
import { inputProjectCategoryRelationEntityDefinition } from './InputProjectCategoryRelation.entity';
import { inputProjectDirectoryDefinitionEntityDefinition } from './InputProjectDirectoryDefinition.entity';
import { inputProjectStepLinkEntityDefinition } from './InputProjectStepLink.entity';
import { inputProjectStepLinkRelationEntityDefinition } from './InputProjectStepLinkRelation.entity';
import { inputProjectStepRelationEntityDefinition } from './InputProjectStepRelation.entity';
import { inputProjektEntityDefinition } from './InputProjekt.entity';
import { inputProjektRelationEntityDefinition } from './InputProjektRelation.entity';
import { inputProjektVorgangEntityDefinition } from './InputProjektVorgang.entity';
import { inputPromptResultEntityDefinition } from './InputPromptResult.entity';
import { inputRaEntityDefinition } from './InputRa.entity';
import { inputRaPaymentRelationEntityDefinition } from './InputRaPaymentRelation.entity';
import { inputRaRelationEntityDefinition } from './InputRaRelation.entity';
import { inputRaZahlungEntityDefinition } from './InputRaZahlung.entity';
import { inputRechnungEntityDefinition } from './InputRechnung.entity';
import { inputReisekostenabrechnungBelegEntityDefinition } from './InputReisekostenabrechnungBeleg.entity';
import { inputReisekostenabrechnungBelegRelationEntityDefinition } from './InputReisekostenabrechnungBelegRelation.entity';
import { inputRePaymentRelationEntityDefinition } from './InputRePaymentRelation.entity';
import { inputReport2EntityDefinition } from './InputReport2.entity';
import { inputReRelationEntityDefinition } from './InputReRelation.entity';
import { inputRESachkontenSplitEntityDefinition } from './InputRESachkontenSplit.entity';
import { inputReZahlungEntityDefinition } from './InputReZahlung.entity';
import { inputSalesOpportunityAttachementModifyEntityDefinition } from './InputSalesOpportunityAttachementModify.entity';
import { inputSalesOpportunityAttachementRelationEntityDefinition } from './InputSalesOpportunityAttachementRelation.entity';
import { inputSalesOpportunityRatingRelationEntityDefinition } from './InputSalesOpportunityRatingRelation.entity';
import { inputSalesOpportunityRatingSetEntityDefinition } from './InputSalesOpportunityRatingSet.entity';
import { inputSalesOpportunityRatingStatusEntityDefinition } from './InputSalesOpportunityRatingStatus.entity';
import { inputSalesOpportunityRatingTemplateEntityDefinition } from './InputSalesOpportunityRatingTemplate.entity';
import { inputSavedListFilterEntityDefinition } from './InputSavedListFilter.entity';
import { inputSetMfaModeEntityDefinition } from './InputSetMfaMode.entity';
import { inputSetProjectDirectoryEntityDefinition } from './InputSetProjectDirectory.entity';
import { inputSettingEntityDefinition } from './InputSetting.entity';
import { inputStammdatenAttachementModifyEntityDefinition } from './InputStammdatenAttachementModify.entity';
import { inputStammdatenAttachementsRelationEntityDefinition } from './InputStammdatenAttachementsRelation.entity';
import { inputStammdatenPostItEntityDefinition } from './InputStammdatenPostIt.entity';
import { inputStammdatenPostItDetailsEntityDefinition } from './InputStammdatenPostItDetails.entity';
import { inputTelefonatEntityDefinition } from './InputTelefonat.entity';
import { inputTelefonatAnhangEntityDefinition } from './InputTelefonatAnhang.entity';
import { inputTelefonatRelationEntityDefinition } from './InputTelefonatRelation.entity';
import { inputTemplateFileRelationEntityDefinition } from './InputTemplateFileRelation.entity';
import { inputTerminEntityDefinition } from './InputTermin.entity';
import { inputTerminAnhangEntityDefinition } from './InputTerminAnhang.entity';
import { inputTerminRelationEntityDefinition } from './InputTerminRelation.entity';
import { inputTerminTeilnehmerAddEntityDefinition } from './InputTerminTeilnehmerAdd.entity';
import { inputTerminTeilnehmerRelationEntityDefinition } from './InputTerminTeilnehmerRelation.entity';
import { inputTextbausteinEntityDefinition } from './InputTextbaustein.entity';
import { inputTicketEntityDefinition } from './InputTicket.entity';
import { inputTicketAttachementModifyEntityDefinition } from './InputTicketAttachementModify.entity';
import { inputTicketAttachementsRelationEntityDefinition } from './InputTicketAttachementsRelation.entity';
import { inputTicketChecklisteMarkRelationEntityDefinition } from './InputTicketChecklisteMarkRelation.entity';
import { inputTicketFilterEntityDefinition } from './InputTicketFilter.entity';
import { inputTicketRelationEntityDefinition } from './InputTicketRelation.entity';
import { inputTopicMarkRelationEntityDefinition } from './InputTopicMarkRelation.entity';
import { inputUrlaubEntityDefinition } from './InputUrlaub.entity';
import { inputUserCollectionEntityDefinition } from './InputUserCollection.entity';
import { inputUserCollectionRelationEntityDefinition } from './InputUserCollectionRelation.entity';
import { inputUserRelationEntityDefinition } from './InputUserRelation.entity';
import { inputVacationRequestEntityDefinition } from './InputVacationRequest.entity';
import { inputValidateSetMfaModeEntityDefinition } from './InputValidateSetMfaMode.entity';
import { inputVerkaufschanceEntityDefinition } from './InputVerkaufschance.entity';
import { inputVerkaufschanceRelationEntityDefinition } from './InputVerkaufschanceRelation.entity';
import { inputWordDocumentTemplateEntityDefinition } from './InputWordDocumentTemplate.entity';
import { inputWordLetterTemplateEntityDefinition } from './InputWordLetterTemplate.entity';
import { inputZeiterfassungEntityDefinition } from './InputZeiterfassung.entity';
import { invoiceEntityDefinition } from './Invoice.entity';
import { invoiceToOutgoingInvoiceLedgerRequestEntityDefinition } from './InvoiceToOutgoingInvoiceLedgerRequest.entity';
import { kpisCustomerEntityDefinition } from './KpisCustomer.entity';
import { kpisCustomerSalesValueYearEntityDefinition } from './KpisCustomerSalesValueYear.entity';
import { kpisSupplierEntityDefinition } from './KpisSupplier.entity';
import { kpisSupplierSalesValueYearEntityDefinition } from './KpisSupplierSalesValueYear.entity';
import { layoutEntityDefinition } from './Layout.entity';
import { layoutDataEntityDefinition } from './LayoutData.entity';
import { ledgerAccountEntityDefinition } from './LedgerAccount.entity';
import { legalNoticeParseResultEntityDefinition } from './LegalNoticeParseResult.entity';
import { letterEntityDefinition } from './Letter.entity';
import { letterTemplateGroupEntityDefinition } from './LetterTemplateGroup.entity';
import { licenseInfoEntityDefinition } from './LicenseInfo.entity';
import { licenseModulInfoEntityDefinition } from './LicenseModulInfo.entity';
import { lookUpEntityDefinition } from './LookUp.entity';
import { mailboxEntityDefinition } from './Mailbox.entity';
import { mailboxConfigurationEntityDefinition } from './MailboxConfiguration.entity';
import { mailboxContentEntityDefinition } from './MailboxContent.entity';
import { mailboxFolderEntityDefinition } from './MailboxFolder.entity';
import { mailSearchContactsResultItemEntityDefinition } from './MailSearchContactsResultItem.entity';
import { managementDashboardEntityDefinition } from './ManagementDashboard.entity';
import { mentionEntityDefinition } from './Mention.entity';
import { mentionUnionEntityDefinition } from './MentionUnion.entity';
import { modifyShadowBzObjectResultEntityDefinition } from './ModifyShadowBzObjectResult.entity';
import { modifyShadowREResultEntityDefinition } from './ModifyShadowREResult.entity';
import { moduleAccessRightEntityDefinition } from './ModuleAccessRight.entity';
import { mutateArticleGroupsEntityDefinition } from './MutateArticleGroups.entity';
import { mutateCustomerGroupsEntityDefinition } from './MutateCustomerGroups.entity';
import { mutateProjectGroupsEntityDefinition } from './MutateProjectGroups.entity';
import { mutateSupplierGroupsEntityDefinition } from './MutateSupplierGroups.entity';
import { mutationEntityDefinition } from './Mutation.entity';
import { noteEntityDefinition } from './Note.entity';
import { noteAttachmentEntityDefinition } from './NoteAttachment.entity';
import { notificationEntityDefinition } from './Notification.entity';
import { notificationUnionEntityDefinition } from './NotificationUnion.entity';
import { objectLockEntityDefinition } from './ObjectLock.entity';
import { objectLockResultEntityDefinition } from './ObjectLockResult.entity';
import { ocrInvoiceDataEntityDefinition } from './OcrInvoiceData.entity';
import { ocrInvoiceSupplierGuessingEntityDefinition } from './OcrInvoiceSupplierGuessing.entity';
import { offerEntityDefinition } from './Offer.entity';
import { orderEntityDefinition } from './Order.entity';
import { partialCalculationLogicDetailsEntityDefinition } from './PartialCalculationLogicDetails.entity';
import { partialInvoiceLogicEntityDefinition } from './PartialInvoiceLogic.entity';
import { pauseRuleEntityDefinition } from './PauseRule.entity';
import { paymentKindEntityDefinition } from './PaymentKind.entity';
import { positionEntityDefinition } from './Position.entity';
import { postItEntityDefinition } from './PostIt.entity';
import { priceGroupEntityDefinition } from './PriceGroup.entity';
import { processedEMailTemplateEntityDefinition } from './ProcessedEMailTemplate.entity';
import { processedEMailTemplateAttachmentEntityDefinition } from './ProcessedEMailTemplateAttachment.entity';
import { processWordTemplateResultEntityDefinition } from './ProcessWordTemplateResult.entity';
import { productionContractEntityDefinition } from './ProductionContract.entity';
import { productionContractGroupEntityDefinition } from './ProductionContractGroup.entity';
import { projectEntityDefinition } from './Project.entity';
import { projectAccessGroupEntityDefinition } from './ProjectAccessGroup.entity';
import { projectAccessRightsEntityDefinition } from './ProjectAccessRights.entity';
import { projectCategoryEntityDefinition } from './ProjectCategory.entity';
import { projectCategoryClassEntityDefinition } from './ProjectCategoryClass.entity';
import { projectDirectoryDefinitionEntityDefinition } from './ProjectDirectoryDefinition.entity';
import { projectDistributorEntityDefinition } from './ProjectDistributor.entity';
import { projectDistributorEntryEntityDefinition } from './ProjectDistributorEntry.entity';
import { projectGroupEntityDefinition } from './ProjectGroup.entity';
import { projectInvolvedsEntityDefinition } from './ProjectInvolveds.entity';
import { projectPlanningFeedbackEntityDefinition } from './ProjectPlanningFeedback.entity';
import { projectProcessEntityDefinition } from './ProjectProcess.entity';
import { projectProcessCompletionFeedbackEntityDefinition } from './ProjectProcessCompletionFeedback.entity';
import { projectProcessLinkEntityDefinition } from './ProjectProcessLink.entity';
import { purchasePriceEntityDefinition } from './PurchasePrice.entity';
import { queryEntityDefinition } from './Query.entity';
import { raEntityDefinition } from './Ra.entity';
import { raLedgerAccountSplitEntityDefinition } from './RaLedgerAccountSplit.entity';
import { raPaymentEntityDefinition } from './RaPayment.entity';
import { raViewModelEntityDefinition } from './RaViewModel.entity';
import { rELedgerAccountSplitEntityDefinition } from './RELedgerAccountSplit.entity';
import { reportEntityDefinition } from './Report.entity';
import { report2EntityDefinition } from './Report2.entity';
import { reportOptionEntityDefinition } from './ReportOption.entity';
import { reportOptionValueEntityDefinition } from './ReportOptionValue.entity';
import { resourceUtilizationInfoEntityDefinition } from './ResourceUtilizationInfo.entity';
import { reViewModelEntityDefinition } from './ReViewModel.entity';
import { salesOpportunitiesEntityDefinition } from './SalesOpportunities.entity';
import { salesOpportunityAttachementEntityDefinition } from './SalesOpportunityAttachement.entity';
import { salesOpportunityGroupEntityDefinition } from './SalesOpportunityGroup.entity';
import { salesOpportunityRatingEntityDefinition } from './SalesOpportunityRating.entity';
import { salesOpportunityRatingStatusEntityDefinition } from './SalesOpportunityRatingStatus.entity';
import { salutationEntityDefinition } from './Salutation.entity';
import { savedListFilterEntityDefinition } from './SavedListFilter.entity';
import { saveSendMailJobEntityDefinition } from './SaveSendMailJob.entity';
import { searchResultItemEntityDefinition } from './SearchResultItem.entity';
import { sendEMailResultEntityDefinition } from './SendEMailResult.entity';
import { serviceContractEntityDefinition } from './ServiceContract.entity';
import { serviceContractPositionEntityDefinition } from './ServiceContractPosition.entity';
import { setMfaModeResponseEntityDefinition } from './SetMfaModeResponse.entity';
import { settingEntityDefinition } from './Setting.entity';
import { setUserPresenceResultEntityDefinition } from './SetUserPresenceResult.entity';
import { shadowBzObjectEntityDefinition } from './ShadowBzObject.entity';
import { shadowReEntityDefinition } from './ShadowRe.entity';
import { sicknessEntityDefinition } from './Sickness.entity';
import { singlePriceEntityDefinition } from './SinglePrice.entity';
import { slModeSettingEntityDefinition } from './SlModeSetting.entity';
import { sprintEntityDefinition } from './Sprint.entity';
import { statisticCompanyReportEntityDefinition } from './StatisticCompanyReport.entity';
import { statisticCompanyReportColumnEntityDefinition } from './StatisticCompanyReportColumn.entity';
import { statisticCompanyReportFiguresEntityDefinition } from './StatisticCompanyReportFigures.entity';
import { statisticCompanyReportKeyFiguresEntityDefinition } from './StatisticCompanyReportKeyFigures.entity';
import {
  statisticCustomerDatasheetEntityDefinition,
  statisticSupplierDatasheetEntityDefinition,
} from './StatisticCustomerDatasheet.entity';
import { statisticCustomerDatasheetColumnEntityDefinition } from './StatisticCustomerDatasheetColumn.entity';
import { statisticCustomerDatasheetStatisticEntityDefinition } from './StatisticCustomerDatasheetStatistic.entity';
import { statisticSalesVolumeBySupplierEntityDefinition } from './StatisticSalesVolumeBySupplier.entity';
import { statisticSalesVolumeYearEntityDefinition } from './StatisticSalesVolumeYear.entity';
import { statisticSalesVolumeYearItemEntityDefinition } from './StatisticSalesVolumeYearItem.entity';
import { statisticSalesVoumeByCostCenterEntityDefinition } from './StatisticSalesVoumeByCostCenter.entity';
import { statisticSalesVoumeByCustomerEntityDefinition } from './StatisticSalesVoumeByCustomer.entity';
import { statisticSalesVoumeByItemEntityDefinition } from './StatisticSalesVoumeByItem.entity';
import { statisticsSalesVolumeByCostCenterColumnEntityDefinition } from './StatisticsSalesVolumeByCostCenterColumn.entity';
import { statisticsSalesVolumeByCostCenterRowEntityDefinition } from './StatisticsSalesVolumeByCostCenterRow.entity';
import { statisticsSalesVolumeByCustomerColumnEntityDefinition } from './StatisticsSalesVolumeByCustomerColumn.entity';
import { statisticsSalesVolumeByCustomerRowEntityDefinition } from './StatisticsSalesVolumeByCustomerRow.entity';
import { statisticsSalesVolumeByItemColumnEntityDefinition } from './StatisticsSalesVolumeByItemColumn.entity';
import { statisticsSalesVolumeByItemRowEntityDefinition } from './StatisticsSalesVolumeByItemRow.entity';
import { statisticsSalesVolumeBySupplierColumnEntityDefinition } from './StatisticsSalesVolumeBySupplierColumn.entity';
import { statisticsSalesVolumeBySupplierRowEntityDefinition } from './StatisticsSalesVolumeBySupplierRow.entity';
import { storageLocationEntityDefinition } from './StorageLocation.entity';
import { stringKeyValueEntityDefinition } from './StringKeyValue.entity';
import { subDirectoryTemplateItemEntityDefinition } from './SubDirectoryTemplateItem.entity';
import { subObjectLockResultEntityDefinition } from './SubObjectLockResult.entity';
import { sumValuesEntityDefinition } from './SumValues.entity';
import { supplierEntityDefinition } from './Supplier.entity';
import { supplierDemandAddignEntityDefinition } from './SupplierDemandAddign.entity';
import { supplierGroupEntityDefinition } from './SupplierGroup.entity';
import { talkingPointEntityDefinition } from './TalkingPoint.entity';
import { taskEntityDefinition } from './Task.entity';
import { taskAttachmentEntityDefinition } from './TaskAttachment.entity';
import { taxGroupEntityDefinition } from './TaxGroup.entity';
import { taxKeyEntityDefinition } from './TaxKey.entity';
import { tempFileEntityDefinition } from './TempFile.entity';
import { templateGroupEntityDefinition } from './TemplateGroup.entity';
import { tenantEntityDefinition } from './Tenant.entity';
import { textBuildingBlockEntityDefinition } from './TextBuildingBlock.entity';
import { textBuildingBlockGroupEntityDefinition } from './TextBuildingBlockGroup.entity';
import { thumbnailEntityDefinition } from './Thumbnail.entity';
import { ticketEntityDefinition } from './Ticket.entity';
import { ticketAttachmentEntityDefinition } from './TicketAttachment.entity';
import { ticketCategory1EntityDefinition } from './TicketCategory1.entity';
import { ticketChecklisteMarkEntityDefinition } from './TicketChecklisteMark.entity';
import { ticketFilterEntityDefinition } from './TicketFilter.entity';
import { ticketKindEntityDefinition } from './TicketKind.entity';
import { timeCardEntityDefinition } from './TimeCard.entity';
import { timeTrackingEntityDefinition } from './TimeTracking.entity';
import { timeTrackingOverviewItemEntityDefinition } from './TimeTrackingOverviewItem.entity';
import { topicSimpleEntityDefinition } from './TopicSimple.entity';
import { tourEntityDefinition } from './Tour.entity';
import { travelCostInvoiceKindEntityDefinition } from './TravelCostInvoiceKind.entity';
import { travelCostInvoiceKindTransportCostEntityDefinition } from './TravelCostInvoiceKindTransportCost.entity';
import { travelExpenseReportPaymentMethodEntityDefinition } from './TravelExpenseReportPaymentMethod.entity';
import { travelExpensesEntityDefinition } from './TravelExpenses.entity';
import { travelReceiptsEntityDefinition } from './TravelReceipts.entity';
import { unitEntityDefinition } from './Unit.entity';
import { userEntityDefinition } from './User.entity';
import { userApperanceConfigurationEntityDefinition } from './UserApperanceConfiguration.entity';
import { userApperanceConfigurationInputEntityDefinition } from './UserApperanceConfigurationInput.entity';
import { userCollectionEntityDefinition } from './UserCollection.entity';
import { userDailyTargetEntityDefinition } from './UserDailyTarget.entity';
import { userGroupEntityDefinition } from './UserGroup.entity';
import { userLicenseInformationEntityDefinition } from './UserLicenseInformation.entity';
import { userLicenseInformationItemEntityDefinition } from './UserLicenseInformationItem.entity';
import { userPresenceEntityDefinition } from './UserPresence.entity';
import { userPresenceTimeInfoEntityDefinition } from './UserPresenceTimeInfo.entity';
import { userPresenceTimeInfoItemEntityDefinition } from './UserPresenceTimeInfoItem.entity';
import { userRightDefinitionEntityDefinition } from './UserRightDefinition.entity';
import { userRightsFilterEntityDefinition } from './UserRightsFilter.entity';
import { userRoleEntityDefinition } from './UserRole.entity';
import { userSyncOptionsEntityDefinition } from './UserSyncOptions.entity';
import { vacationEntityDefinition } from './Vacation.entity';
import { vacationEntitlementEntityDefinition } from './VacationEntitlement.entity';
import { vacationInfoEntityDefinition } from './VacationInfo.entity';
import { vacationKindEntityDefinition } from './VacationKind.entity';
import { vacationRequestEntityDefinition } from './VacationRequest.entity';
import { validateSetMfaModeResponseEntityDefinition } from './ValidateSetMfaModeResponse.entity';
import { validationMessageEntityDefinition } from './ValidationMessage.entity';
import { vatRateEntityDefinition } from './VatRate.entity';
import { vaultSettingItemEntityDefinition } from './VaultSettingItem.entity';
import { visitationReportEntityDefinition } from './VisitationReport.entity';
import { visitationReportAttendeeEntityDefinition } from './VisitationReportAttendee.entity';
import { widgetDataEntityDefinition } from './WidgetData.entity';
import { widgetDataUnionEntityDefinition } from './WidgetDataUnion.entity';
import { wordDocumentTemplateEntityDefinition } from './WordDocumentTemplate.entity';
import { wordLetterTemplateEntityDefinition } from './WordLetterTemplate.entity';
import { work4allSubscriptionEntityDefinition } from './Work4allSubscription.entity';
import { workingTimeTimeCardDayInfoEntityDefinition } from './WorkingTimeTimeCardDayInfo.entity';
import { workingTimeTimeCardMonthCorrectionEntityDefinition } from './WorkingTimeTimeCardMonthCorrection.entity';
import { workTimeShortInfoEntityDefinition } from './WorkTimeShortInfo.entity';

export const entityDefinition: EntitiyDefinitionMap = {};
entityDefinition[Entities.department] = departmentEntityDefinition;
entityDefinition[Entities.absenceOverview] = absenceOverviewEntityDefinition;
entityDefinition[Entities.addressConnection] =
  addressConnectionEntityDefinition;
entityDefinition[Entities.offer] = offerEntityDefinition;
entityDefinition[Entities.salutation] = salutationEntityDefinition;
entityDefinition[Entities.contact] = contactEntityDefinition;
entityDefinition[Entities.archivePdf] = archivePdfEntityDefinition;
entityDefinition[Entities.article] = articleEntityDefinition;
entityDefinition[Entities.articlePrices] = articlePricesEntityDefinition;
entityDefinition[Entities.task] = taskEntityDefinition;
entityDefinition[Entities.contract] = contractEntityDefinition;
entityDefinition[Entities.demand] = demandEntityDefinition;
entityDefinition[Entities.user] = userEntityDefinition;
entityDefinition[Entities.order] = orderEntityDefinition;
entityDefinition[Entities.visitationReport] = visitationReportEntityDefinition;
entityDefinition[Entities.letter] = letterEntityDefinition;
entityDefinition[Entities.checkList] = checkListEntityDefinition;
entityDefinition[Entities.checkListPosition] =
  checkListPositionEntityDefinition;
entityDefinition[Entities.customField] = customFieldEntityDefinition;
entityDefinition[Entities.dhlShipment] = dhlShipmentEntityDefinition;
entityDefinition[Entities.document] = documentEntityDefinition;
entityDefinition[Entities.eMail] = eMailEntityDefinition;
entityDefinition[Entities.eMailAttachment] = eMailAttachmentEntityDefinition;
entityDefinition[Entities.inboundDeliveryNote] =
  inboundDeliveryNoteEntityDefinition;
entityDefinition[Entities.inboundInvoice] = inboundInvoiceEntityDefinition;
entityDefinition[Entities.unit] = unitEntityDefinition;
entityDefinition[Entities.purchasePrice] = purchasePriceEntityDefinition;
entityDefinition[Entities.singlePrice] = singlePriceEntityDefinition;
entityDefinition[Entities.entityChangedInfo] =
  entityChangedInfoEntityDefinition;
entityDefinition[Entities.entitySchema] = entitySchemaEntityDefinition;
entityDefinition[Entities.erpAttachment] = erpAttachmentEntityDefinition;
entityDefinition[Entities.fileMetaInfo] = fileMetaInfoEntityDefinition;
entityDefinition[Entities.generalSettings] = generalSettingsEntityDefinition;
entityDefinition[Entities.businessPartner] = businessPartnerEntityDefinition;
entityDefinition[Entities.group] = groupEntityDefinition;
entityDefinition[Entities.groupQueryResult] = groupQueryResultEntityDefinition;
entityDefinition[Entities.groupQueryResultItem] =
  groupQueryResultItemEntityDefinition;
entityDefinition[Entities.calculation] = calculationEntityDefinition;
entityDefinition[Entities.categoryAssignment] =
  categoryAssignmentEntityDefinition;
entityDefinition[Entities.workingTimeTimeCardMonthCorrection] =
  workingTimeTimeCardMonthCorrectionEntityDefinition;
entityDefinition[Entities.workingTimeTimeCardDayInfo] =
  workingTimeTimeCardDayInfoEntityDefinition;
entityDefinition[Entities.costCenter] = costCenterEntityDefinition;
entityDefinition[Entities.sickness] = sicknessEntityDefinition;
entityDefinition[Entities.customer] = customerEntityDefinition;
entityDefinition[Entities.customerGroup] = customerGroupEntityDefinition;
entityDefinition[Entities.customerIndividualPrice] =
  customerIndividualPriceEntityDefinition;
entityDefinition[Entities.layout] = layoutEntityDefinition;
entityDefinition[Entities.layoutData] = layoutDataEntityDefinition;
entityDefinition[Entities.supplier] = supplierEntityDefinition;
entityDefinition[Entities.supplierGroup] = supplierGroupEntityDefinition;
entityDefinition[Entities.deliveryNote] = deliveryNoteEntityDefinition;
entityDefinition[Entities.deliveryKind] = deliveryKindEntityDefinition;
entityDefinition[Entities.lookUp] = lookUpEntityDefinition;
entityDefinition[Entities.tenant] = tenantEntityDefinition;
entityDefinition[Entities.mutation] = mutationEntityDefinition;
entityDefinition[Entities.businessPartnerContactCombined] =
  businessPartnerContactCombinedEntityDefinition;
entityDefinition[Entities.note] = noteEntityDefinition;
entityDefinition[Entities.objectLock] = objectLockEntityDefinition;
entityDefinition[Entities.position] = positionEntityDefinition;
entityDefinition[Entities.priceGroup] = priceGroupEntityDefinition;
entityDefinition[Entities.productionContract] =
  productionContractEntityDefinition;
entityDefinition[Entities.project] = projectEntityDefinition;
entityDefinition[Entities.projectInvolveds] = projectInvolvedsEntityDefinition;
entityDefinition[Entities.projectGroup] = projectGroupEntityDefinition;
entityDefinition[Entities.projectDistributor] =
  projectDistributorEntityDefinition;
entityDefinition[Entities.projectDistributorEntry] =
  projectDistributorEntryEntityDefinition;
entityDefinition[Entities.projectProcess] = projectProcessEntityDefinition;
entityDefinition[Entities.projectPlanningFeedback] =
  projectPlanningFeedbackEntityDefinition;
entityDefinition[Entities.query] = queryEntityDefinition;
entityDefinition[Entities.rELedgerAccountSplit] =
  rELedgerAccountSplitEntityDefinition;
entityDefinition[Entities.invoice] = invoiceEntityDefinition;
entityDefinition[Entities.travelExpenses] = travelExpensesEntityDefinition;
entityDefinition[Entities.report] = reportEntityDefinition;
entityDefinition[Entities.ledgerAccount] = ledgerAccountEntityDefinition;
entityDefinition[Entities.baseDataLanguage] = baseDataLanguageEntityDefinition;
entityDefinition[Entities.partialInvoiceLogic] =
  partialInvoiceLogicEntityDefinition;
entityDefinition[Entities.partialCalculationLogicDetails] =
  partialCalculationLogicDetailsEntityDefinition;
entityDefinition[Entities.callMemo] = callMemoEntityDefinition;
entityDefinition[Entities.appointment] = appointmentEntityDefinition;
entityDefinition[Entities.appointmentAttendee] =
  appointmentAttendeeEntityDefinition;
entityDefinition[Entities.textBuildingBlock] =
  textBuildingBlockEntityDefinition;
entityDefinition[Entities.ticket] = ticketEntityDefinition;
entityDefinition[Entities.ticketAttachment] = ticketAttachmentEntityDefinition;
entityDefinition[Entities.ticketKind] = ticketKindEntityDefinition;
entityDefinition[Entities.ticketCategory1] = ticketCategory1EntityDefinition;
entityDefinition[Entities.tour] = tourEntityDefinition;
entityDefinition[Entities.vacation] = vacationEntityDefinition;
entityDefinition[Entities.category] = categoryEntityDefinition;
entityDefinition[Entities.categoryClass] = categoryClassEntityDefinition;
entityDefinition[Entities.currency] = currencyEntityDefinition;
entityDefinition[Entities.inboundPosition] = inboundPositionEntityDefinition;
entityDefinition[Entities.serviceContract] = serviceContractEntityDefinition;
entityDefinition[Entities.serviceContractPosition] =
  serviceContractPositionEntityDefinition;
entityDefinition[Entities.widgetData] = widgetDataEntityDefinition;
entityDefinition[Entities.work4allSubscription] =
  work4allSubscriptionEntityDefinition;
entityDefinition[Entities.paymentKind] = paymentKindEntityDefinition;
entityDefinition[Entities.timeTracking] = timeTrackingEntityDefinition;
entityDefinition[Entities.timeCard] = timeCardEntityDefinition;
entityDefinition[Entities.bankDetails] = bankDetailsEntityDefinition;
entityDefinition[Entities.bulkPriceTier] = bulkPriceTierEntityDefinition;
entityDefinition[Entities.bulkPriceTierItem] =
  bulkPriceTierItemEntityDefinition;
entityDefinition[Entities.objectLockResult] = objectLockResultEntityDefinition;
entityDefinition[Entities.documentClass] = documentClassEntityDefinition;
entityDefinition[Entities.articleGroup] = articleGroupEntityDefinition;
entityDefinition[Entities.deleteEntityResult] =
  deleteEntityResultEntityDefinition;
entityDefinition[Entities.kpisCustomer] = kpisCustomerEntityDefinition;
entityDefinition[Entities.kpisCustomerSalesValueYear] =
  kpisCustomerSalesValueYearEntityDefinition;
entityDefinition[Entities.deleteErrorMessage] =
  deleteErrorMessageEntityDefinition;
entityDefinition[Entities.modifyShadowBzObjectResult] =
  modifyShadowBzObjectResultEntityDefinition;
entityDefinition[Entities.taskAttachment] = taskAttachmentEntityDefinition;
entityDefinition[Entities.noteAttachment] = noteAttachmentEntityDefinition;
entityDefinition[Entities.appointmentAttachment] =
  appointmentAttachmentEntityDefinition;
entityDefinition[Entities.callMemoAttachment] =
  callMemoAttachmentEntityDefinition;
entityDefinition[Entities.appointmentState] = appointmentStateEntityDefinition;
entityDefinition[Entities.mailSearchContactsResultItem] =
  mailSearchContactsResultItemEntityDefinition;
entityDefinition[Entities.eMailTemplate] = eMailTemplateEntityDefinition;
entityDefinition[Entities.eMailSignature] = eMailSignatureEntityDefinition;
entityDefinition[Entities.groupQueryResultRow] =
  groupQueryResultRowEntityDefinition;
entityDefinition[Entities.sendEMailResult] = sendEMailResultEntityDefinition;
entityDefinition[Entities.contactUnionWrapper] =
  contactUnionWrapperEntityDefinition;
entityDefinition[Entities.taxKey] = taxKeyEntityDefinition;
entityDefinition[Entities.exchangeRate] = exchangeRateEntityDefinition;
entityDefinition[Entities.fileEntity] = fileEntityEntityDefinition;
entityDefinition[Entities.topicSimple] = topicSimpleEntityDefinition;
entityDefinition[Entities.ocrInvoiceData] = ocrInvoiceDataEntityDefinition;
entityDefinition[Entities.ocrInvoiceSupplierGuessing] =
  ocrInvoiceSupplierGuessingEntityDefinition;
entityDefinition[Entities.country] = countryEntityDefinition;
entityDefinition[Entities.statisticSalesVolumeYear] =
  statisticSalesVolumeYearEntityDefinition;
entityDefinition[Entities.statisticSalesVolumeYearItem] =
  statisticSalesVolumeYearItemEntityDefinition;
entityDefinition[Entities.convertTempFileResult] =
  convertTempFileResultEntityDefinition;
entityDefinition[Entities.vatRate] = vatRateEntityDefinition;
entityDefinition[Entities.articleText] = articleTextEntityDefinition;
entityDefinition[Entities.letterTemplateGroup] =
  letterTemplateGroupEntityDefinition;
entityDefinition[Entities.processWordTemplateResult] =
  processWordTemplateResultEntityDefinition;
entityDefinition[Entities.statisticSalesVoumeByCustomer] =
  statisticSalesVoumeByCustomerEntityDefinition;
entityDefinition[Entities.statisticSalesVolumeBySupplier] =
  statisticSalesVolumeBySupplierEntityDefinition;
entityDefinition[Entities.wordLetterTemplate] =
  wordLetterTemplateEntityDefinition;
entityDefinition[Entities.templateGroup] = templateGroupEntityDefinition;
entityDefinition[Entities.documentTemplateGroup] =
  documentTemplateGroupEntityDefinition;
entityDefinition[Entities.wordDocumentTemplate] =
  wordDocumentTemplateEntityDefinition;
entityDefinition[Entities.subDirectoryTemplateItem] =
  subDirectoryTemplateItemEntityDefinition;
entityDefinition[Entities.timeTrackingOverviewItem] =
  timeTrackingOverviewItemEntityDefinition;
entityDefinition[Entities.holiday] = holidayEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeByCustomerColumn] =
  statisticsSalesVolumeByCustomerColumnEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeByCustomerRow] =
  statisticsSalesVolumeByCustomerRowEntityDefinition;
entityDefinition[Entities.statisticSalesVoumeByItem] =
  statisticSalesVoumeByItemEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeByItemColumn] =
  statisticsSalesVolumeByItemColumnEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeByItemRow] =
  statisticsSalesVolumeByItemRowEntityDefinition;
entityDefinition[Entities.statisticSalesVoumeByCostCenter] =
  statisticSalesVoumeByCostCenterEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeByCostCenterColumn] =
  statisticsSalesVolumeByCostCenterColumnEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeByCostCenterRow] =
  statisticsSalesVolumeByCostCenterRowEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeBySupplierColumn] =
  statisticsSalesVolumeBySupplierColumnEntityDefinition;
entityDefinition[Entities.statisticsSalesVolumeBySupplierRow] =
  statisticsSalesVolumeBySupplierRowEntityDefinition;
entityDefinition[Entities.setUserPresenceResult] =
  setUserPresenceResultEntityDefinition;
entityDefinition[Entities.appearance] = appearanceEntityDefinition;
entityDefinition[Entities.appearanceBackgroundImage] =
  appearanceBackgroundImageEntityDefinition;
entityDefinition[Entities.userApperanceConfiguration] =
  userApperanceConfigurationEntityDefinition;
entityDefinition[Entities.companyKpis] = companyKpisEntityDefinition;
entityDefinition[Entities.shadowRe] = shadowReEntityDefinition;
entityDefinition[Entities.setting] = settingEntityDefinition;
entityDefinition[Entities.vaultSettingItem] = vaultSettingItemEntityDefinition;
entityDefinition[Entities.modifyShadowREResult] =
  modifyShadowREResultEntityDefinition;
entityDefinition[Entities.baseDataAttachment] =
  baseDataAttachmentEntityDefinition;
entityDefinition[Entities.userPresenceTimeInfo] =
  userPresenceTimeInfoEntityDefinition;
entityDefinition[Entities.userPresence] = userPresenceEntityDefinition;
entityDefinition[Entities.productionContractGroup] =
  productionContractGroupEntityDefinition;
entityDefinition[Entities.licenseInfo] = licenseInfoEntityDefinition;
entityDefinition[Entities.licenseModulInfo] = licenseModulInfoEntityDefinition;
entityDefinition[Entities.visitationReportAttendee] =
  visitationReportAttendeeEntityDefinition;
entityDefinition[Entities.talkingPoint] = talkingPointEntityDefinition;
entityDefinition[Entities.workTimeShortInfo] =
  workTimeShortInfoEntityDefinition;
entityDefinition[Entities.vacationEntitlement] =
  vacationEntitlementEntityDefinition;
entityDefinition[Entities.userRole] = userRoleEntityDefinition;
entityDefinition[Entities.createEvent] = createEventEntityDefinition;
entityDefinition[Entities.changeEvent] = changeEventEntityDefinition;
entityDefinition[Entities.changeEventChangeInfo] =
  changeEventChangeInfoEntityDefinition;
entityDefinition[Entities.legalNoticeParseResult] =
  legalNoticeParseResultEntityDefinition;
entityDefinition[Entities.file] = fileEntityDefinition;
entityDefinition[Entities.eMailTemplateAttachment] =
  eMailTemplateAttachmentEntityDefinition;
entityDefinition[Entities.projectCategoryClass] =
  projectCategoryClassEntityDefinition;
entityDefinition[Entities.projectCategory] = projectCategoryEntityDefinition;
entityDefinition[Entities.saveSendMailJob] = saveSendMailJobEntityDefinition;
entityDefinition[Entities.validationMessage] =
  validationMessageEntityDefinition;
entityDefinition[Entities.vacationRequest] = vacationRequestEntityDefinition;
entityDefinition[Entities.userPresenceTimeInfoItem] =
  userPresenceTimeInfoItemEntityDefinition;
entityDefinition[Entities.ticketChecklisteMark] =
  ticketChecklisteMarkEntityDefinition;
entityDefinition[Entities.eMailTemplateGroup] =
  eMailTemplateGroupEntityDefinition;
entityDefinition[Entities.userCollection] = userCollectionEntityDefinition;
entityDefinition[Entities.subObjectLockResult] =
  subObjectLockResultEntityDefinition;
entityDefinition[Entities.bulkPrice] = bulkPriceEntityDefinition;
entityDefinition[Entities.chronoFileItem] = chronoFileItemEntityDefinition;
entityDefinition[Entities.moduleAccessRight] =
  moduleAccessRightEntityDefinition;
entityDefinition[Entities.vacationInfo] = vacationInfoEntityDefinition;
entityDefinition[Entities.processedEMailTemplate] =
  processedEMailTemplateEntityDefinition;
entityDefinition[Entities.userLicenseInformation] =
  userLicenseInformationEntityDefinition;
entityDefinition[Entities.userLicenseInformationItem] =
  userLicenseInformationItemEntityDefinition;
entityDefinition[Entities.entityAccessRights] =
  entityAccessRightsEntityDefinition;
entityDefinition[Entities.entityAccessRightsState] =
  entityAccessRightsStateEntityDefinition;
entityDefinition[Entities.textBuildingBlockGroup] =
  textBuildingBlockGroupEntityDefinition;
entityDefinition[Entities.contactTelephonenumbers] =
  contactTelephonenumbersEntityDefinition;
entityDefinition[Entities.ticketFilter] = ticketFilterEntityDefinition;
entityDefinition[Entities.sumValues] = sumValuesEntityDefinition;
entityDefinition[Entities.processedEMailTemplateAttachment] =
  processedEMailTemplateAttachmentEntityDefinition;
entityDefinition[Entities.customFieldDefinition] =
  customFieldDefinitionEntityDefinition;
entityDefinition[Entities.managementDashboard] =
  managementDashboardEntityDefinition;
entityDefinition[Entities.tempFile] = tempFileEntityDefinition;
entityDefinition[Entities.postIt] = postItEntityDefinition;
entityDefinition[Entities.mailbox] = mailboxEntityDefinition;
entityDefinition[Entities.mailboxFolder] = mailboxFolderEntityDefinition;
entityDefinition[Entities.mailboxContent] = mailboxContentEntityDefinition;
entityDefinition[Entities.defaultErpText] = defaultErpTextEntityDefinition;
entityDefinition[Entities.notification] = notificationEntityDefinition;
entityDefinition[Entities.supplierDemandAddign] =
  supplierDemandAddignEntityDefinition;
entityDefinition[Entities.savedListFilter] = savedListFilterEntityDefinition;
entityDefinition[Entities.vacationKind] = vacationKindEntityDefinition;
entityDefinition[Entities.salesOpportunities] =
  salesOpportunitiesEntityDefinition;
entityDefinition[Entities.salesOpportunityGroup] =
  salesOpportunityGroupEntityDefinition;
entityDefinition[Entities.salesOpportunityRating] =
  salesOpportunityRatingEntityDefinition;
entityDefinition[Entities.childItemCreated] = childItemCreatedEntityDefinition;
entityDefinition[Entities.mailboxConfiguration] =
  mailboxConfigurationEntityDefinition;
entityDefinition[Entities.projectAccessRights] =
  projectAccessRightsEntityDefinition;
entityDefinition[Entities.mention] = mentionEntityDefinition;
entityDefinition[Entities.createReportResult] =
  createReportResultEntityDefinition;
entityDefinition[Entities.thumbnail] = thumbnailEntityDefinition;
entityDefinition[Entities.commentEvent] = commentEventEntityDefinition;
entityDefinition[Entities.reportOption] = reportOptionEntityDefinition;
entityDefinition[Entities.salesOpportunityRatingStatus] =
  salesOpportunityRatingStatusEntityDefinition;
entityDefinition[Entities.projectAccessGroup] =
  projectAccessGroupEntityDefinition;
entityDefinition[Entities.statisticCompanyReport] =
  statisticCompanyReportEntityDefinition;
entityDefinition[Entities.statisticCompanyReportColumn] =
  statisticCompanyReportColumnEntityDefinition;
entityDefinition[Entities.statisticCompanyReportKeyFigures] =
  statisticCompanyReportKeyFiguresEntityDefinition;
entityDefinition[Entities.statisticCompanyReportFigures] =
  statisticCompanyReportFiguresEntityDefinition;
entityDefinition[Entities.kpisSupplier] = kpisSupplierEntityDefinition;
entityDefinition[Entities.kpisSupplierSalesValueYear] =
  kpisSupplierSalesValueYearEntityDefinition;
entityDefinition[Entities.bzObjectConversionResult] =
  bzObjectConversionResultEntityDefinition;
entityDefinition[Entities.slModeSetting] = slModeSettingEntityDefinition;
entityDefinition[Entities.inboundInvoicePayment] =
  inboundInvoicePaymentEntityDefinition;
entityDefinition[Entities.salesOpportunityAttachement] =
  salesOpportunityAttachementEntityDefinition;
entityDefinition[Entities.duplicateResponse] =
  duplicateResponseEntityDefinition;
entityDefinition[Entities.ra] = raEntityDefinition;
entityDefinition[Entities.raLedgerAccountSplit] =
  raLedgerAccountSplitEntityDefinition;
entityDefinition[Entities.raPayment] = raPaymentEntityDefinition;
entityDefinition[Entities.articleImage] = articleImageEntityDefinition;
entityDefinition[Entities.travelReceipts] = travelReceiptsEntityDefinition;
entityDefinition[Entities.report2] = report2EntityDefinition;
entityDefinition[Entities.incomingMailBlockedSender] =
  incomingMailBlockedSenderEntityDefinition;
entityDefinition[Entities.userRightDefinition] =
  userRightDefinitionEntityDefinition;
entityDefinition[Entities.sprint] = sprintEntityDefinition;
entityDefinition[Entities.setMfaModeResponse] =
  setMfaModeResponseEntityDefinition;
entityDefinition[Entities.validateSetMfaModeResponse] =
  validateSetMfaModeResponseEntityDefinition;
entityDefinition[Entities.travelCostInvoiceKind] =
  travelCostInvoiceKindEntityDefinition;
entityDefinition[Entities.travelCostInvoiceKindTransportCost] =
  travelCostInvoiceKindTransportCostEntityDefinition;
entityDefinition[Entities.travelExpenseReportPaymentMethod] =
  travelExpenseReportPaymentMethodEntityDefinition;
entityDefinition[Entities.fileLink] = fileLinkEntityDefinition;
entityDefinition[Entities.taxGroup] = taxGroupEntityDefinition;
entityDefinition[Entities.projectDirectoryDefinition] =
  projectDirectoryDefinitionEntityDefinition;
entityDefinition[Entities.articleLedgerAccount] =
  articleLedgerAccountEntityDefinition;
entityDefinition[Entities.bzObjectReleaseInformation] =
  bzObjectReleaseInformationEntityDefinition;
entityDefinition[Entities.reViewModel] = reViewModelEntityDefinition;
entityDefinition[Entities.projectProcessLink] =
  projectProcessLinkEntityDefinition;
entityDefinition[Entities.statisticCustomerDatasheet] =
  statisticCustomerDatasheetEntityDefinition;
entityDefinition[Entities.statisticCustomerDatasheetColumn] =
  statisticCustomerDatasheetColumnEntityDefinition;
entityDefinition[Entities.statisticCustomerDatasheetStatistic] =
  statisticCustomerDatasheetStatisticEntityDefinition;
entityDefinition[Entities.statisticSupplierDatasheet] =
  statisticSupplierDatasheetEntityDefinition;
entityDefinition[Entities.projectProcessCompletionFeedback] =
  projectProcessCompletionFeedbackEntityDefinition;
entityDefinition[Entities.raViewModel] = raViewModelEntityDefinition;
entityDefinition[Entities.resourceUtilizationInfo] =
  resourceUtilizationInfoEntityDefinition;
entityDefinition[Entities.fileServiceProviderConfig] =
  fileServiceProviderConfigEntityDefinition;
entityDefinition[Entities.bzObjectMutationResponse] =
  bzObjectMutationResponseEntityDefinition;
entityDefinition[Entities.articleCategoryMark] =
  articleCategoryMarkEntityDefinition;
entityDefinition[Entities.articleCategory] = articleCategoryEntityDefinition;
entityDefinition[Entities.articleInventoryInfo] =
  articleInventoryInfoEntityDefinition;
entityDefinition[Entities.storageLocation] = storageLocationEntityDefinition;
entityDefinition[Entities.userGroup] = userGroupEntityDefinition;
entityDefinition[Entities.bOMComponent] = bOMComponentEntityDefinition;
entityDefinition[Entities.articlePurchaseInformation] =
  articlePurchaseInformationEntityDefinition;
entityDefinition[Entities.fullUser] = fullUserEntityDefinition;
entityDefinition[Entities.pauseRule] = pauseRuleEntityDefinition;
entityDefinition[Entities.userDailyTarget] = userDailyTargetEntityDefinition;
entityDefinition[Entities.userSyncOptions] = userSyncOptionsEntityDefinition;
entityDefinition[Entities.businessPartnerUnion] =
  businessPartnerUnionEntityDefinition;
entityDefinition[Entities.bzObject] = bzObjectEntityDefinition;
entityDefinition[Entities.articleImageUnion] =
  articleImageUnionEntityDefinition;
entityDefinition[Entities.chronoFileItemUnion] =
  chronoFileItemUnionEntityDefinition;
entityDefinition[Entities.notificationUnion] =
  notificationUnionEntityDefinition;
entityDefinition[Entities.mentionUnion] = mentionUnionEntityDefinition;
entityDefinition[Entities.contactUnion] = contactUnionEntityDefinition;
entityDefinition[Entities.shadowBzObject] = shadowBzObjectEntityDefinition;
entityDefinition[Entities.searchResultItem] = searchResultItemEntityDefinition;
entityDefinition[Entities.widgetDataUnion] = widgetDataUnionEntityDefinition;
entityDefinition[Entities.event] = eventEntityDefinition;
entityDefinition[Entities.childItemCreatedObject] =
  childItemCreatedObjectEntityDefinition;
entityDefinition[Entities.duplicatedObjectUnion] =
  duplicatedObjectUnionEntityDefinition;
entityDefinition[Entities.groupQuery] = groupQueryEntityDefinition;
entityDefinition[Entities.groupFieldDefinition] =
  groupFieldDefinitionEntityDefinition;
entityDefinition[Entities.groupQuerySortInfo] =
  groupQuerySortInfoEntityDefinition;
entityDefinition[Entities.inputLayout] = inputLayoutEntityDefinition;
entityDefinition[Entities.inputCustomField] = inputCustomFieldEntityDefinition;
entityDefinition[Entities.inputSetting] = inputSettingEntityDefinition;
entityDefinition[Entities.inputKunde] = inputKundeEntityDefinition;
entityDefinition[Entities.inputLieferant] = inputLieferantEntityDefinition;
entityDefinition[Entities.inputNotiz] = inputNotizEntityDefinition;
entityDefinition[Entities.inputNotizRelation] =
  inputNotizRelationEntityDefinition;
entityDefinition[Entities.inputCrmAnhangAttachementsRelation] =
  inputCrmAnhangAttachementsRelationEntityDefinition;
entityDefinition[Entities.inputCrmAnhangAttachementAdd] =
  inputCrmAnhangAttachementAddEntityDefinition;
entityDefinition[Entities.inputCrmAnhangAttachementModify] =
  inputCrmAnhangAttachementModifyEntityDefinition;
entityDefinition[Entities.inputTopicMarkRelation] =
  inputTopicMarkRelationEntityDefinition;
entityDefinition[Entities.inputTelefonat] = inputTelefonatEntityDefinition;
entityDefinition[Entities.inputTelefonatRelation] =
  inputTelefonatRelationEntityDefinition;
entityDefinition[Entities.inputAufgabe] = inputAufgabeEntityDefinition;
entityDefinition[Entities.inputDokument] = inputDokumentEntityDefinition;
entityDefinition[Entities.inputBrief] = inputBriefEntityDefinition;
entityDefinition[Entities.inputCheckliste] = inputChecklisteEntityDefinition;
entityDefinition[Entities.inputChecklistePosition] =
  inputChecklistePositionEntityDefinition;
entityDefinition[Entities.inputEMail] = inputEMailEntityDefinition;
entityDefinition[Entities.inputEMailRelation] =
  inputEMailRelationEntityDefinition;
entityDefinition[Entities.inputEMailAnhangAttachementsRelation] =
  inputEMailAnhangAttachementsRelationEntityDefinition;
entityDefinition[Entities.inputEMailVorlage] =
  inputEMailVorlageEntityDefinition;
entityDefinition[Entities.inputEMailSignatur] =
  inputEMailSignaturEntityDefinition;
entityDefinition[Entities.inputTelefonatAnhang] =
  inputTelefonatAnhangEntityDefinition;
entityDefinition[Entities.inputTerminAnhang] =
  inputTerminAnhangEntityDefinition;
entityDefinition[Entities.inputAufgabeAnhang] =
  inputAufgabeAnhangEntityDefinition;
entityDefinition[Entities.inputNotizAnhang] = inputNotizAnhangEntityDefinition;
entityDefinition[Entities.inputBesuchsbericht] =
  inputBesuchsberichtEntityDefinition;
entityDefinition[Entities.inputAnsprechpartner] =
  inputAnsprechpartnerEntityDefinition;
entityDefinition[Entities.inputAdressVerknuepfung] =
  inputAdressVerknuepfungEntityDefinition;
entityDefinition[Entities.inputTermin] = inputTerminEntityDefinition;
entityDefinition[Entities.inputTerminRelation] =
  inputTerminRelationEntityDefinition;
entityDefinition[Entities.inputTerminTeilnehmerRelation] =
  inputTerminTeilnehmerRelationEntityDefinition;
entityDefinition[Entities.inputTerminTeilnehmerAdd] =
  inputTerminTeilnehmerAddEntityDefinition;
entityDefinition[Entities.inputTicket] = inputTicketEntityDefinition;
entityDefinition[Entities.inputKalkulation] = inputKalkulationEntityDefinition;
entityDefinition[Entities.inputAngebot] = inputAngebotEntityDefinition;
entityDefinition[Entities.inputPosition] = inputPositionEntityDefinition;
entityDefinition[Entities.inputAuftrag] = inputAuftragEntityDefinition;
entityDefinition[Entities.inputRechnung] = inputRechnungEntityDefinition;
entityDefinition[Entities.inputLieferschein] =
  inputLieferscheinEntityDefinition;
entityDefinition[Entities.inputBedarfsanforderung] =
  inputBedarfsanforderungEntityDefinition;
entityDefinition[Entities.inputBestellung] = inputBestellungEntityDefinition;
entityDefinition[Entities.inputEingangslieferschein] =
  inputEingangslieferscheinEntityDefinition;
entityDefinition[Entities.inputProjekt] = inputProjektEntityDefinition;
entityDefinition[Entities.inputZeiterfassung] =
  inputZeiterfassungEntityDefinition;
entityDefinition[Entities.inputKundeRelation] =
  inputKundeRelationEntityDefinition;
entityDefinition[Entities.inputCategoryMarkRelation] =
  inputCategoryMarkRelationEntityDefinition;
entityDefinition[Entities.inputCategoryMarkRelationAdd] =
  inputCategoryMarkRelationAddEntityDefinition;
entityDefinition[Entities.inputCategoryMarkRelationModify] =
  inputCategoryMarkRelationModifyEntityDefinition;
entityDefinition[Entities.inputLieferantRelation] =
  inputLieferantRelationEntityDefinition;
entityDefinition[Entities.inputAufgabeRelation] =
  inputAufgabeRelationEntityDefinition;
entityDefinition[Entities.inputDokumentRelation] =
  inputDokumentRelationEntityDefinition;
entityDefinition[Entities.inputBriefRelation] =
  inputBriefRelationEntityDefinition;
entityDefinition[Entities.inputTemplateFileRelation] =
  inputTemplateFileRelationEntityDefinition;
entityDefinition[Entities.inputLetterDocumentFileRelation] =
  inputLetterDocumentFileRelationEntityDefinition;
entityDefinition[Entities.inputEMailAnhangAttachementAdd] =
  inputEMailAnhangAttachementAddEntityDefinition;
entityDefinition[Entities.appearanceSettingsInput] =
  appearanceSettingsInputEntityDefinition;
entityDefinition[Entities.appearanceSettingsInputAddBackgroundImage] =
  appearanceSettingsInputAddBackgroundImageEntityDefinition;
entityDefinition[Entities.userApperanceConfigurationInput] =
  userApperanceConfigurationInputEntityDefinition;
entityDefinition[Entities.inputBankAccountRelation] =
  inputBankAccountRelationEntityDefinition;
entityDefinition[Entities.inputbankAccountRelationAddModify] =
  inputbankAccountRelationAddModifyEntityDefinition;
entityDefinition[Entities.inputAnsprechpartnerRelation] =
  inputAnsprechpartnerRelationEntityDefinition;
entityDefinition[Entities.inputRESachkontenSplit] =
  inputRESachkontenSplitEntityDefinition;
entityDefinition[Entities.inputAddressConnectionRelation] =
  inputAddressConnectionRelationEntityDefinition;
entityDefinition[Entities.inputAddressConnectionRelationAdd] =
  inputAddressConnectionRelationAddEntityDefinition;
entityDefinition[Entities.inputStammdatenAttachementsRelation] =
  inputStammdatenAttachementsRelationEntityDefinition;
entityDefinition[Entities.inputAttachementAdd] =
  inputAttachementAddEntityDefinition;
entityDefinition[Entities.inputStammdatenAttachementModify] =
  inputStammdatenAttachementModifyEntityDefinition;
entityDefinition[Entities.stringKeyValue] = stringKeyValueEntityDefinition;
entityDefinition[Entities.inputUrlaub] = inputUrlaubEntityDefinition;
entityDefinition[Entities.inputKrankheit] = inputKrankheitEntityDefinition;
entityDefinition[Entities.inputTicketRelation] =
  inputTicketRelationEntityDefinition;
entityDefinition[Entities.inputTicketAttachementsRelation] =
  inputTicketAttachementsRelationEntityDefinition;
entityDefinition[Entities.userRightsFilter] = userRightsFilterEntityDefinition;
entityDefinition[Entities.inputVacationRequest] =
  inputVacationRequestEntityDefinition;
entityDefinition[Entities.inputProjektRelation] =
  inputProjektRelationEntityDefinition;
entityDefinition[Entities.inputProjectCategoryRelation] =
  inputProjectCategoryRelationEntityDefinition;
entityDefinition[Entities.inputTicketChecklisteMarkRelation] =
  inputTicketChecklisteMarkRelationEntityDefinition;
entityDefinition[Entities.inputKommtGehtZeitstempel] =
  inputKommtGehtZeitstempelEntityDefinition;
entityDefinition[Entities.inputUserCollection] =
  inputUserCollectionEntityDefinition;
entityDefinition[Entities.inputUserCollectionRelation] =
  inputUserCollectionRelationEntityDefinition;
entityDefinition[Entities.inputUserRelation] =
  inputUserRelationEntityDefinition;
entityDefinition[Entities.inputEMailVorlagenRelation] =
  inputEMailVorlagenRelationEntityDefinition;
entityDefinition[Entities.inputEMailVorlagenAnhangRelation] =
  inputEMailVorlagenAnhangRelationEntityDefinition;
entityDefinition[Entities.inputEMailVorlagenAnhangAdd] =
  inputEMailVorlagenAnhangAddEntityDefinition;
entityDefinition[Entities.inputEMailTemplateGroup] =
  inputEMailTemplateGroupEntityDefinition;
entityDefinition[Entities.inputTicketAttachementModify] =
  inputTicketAttachementModifyEntityDefinition;
entityDefinition[Entities.inputEingangsrechnung] =
  inputEingangsrechnungEntityDefinition;
entityDefinition[Entities.inputTextbaustein] =
  inputTextbausteinEntityDefinition;
entityDefinition[Entities.inputTicketFilter] =
  inputTicketFilterEntityDefinition;
entityDefinition[Entities.inputErpAnhangAttachementsRelation] =
  inputErpAnhangAttachementsRelationEntityDefinition;
entityDefinition[Entities.inputErpAnhangAttachementModify] =
  inputErpAnhangAttachementModifyEntityDefinition;
entityDefinition[Entities.inputEingangsrechnungRelation] =
  inputEingangsrechnungRelationEntityDefinition;
entityDefinition[Entities.inputStammdatenPostIt] =
  inputStammdatenPostItEntityDefinition;
entityDefinition[Entities.inputStammdatenPostItDetails] =
  inputStammdatenPostItDetailsEntityDefinition;
entityDefinition[Entities.inputCompanyDefaultErpText] =
  inputCompanyDefaultErpTextEntityDefinition;
entityDefinition[Entities.inputDefaultErpTextAddModify] =
  inputDefaultErpTextAddModifyEntityDefinition;
entityDefinition[Entities.inputModifySupplierDemandAssign] =
  inputModifySupplierDemandAssignEntityDefinition;
entityDefinition[Entities.inputModifyAddSupplierDemandAssign] =
  inputModifyAddSupplierDemandAssignEntityDefinition;
entityDefinition[Entities.inputSavedListFilter] =
  inputSavedListFilterEntityDefinition;
entityDefinition[Entities.inputVerkaufschance] =
  inputVerkaufschanceEntityDefinition;
entityDefinition[Entities.inputVerkaufschanceRelation] =
  inputVerkaufschanceRelationEntityDefinition;
entityDefinition[Entities.inputSalesOpportunityRatingRelation] =
  inputSalesOpportunityRatingRelationEntityDefinition;
entityDefinition[Entities.inputSalesOpportunityRatingSet] =
  inputSalesOpportunityRatingSetEntityDefinition;
entityDefinition[Entities.inputNotificationKey] =
  inputNotificationKeyEntityDefinition;
entityDefinition[Entities.createCrystalReportRequest] =
  createCrystalReportRequestEntityDefinition;
entityDefinition[Entities.crystalReportFormular] =
  crystalReportFormularEntityDefinition;
entityDefinition[Entities.inputBenutzer] = inputBenutzerEntityDefinition;
entityDefinition[Entities.inputSalesOpportunityRatingTemplate] =
  inputSalesOpportunityRatingTemplateEntityDefinition;
entityDefinition[Entities.bzObjectRelation] = bzObjectRelationEntityDefinition;
entityDefinition[Entities.inputSalesOpportunityRatingStatus] =
  inputSalesOpportunityRatingStatusEntityDefinition;
entityDefinition[Entities.copyPosition] = copyPositionEntityDefinition;
entityDefinition[Entities.inputProjectAccessGroup] =
  inputProjectAccessGroupEntityDefinition;
entityDefinition[Entities.inputProjectAccessGroupRelation] =
  inputProjectAccessGroupRelationEntityDefinition;
entityDefinition[Entities.inputProjectAccessGroupUserRelation] =
  inputProjectAccessGroupUserRelationEntityDefinition;
entityDefinition[Entities.inputProjectAccessGroupProjectRelation] =
  inputProjectAccessGroupProjectRelationEntityDefinition;
entityDefinition[Entities.inputProjectAccessGroupGroupRelation] =
  inputProjectAccessGroupGroupRelationEntityDefinition;
entityDefinition[Entities.erpObjectConversionRequest] =
  erpObjectConversionRequestEntityDefinition;
entityDefinition[Entities.erpObjectConversionRequestOptions] =
  erpObjectConversionRequestOptionsEntityDefinition;
entityDefinition[Entities.inputSalesOpportunityAttachementRelation] =
  inputSalesOpportunityAttachementRelationEntityDefinition;
entityDefinition[Entities.inputSalesOpportunityAttachementModify] =
  inputSalesOpportunityAttachementModifyEntityDefinition;
entityDefinition[Entities.duplicateRequest] = duplicateRequestEntityDefinition;
entityDefinition[Entities.inputRa] = inputRaEntityDefinition;
entityDefinition[Entities.inputRaRelation] = inputRaRelationEntityDefinition;
entityDefinition[Entities.inputRaPaymentRelation] =
  inputRaPaymentRelationEntityDefinition;
entityDefinition[Entities.inputRaZahlung] = inputRaZahlungEntityDefinition;
entityDefinition[Entities.inputReRelation] = inputReRelationEntityDefinition;
entityDefinition[Entities.inputRePaymentRelation] =
  inputRePaymentRelationEntityDefinition;
entityDefinition[Entities.inputWordLetterTemplate] =
  inputWordLetterTemplateEntityDefinition;
entityDefinition[Entities.inputReisekostenabrechnungBeleg] =
  inputReisekostenabrechnungBelegEntityDefinition;
entityDefinition[Entities.inputReisekostenabrechnungBelegRelation] =
  inputReisekostenabrechnungBelegRelationEntityDefinition;
entityDefinition[Entities.inputWordDocumentTemplate] =
  inputWordDocumentTemplateEntityDefinition;
entityDefinition[Entities.inputIncomingMailBlockedSender] =
  inputIncomingMailBlockedSenderEntityDefinition;
entityDefinition[Entities.inputSetMfaMode] = inputSetMfaModeEntityDefinition;
entityDefinition[Entities.inputValidateSetMfaMode] =
  inputValidateSetMfaModeEntityDefinition;
entityDefinition[Entities.inputBenutzerRelation] =
  inputBenutzerRelationEntityDefinition;
entityDefinition[Entities.inputProjectDirectoryDefinition] =
  inputProjectDirectoryDefinitionEntityDefinition;
entityDefinition[Entities.inputPromptResult] =
  inputPromptResultEntityDefinition;
entityDefinition[Entities.reportOptionValue] =
  reportOptionValueEntityDefinition;
entityDefinition[Entities.inputProjektVorgang] =
  inputProjektVorgangEntityDefinition;
entityDefinition[Entities.inputProjectStepRelation] =
  inputProjectStepRelationEntityDefinition;
entityDefinition[Entities.inputProjectStepLinkRelation] =
  inputProjectStepLinkRelationEntityDefinition;
entityDefinition[Entities.inputProjectStepLink] =
  inputProjectStepLinkEntityDefinition;
entityDefinition[Entities.bzObjectMutationRequest] =
  bzObjectMutationRequestEntityDefinition;
entityDefinition[Entities.inputSetProjectDirectory] =
  inputSetProjectDirectoryEntityDefinition;
entityDefinition[Entities.inputArtikel] = inputArtikelEntityDefinition;
entityDefinition[Entities.inputArticleRelation] =
  inputArticleRelationEntityDefinition;
entityDefinition[Entities.inputArticleTextRelation] =
  inputArticleTextRelationEntityDefinition;
entityDefinition[Entities.inputArticleTextAddRelation] =
  inputArticleTextAddRelationEntityDefinition;
entityDefinition[Entities.inputArticleTextModifyRelation] =
  inputArticleTextModifyRelationEntityDefinition;
entityDefinition[Entities.inputArticlePrices] =
  inputArticlePricesEntityDefinition;
entityDefinition[Entities.inputArticlePricesSetSimplePrice] =
  inputArticlePricesSetSimplePriceEntityDefinition;
entityDefinition[Entities.inputObjectGroupRelation] =
  inputObjectGroupRelationEntityDefinition;
entityDefinition[Entities.erpObjectConversionPositionDetails] =
  erpObjectConversionPositionDetailsEntityDefinition;
entityDefinition[Entities.inputArticleLedgerAccountsAssignmentsRelation] =
  inputArticleLedgerAccountsAssignmentsRelationEntityDefinition;
entityDefinition[Entities.inputArticleSetLedgerAccountsAssignmentRelation] =
  inputArticleSetLedgerAccountsAssignmentRelationEntityDefinition;
entityDefinition[Entities.inputArticleBomComponents] =
  inputArticleBomComponentsEntityDefinition;
entityDefinition[Entities.inputArticleUpsertBomComponent] =
  inputArticleUpsertBomComponentEntityDefinition;
entityDefinition[Entities.inputReport2] = inputReport2EntityDefinition;
entityDefinition[Entities.invoiceToOutgoingInvoiceLedgerRequest] =
  invoiceToOutgoingInvoiceLedgerRequestEntityDefinition;
entityDefinition[Entities.inputReZahlung] = inputReZahlungEntityDefinition;
entityDefinition[Entities.mutateCustomerGroups] =
  mutateCustomerGroupsEntityDefinition;
entityDefinition[Entities.mutateProjectGroups] =
  mutateProjectGroupsEntityDefinition;
entityDefinition[Entities.mutateSupplierGroups] =
  mutateSupplierGroupsEntityDefinition;
entityDefinition[Entities.mutateArticleGroups] =
  mutateArticleGroupsEntityDefinition;
entityDefinition[Entities.inputGroup] = inputGroupEntityDefinition;
entityDefinition[Entities.inputChecklisteRelation] =
  inputChecklisteRelationEntityDefinition;
entityDefinition[Entities.inputChecklistPositionRelation] =
  inputChecklistPositionRelationEntityDefinition;
entityDefinition[Entities.inputArticleAttachment] =
  inputArticleAttachmentEntityDefinition;
entityDefinition[Entities.inputArticleAttachmentAdd] =
  inputArticleAttachmentAddEntityDefinition;
entityDefinition[Entities.inputArticleAttachmentModify] =
  inputArticleAttachmentModifyEntityDefinition;
entityDefinition[Entities.inputFullUser] = inputFullUserEntityDefinition;
