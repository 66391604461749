import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';

import { usePreviewFeature } from '../../use-check-module-right';
import { useModuleRights } from '../../use-module-rights';
import { checkModuleRight } from '../../useCanView';

import { UsePermissionsRightsReturn } from './types';

export const usePermissionsRights = (): UsePermissionsRightsReturn => {
  const { rights } = useModuleRights();

  const isFullLicenceUser = checkModuleRight(
    rights,
    ModuleAccessRightType.FEATURE_WORK_4_ALL_WEB_FULL
  );

  const canAccessErp =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_ERP_LIGHT);

  const canAccessCrm =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_CRM_LIGHT);

  const canAccessProject =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_PROJECT_LIGHT);

  const canAccessTicket =
    isFullLicenceUser ||
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_TICKETS_LIGHT);

  const canAccessTravelReceipts = checkModuleRight(
    rights,
    ModuleAccessRightType.FEATURE_RECEIPTS_TRAVEL
  );

  const hasPreviewFeature = usePreviewFeature();

  const isErpLightUser =
    checkModuleRight(rights, ModuleAccessRightType.FEATURE_ERP_LIGHT) &&
    !isFullLicenceUser;

  return {
    canAccessErp,
    canAccessCrm,
    canAccessProject,
    canAccessTicket,
    canAccessTravelReceipts,
    hasPreviewFeature,
    isErpLightUser,
  };
};
