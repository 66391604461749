import styles from './FileListGalleryItem.module.scss';

import { Typography } from '@mui/material';
import { useContext } from 'react';
import sanitize from 'sanitize-html';

import { AttachmentRenameContext } from './attachment-rename-context';
import { FileListGalleryItemThumbnail } from './FileListGalleryItemThumbnail';
import { FileAndThumbnail } from './types';

export interface FileListGalleryItemProps {
  file: FileAndThumbnail;
  onClick: () => void;
}

export function FileListGalleryItem(props: FileListGalleryItemProps) {
  const { file, onClick } = props;

  const renameContext = useContext(AttachmentRenameContext);
  const description = renameContext.getNote(file.id);

  return (
    <div className={styles.root}>
      <div className={styles.thumbnail} tabIndex={0} onClick={onClick}>
        <FileListGalleryItemThumbnail file={file} />
      </div>

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: sanitize(description) }}
        className={styles.itemText}
      />
    </div>
  );
}
