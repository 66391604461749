import { Box } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatAsFloat } from '@work4all/utils';

export const useProjectTimeTrackingColumn = (props: {
  projectList: Project[];
}) => {
  const { projectList } = props;

  const projectIdList = useMemo(() => {
    return [
      ...projectList,
      ...projectList.flatMap((pr) => pr?.childProjects || []),
    ]?.map((pr) => pr?.id);
  }, [projectList]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        amount: null,
        user: {
          id: null,
          displayName: null,
        },
        projectId: null,
      } as TimeTracking,
      entity: Entities.timeTracking,
      filter: [
        {
          projectId: {
            $in: projectIdList,
          },
        },
      ],
    };
  }, [projectIdList]);

  const { data } = useDataProvider<TimeTracking>(request);

  const result = useMemo(
    () => ({
      title: ['COMMON.TRACKED_PROJECT_TIME'],
      displayTitle: 'Erfasste Zeit',
      accessor: (item: Project) => {
        const relevantProject = projectList.find((x) => x.id === item.id);
        if (!relevantProject) {
          return;
        }
        const relevantProjectIds = [
          relevantProject,
          ...(relevantProject.childProjects || []),
        ]?.map((pr) => pr?.id);
        const total = data
          .filter((x) => relevantProjectIds.includes(x.projectId))
          ?.map((x) => x.amount)
          ?.reduce((a, b) => a + b, 0);

        return (
          <Box textAlign="right">
            {total
              ? formatAsFloat(total / 8, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 3,
                })
              : null}{' '}
          </Box>
        );
      },
      id: 'projectTimeTracking',
      path: ['MORE.CONTROLLING'],
      width: 100,
      sortable: false,
      groupable: false,
      quickSearchable: false,
      defaultHidden: true,
      filterable: {
        type: 'None',
      },
      disableFooterSum: false,
    }),
    [data, projectList]
  );

  return result;
};
