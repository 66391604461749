import styles from './styles.module.scss';

import { Edit } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { formatAsFloat } from '@work4all/utils';

import { settings, useSetting } from '../../../settings';

import { PositionCorrection } from './components/PositionCorrection';
import { useResourceClasses } from './use-resource-classes';
import { getFactor } from './utils';

export const useErpPlanningColumns = (props: { entity: Entities }) => {
  const { entity } = props;
  const { data: ressourceClasses } = useResourceClasses();

  const resourcePlanningColoumnTimeUnit = useSetting(
    settings.resourcePlanningColoumnTimeUnit()
  );

  const result = useMemo(
    () =>
      ressourceClasses.map((rc) => ({
        title: [rc.name],
        displayTitle: rc.name,
        accessor: (item: ERPTypes) => {
          const positions =
            item.positionList?.filter(
              (pos) => pos?.article?.ressourceClassId === rc.id
            ) ?? [];

          const total = positions
            ?.map((x) => {
              const factor = getFactor(
                x.unit,
                resourcePlanningColoumnTimeUnit.value
              );
              return x.amount * factor;
            })
            ?.reduce((a, b) => {
              return a + b;
            }, 0);

          return (
            <ErpRessourceClassCell
              value={total}
              positions={positions}
              entity={entity}
              parentEntity={
                item.businessPartnerType === SdObjType.KUNDE
                  ? Entities.customer
                  : Entities.supplier
              }
              parentId={item.businessPartnerId}
              bzObjId={item.id}
            />
          );
        },
        id: rc.id.toString(),
        path: ['COMMON.RESSOURCECLASS_plural'],
        width: 200,
        sortable: false,
        groupable: false,
        quickSearchable: false,
        defaultHidden: true,
        filterable: {
          type: 'None',
        },
        disableFooterSum: false,
      })),
    [entity, resourcePlanningColoumnTimeUnit.value, ressourceClasses]
  );

  return result;
};

export const ErpRessourceClassCell = (props: {
  value: number;
  positions: Position[];
  entity: Entities;
  parentEntity: Entities.customer | Entities.supplier;
  parentId: number;
  bzObjId: number;
}) => {
  const { value, positions, entity, parentEntity, parentId, bzObjId } = props;
  const [showPositionCorrection, setShowPositionCorrection] = useState(false);
  const [loading, setLoading] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <Box ref={cellRef} textAlign="center">
        {loading ? (
          <CircularProgress size="1rem" />
        ) : (
          <Box
            className={styles.cellWrap}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowPositionCorrection(true);
            }}
          >
            <Typography flex="1">
              {value
                ? formatAsFloat(value, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })
                : ' '}
              {value && <Edit className={styles.editIcon} />}
            </Typography>
          </Box>
        )}
      </Box>

      {showPositionCorrection && (
        <PositionCorrection
          positions={positions}
          entity={entity}
          parentEntity={parentEntity}
          parentId={parentId}
          bzObjId={bzObjId}
          cellRef={cellRef}
          onEditStart={() => {
            setLoading(true);
          }}
          onEditComplete={() => {
            setLoading(false);
            setShowPositionCorrection(false);
          }}
        />
      )}
    </>
  );
};
