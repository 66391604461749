import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type ILedgerAccountPickerProps<TMultiple extends boolean> = IPickerProps<
  LedgerAccount,
  TMultiple
>;

export function LedgerAccountPicker<TMultiple extends boolean>(
  props: ILedgerAccountPickerProps<TMultiple>
) {
  const { data, prefilter, ...rest } = props;

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.ledgerAccount,
      data: {
        ...LEDGER_ACCOUNT_FIELDS,
        ...data,
      },
      filter: prefilter,
    };
  }, [data, prefilter]);

  const result = useDataProvider<LedgerAccount>(requestData, false, 9999);

  const dataSet = useMemo(() => {
    return [...result.data].sort((ac: LedgerAccount, ac2: LedgerAccount) => {
      return ac.number > ac2.number ? 1 : -1;
    });
  }, [result.data]);

  const renderItem = (ledgerAccount: LedgerAccount) => {
    const label = `${ledgerAccount?.number} | ${ledgerAccount?.name}`;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <FixedDataPicker<TMultiple, LedgerAccount>
      value={rest.value}
      dataSet={dataSet}
      renderItemContent={renderItem}
      {...rest}
    />
  );
}

const LEDGER_ACCOUNT_FIELDS: LedgerAccount = {
  id: null,
  name: null,
  number: null,
  short: null,
  disabled: null,
};
