import { useEventCallback } from '@mui/material/utils';

import { useDialogs } from '@work4all/components';
import {
  OpenEmailMaskControllerContext,
  OpenEmailMaskControllerProps,
} from '@work4all/components/lib/hooks/contact-email-mask/OpenEmailMaskControllerContext';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { sdObjTypeToEntity, typeNameToEntity } from '@work4all/utils';

import { EmailOverlayController } from '../../../mask-overlays/mask-overlay/views/email/EmailOverlayController';

interface OpenEmailMaskControllerProviderProps {
  children: React.ReactNode;
}

export const OpenEmailMaskControllerProvider = ({
  children,
}: OpenEmailMaskControllerProviderProps) => {
  const dialogs = useDialogs();

  const openEmailMaskController = useEventCallback(
    (contactEmailData: OpenEmailMaskControllerProps) => {
      if (!contactEmailData) return;

      const { businessPartner, contactId, email, project } = contactEmailData;

      const entity =
        sdObjTypeToEntity(businessPartner?.businessPartnerType) ??
        typeNameToEntity(businessPartner?.__typename);

      const entityTemplate = businessPartner
        ? {
            entity: contactId ? Entities.contact : entity,
            id: contactId
              ? `${contactId}:customer:${businessPartner?.id}`
              : businessPartner?.id,
          }
        : undefined;

      const initialView: IStackItem = {
        view: (
          <EmailOverlayController
            entity={Entities.eMail}
            id={null}
            template={entityTemplate}
            onAfterSave={closeMaskOverlay}
            params={{
              overrideRecipients: email,
              processedMailTemplateArgs: JSON.stringify({
                noDirectContact: !contactId,
                project,
              }),
            }}
          />
        ),
      };

      const dialog = dialogs.open(NavigationOverlay, {
        initialView: initialView,
        close() {
          dialogs.close(dialog.id);
        },
      });

      function closeMaskOverlay() {
        dialogs.close(dialog.id);
      }
    }
  );

  return (
    <OpenEmailMaskControllerContext.Provider value={openEmailMaskController}>
      {children}
    </OpenEmailMaskControllerContext.Provider>
  );
};
