import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMailKind } from '../Enums/EMailKind.enum';
import { EMailPriority } from '../Enums/EMailPriority.enum';
import { EMailTypeSimple } from '../Enums/EMailTypeSimple.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { EMailAttachment } from './EMailAttachment.entity';
import { Project } from './Project.entity';
import { SaveSendMailJob } from './SaveSendMailJob.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class EMail<T extends EMode = EMode.entity> {
  /** Alias: anhaenge */
  attachmentList?: EMailAttachment<T>[];
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: art */
  kind?: EMailKind;
  /** Alias: bcc */
  bcc?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: betreff */
  subject?: string;
  /** Alias: body */
  body?: string;
  /** Alias: bodyHtml */
  bodyHtml?: string;
  /** Alias: cc */
  cc?: string;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: erstellerBenutzerCode */
  creatorUserId?: number;
  /** Alias: from */
  from?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: mimeContent */
  mimeContent?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: personalisiert */
  personalisiert?: number;
  /** Alias: prioritaet */
  priority?: EMailPriority;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: ticketId */
  ticketId?: string;
  /** Alias: to */
  to?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: typeSimple */
  typeSimple?: EMailTypeSimple;
  /** Alias: toMailContacts */
  toMailContacts?: string;
  /** Alias: ccMailContacts */
  ccMailContacts?: string;
  /** Alias: bccMailContacts */
  bccMailContacts?: string;
  /** Alias: saveSendMailJobID */
  saveSendMailJobID?: string;
  /** Alias: saveSendMailJob */
  saveSendMailJob?: SaveSendMailJob<T>;
  /** Alias: rewrittenBodyHtml */
  rewrittenBodyHtml?: string;

  __typename?: string;
}

const fields: FieldDefinitions<EMail> = {
  attachmentList: {
    alias: 'anhaenge',
    entity: Entities.eMailAttachment,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  kind: {
    alias: 'art',
  },
  bcc: {
    alias: 'bcc',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  subject: {
    alias: 'betreff',
  },
  body: {
    alias: 'body',
  },
  bodyHtml: {
    alias: 'bodyHtml',
  },
  cc: {
    alias: 'cc',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  creatorUserId: {
    alias: 'erstellerBenutzerCode',
  },
  from: {
    alias: 'from',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  insertTime: {
    alias: 'insertTime',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  mimeContent: {
    alias: 'mimeContent',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  personalisiert: {
    alias: 'personalisiert',
  },
  priority: {
    alias: 'prioritaet',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  ticketId: {
    alias: 'ticketId',
  },
  to: {
    alias: 'to',
  },
  updateTime: {
    alias: 'updateTime',
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  typeSimple: {
    alias: 'typeSimple',
  },
  toMailContacts: {
    alias: 'toMailContacts',
  },
  ccMailContacts: {
    alias: 'ccMailContacts',
  },
  bccMailContacts: {
    alias: 'bccMailContacts',
  },
  saveSendMailJobID: {
    alias: 'saveSendMailJobID',
  },
  saveSendMailJob: {
    alias: 'saveSendMailJob',
    entity: Entities.saveSendMailJob,
  },
  rewrittenBodyHtml: {
    alias: 'rewrittenBodyHtml',
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailEntityDefinition: EntitiyDefinition<EMail> = {
  local: {},
  remote: {
    queryName: 'getEmails',
    fragmentName: 'EMail',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertEMail',
        args: [
          { name: 'input', type: 'InputEMail!' },
          { name: 'relations', type: 'InputEMailRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'rewriteInlineAttachements', type: 'Boolean' },
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
