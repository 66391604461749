import styles from './ResourcesTimeline.module.scss';

import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscResource,
} from '@mobiscroll/react';
import { Close, Groups, Save, Search, Settings } from '@mui/icons-material';
import { Box, IconButton, Popover, Stack } from '@mui/material';
import { popoverClasses } from '@mui/material/Popover';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ResourceUtilizationInfo } from '@work4all/models/lib/Classes/ResourceUtilizationInfo.entity';
import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  AppointmentQuickCreateDialog,
  AppointmentTemplate,
} from '../../../../../../../containers/calendar/components/AppointmentQuickCreateDialog';
import { CreateViewDialog } from '../../../../../../../containers/calendar/components/CreateViewDialog';
import { ViewsList } from '../../../../../../../containers/calendar/components/ViewsList';
import { useCalendarViews } from '../../../../../../../containers/calendar/hooks/use-calendar-views';
import { mapUsersToAppointmentAttendees } from '../../../../../../../containers/calendar/utils/map-users-to-appointment-attendees';
import { settings, useSetting } from '../../../../../../../settings';
import { UserCompilationPicker } from '../../../../../../entity-picker/UsersCompilationPicker';
import { TimeUnit } from '../project-planning-view/components/time-unit-switch/TimeUnitSwitch';
import { ZoomValue } from '../project-planning-view/components/timeline-zoom/TimelineZoom';
import { useColors } from '../project-planning-view/hooks/use-colors';
import {
  ZoomableTimeline,
  ZoomableTimelineProps,
} from '../zoomable-timeline/ZoomableTimeline';

import { ResourceUtilizationInfoDialog } from './components/resource-utilization-info-dialog/ResourceUtilizationInfoDialog';
import { ResourcesTimelineToolbar } from './components/resources-timeline-toolbar/ResourcesTimelineToolbar';
import { useUsersById } from './hooks/get-users-by-id';
import { useResourceInfo } from './hooks/use-resource-info';
import { useUtilizationEvents } from './hooks/use-utilization-events';

export interface DateRangeConf {
  start: Date;
  end: Date;
}

type ResourcesTimelineProps = Pick<
  ZoomableTimelineProps,
  | 'compactView'
  | 'date'
  | 'leftAreaCollapsed'
  | 'leftAreaWidth'
  | 'onSelectedDateChange'
  | 'componentRef'
  | 'zoom'
  | 'view'
  | 'onDestroy'
  | 'onPageLoaded'
> & {
  className?: string;
  highlightedDateRanges: DateRangeConf[];
  focusedProject?: Project;
  focusedProjectProcess?: ProjectProcess;
  userIds?: number[];
  onUsersChange?: (users: User[]) => void;
  showToolbar?: boolean;
  zoom?: ZoomValue;
  unit?: 'year' | 'day' | 'week' | 'month';
  onZoomChange?: (zoom: ZoomValue) => void;
  onTimeUnitChange?: (unit: TimeUnit) => void;
};

export const ResourcesTimeline = (props: ResourcesTimelineProps) => {
  const {
    date: initialDate = new Date(),
    className,
    highlightedDateRanges,
    focusedProjectProcess,
    focusedProject,
    userIds: userIdsInitial,
    onUsersChange,
    showToolbar = false,
    zoom = 3,
    onZoomChange,
    unit = 'year',
    onTimeUnitChange,
    ...rest
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [events, setEvents] = useState<MbscCalendarEvent[]>([]);
  const [appointmentTemplate, setAppointmentTemplate] =
    useState<AppointmentTemplate>({});
  const [attendeeSuggestions, setAttendeeSuggestions] = useState<
    AppointmentAttendee[]
  >([]);

  const [usersDialogOpen, setUsersDialogOpen] = useState(false);
  const [teamsDialogOpen, setTeamsDialogOpen] = useState(false);

  const { t } = useTranslation();

  const userSearchBtnRef = useRef(null);
  const teamsButton = useRef(null);

  const [date, setDate] = useState(initialDate);

  const resourcePlanningUtilizationTimeUnit = useSetting(
    settings.resourcePlanningUtilizationTimeUnit()
  );

  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);

  const startDate = DateTime.fromJSDate(date).startOf('year').toJSDate();
  const endDate = DateTime.fromJSDate(date).endOf('year').toJSDate();

  const { views, createView, deleteView } = useCalendarViews();

  const { data: userList } = useUsersById({
    idList: userIdsInitial,
  });

  const resourceInfoRes = useResourceInfo({
    endDate,
    startDate,
    userIdList: userList.map((user) => user.id),
  });

  const utilizationData = resourceInfoRes.data;

  const initialEvents = useUtilizationEvents({
    utilizationData: utilizationData,
  });

  useEffect(() => {
    setEvents(initialEvents);
  }, [initialEvents]);

  const [createViewDialogOpen, setCreateViewDialogOpen] = useState(false);

  const onCreateView = useCallback(
    (name: string) => {
      createView({
        name,
        params: {
          appointmentStates: [],
          focusedUserIds: [],
          groupMode: 'vertical',
          range: 'month',
          userIds: userList.map((user) => user.id),
        },
      });
    },
    [createView, userList]
  );

  const settingsPopoverState = usePopoverState('bottom');
  const settingsButtonRef = useRef(null);

  const renderResourceHeader = useCallback(() => {
    return (
      <Stack direction="row" p="0.125rem" pr={0} gap="0.5rem">
        <Popover {...settingsPopoverState}>
          <Stack padding="0 1rem 0.5rem 1rem" gap="0.5rem">
            <Divider title={t('COMMON.CALCULATION')} />
            <CheckboxRadioItem
              label={t('COMMON.DAY')}
              checked={resourcePlanningUtilizationTimeUnit.value === 'day'}
              onChange={() => resourcePlanningUtilizationTimeUnit.set('day')}
              control="radio"
              horizontalPadding={false}
            />
            <CheckboxRadioItem
              label={t('COMMON.WEEK')}
              checked={resourcePlanningUtilizationTimeUnit.value === 'week'}
              onChange={() => resourcePlanningUtilizationTimeUnit.set('week')}
              control="radio"
              horizontalPadding={false}
            />
            <CheckboxRadioItem
              label={t('COMMON.MONTH')}
              checked={resourcePlanningUtilizationTimeUnit.value === 'month'}
              onChange={() => resourcePlanningUtilizationTimeUnit.set('month')}
              control="radio"
              horizontalPadding={false}
            />
          </Stack>
        </Popover>

        <Box width="100%" ref={userSearchBtnRef}>
          <BaseActionButton
            icon={<Search />}
            title={t('COMMON.CHOOSE', { name: t('COMMON.USER_RESOURCE') })}
            onClick={() => {
              setUsersDialogOpen(true);
            }}
          />
        </Box>

        <Stack ref={teamsButton} direction="row" alignContent="center">
          <Tooltip title={t('COMMON.TEAMS')}>
            <IconButton
              size="small"
              onClick={() => {
                setTeamsDialogOpen(true);
              }}
            >
              <Groups />
            </IconButton>
          </Tooltip>
        </Stack>
        <Tooltip title={t('CALENDAR.VIEWS.SAVE_VIEW')}>
          <IconButton
            size="small"
            onClick={() => {
              setCreateViewDialogOpen(true);
            }}
            disabled={userList.length === 0}
          >
            <Save />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('COMMON.SETTINGS')}>
          <IconButton
            size="small"
            onClick={settingsPopoverState.handleClick}
            disabled={userList.length === 0}
            ref={settingsButtonRef}
          >
            <Settings />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('INPUTS.CLEAR_ALL')}>
          <IconButton
            disabled={userList.length === 0}
            size="small"
            onClick={() => {
              onUsersChange?.([]);
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }, [
    settingsPopoverState,
    t,
    resourcePlanningUtilizationTimeUnit,
    userList.length,
    onUsersChange,
  ]);

  const renderResource = useCallback(
    (resource: MbscResource) => {
      const user = userList.find((x) => x.id === resource.id);
      return !user ? (
        <div>{resource.id}</div>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          p="0.25rem 0.5rem"
          gap="0.5rem"
          pr="0"
          width="100%"
        >
          <HookedUserIcon userId={user.id} size="m" />
          <Box width="100%">{user.displayName || user.shortName}</Box>
          <IconButton
            size="small"
            onClick={() => {
              onUsersChange?.(userList.filter((x) => x.id !== user.id));
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      );
    },
    [userList, onUsersChange]
  );

  const resources = useMemo<MbscResource[]>(() => {
    return userList.map(
      (user) => ({ id: user.id, name: user.displayName } as MbscResource)
    );
  }, [userList]);

  const defaultColors = useColors({ resources });

  const colors = useMemo<MbscCalendarColor[]>(() => {
    const statusColors = utilizationData.map((item) => {
      return {
        start: item.date,
        end: item.date,
        resource: item.userId,
        background:
          resourcePlanningUtilizationTimeUnit.value === 'day' &&
          item.utilizedValue >= item.planedValue
            ? 'var(--userShade7)'
            : undefined,
      } as MbscCalendarColor;
    });

    const highlightColors = resources
      .map((resource) => {
        return highlightedDateRanges
          .map((item) => {
            const result = [];
            const currentDate = new Date(item.start);
            while (currentDate <= new Date(item.end)) {
              result.push({
                start: DateTime.fromJSDate(currentDate)
                  .startOf('day')
                  .toJSDate(),
                end: DateTime.fromJSDate(currentDate).endOf('day').toJSDate(),
                resource: resource.id,
                background: 'var(--userShade5)',
              } as MbscCalendarColor);
              currentDate.setDate(currentDate.getDate() + 1);
            }

            return result;
          })
          .flat();
      })
      .flat();

    return [...defaultColors, ...highlightColors, ...statusColors];
  }, [
    resources,
    defaultColors,
    resourcePlanningUtilizationTimeUnit.value,
    highlightedDateRanges,
    utilizationData,
  ]);

  const handleOnEventCreated = useCallback(
    (args: MbscEventCreatedEvent) => {
      const { event } = args;

      const primaryUsers = (
        userList.length === 1
          ? userList
          : userList.filter((u) => u.id === event.resource)
      ).map((user) => ({ ...user, __typename: undefined }));

      const optionalUsers = userList.map((user) => ({
        ...user,
        __typename: undefined,
      }));

      const primaryAppointmentAttendeeList =
        mapUsersToAppointmentAttendees(primaryUsers);
      const optionalAppointmentAttendeeList =
        mapUsersToAppointmentAttendees(optionalUsers);

      const process =
        (event.process as ProjectProcess) || focusedProjectProcess;
      const project = process?.project || focusedProject;

      const businessPartnerType = process?.customerId
        ? SdObjType.KUNDE
        : process?.supplierId
        ? SdObjType.LIEFERANT
        : undefined;
      const businessPartnerId = process?.customerId || process?.supplierId;

      const template: AppointmentTemplate = {
        appointmentAttendeeList: primaryAppointmentAttendeeList,
        startDate: DateTime.fromJSDate(event.start as Date).toISO(),
        endDate: DateTime.fromJSDate(event.end as Date).toISO(),
        isWholeDay: true,
        title: event.title,
        project: project,
        projectId: project?.id,
        businessPartner: businessPartnerType
          ? {
              businessPartnerType,
              data: process?.customer || process?.supplier,
              id: businessPartnerId,
            }
          : undefined,
        businessPartnerId,
        businessPartnerType,
        projectProcess: process,
        projectProcessId: process?.id,
        isRealAppointment: false,
      };

      setAppointmentTemplate(template);
      setAttendeeSuggestions(optionalAppointmentAttendeeList);
      setDialogOpen(true);
      setEvents(cloneDeep(events));
    },
    [events, focusedProject, focusedProjectProcess, userList]
  );

  const [resourceUtilizationInfoOpen, setResourceUtilizationInfoOpen] =
    useState(false);
  const [resourceUtilizationInfoData, setResourceUtilizationInfoData] =
    useState<{
      vacationList: Vacation[];
      absenceList: Sickness[];
      appointmentList: Appointment[];
    }>(null);

  const handleEventClick = useCallback((args: MbscEventClickEvent) => {
    const data = args.event.data as ResourceUtilizationInfo[];
    const params = {
      vacationList: data.flatMap((x) => x.vacationList),
      absenceList: data.flatMap((x) => x.absenceList),
      appointmentList: data.flatMap((x) => x.appointmentList),
    };

    setResourceUtilizationInfoData(params);
    setResourceUtilizationInfoOpen(true);
  }, []);

  return (
    <>
      <ResourceUtilizationInfoDialog
        open={resourceUtilizationInfoOpen}
        onClose={() => setResourceUtilizationInfoOpen(false)}
        {...resourceUtilizationInfoData}
      />
      <CreateViewDialog
        open={createViewDialogOpen}
        onClose={() => setCreateViewDialogOpen(false)}
        onConfirm={(name) => {
          setCreateViewDialogOpen(false);
          onCreateView(name);
        }}
        options={views.map((x) => x.name)}
      />
      <AppointmentQuickCreateDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          resourceInfoRes.refetch();
        }}
        onOpenMask={() => null}
        template={appointmentTemplate}
        attendeeSuggestions={attendeeSuggestions}
      />

      <Popover
        open={usersDialogOpen}
        onClose={() => setUsersDialogOpen(false)}
        anchorEl={userSearchBtnRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          [`.${popoverClasses.paper}`]: {
            height: 500,
          },
        }}
      >
        <UserCompilationPicker
          value={userList}
          onChange={onUsersChange}
          autoFocusTextInput
        />
      </Popover>

      <Popover
        open={teamsDialogOpen}
        onClose={() => setTeamsDialogOpen(false)}
        anchorEl={teamsButton.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ViewsList
          views={views}
          activeView={null}
          onSelectView={(view) => {
            onUsersChange?.([]);
            setTimeout(() => {
              onUsersChange?.(view.params.userIds.map((id) => ({ id })));
              setTeamsDialogOpen(false);
            });
          }}
          onDeleteView={(view) => deleteView(view.id)}
          title={t('COMMON.TEAMS')}
        />
      </Popover>

      {showToolbar && (
        <ResourcesTimelineToolbar
          onDateChange={(e) => setDate?.(e)}
          unit={unit}
          date={date}
          zoom={zoom}
          onZoomChange={onZoomChange}
          onTimeUnitChange={onTimeUnitChange}
        />
      )}
      <ZoomableTimeline
        events={events}
        showControls={false}
        renderResourceHeader={renderResourceHeader}
        renderResource={renderResource}
        resources={resources}
        colors={colors}
        externalDrop
        dragToMove={false}
        onEventCreated={handleOnEventCreated}
        className={clsx(styles.resourceTimeline, className || '')}
        date={date}
        dragToCreate={true}
        newEventText={t('COMMON.NEW_PLANNING_APPOINTMENT')}
        onEventClick={handleEventClick}
        zoom={zoom}
        {...rest}
      />
    </>
  );
};
