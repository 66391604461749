import { SortDirection, SortOption } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const taskSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const ticketSortOptions: SortOption[] = [
  {
    field: 'followUpDate',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const offerSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const productionContractSortOptions: SortOption[] = [
  {
    field: 'creationDate',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const appointmentSortOptions: SortOption[] = [
  {
    field: 'startDate',
    direction: SortDirection.DESCENDING,
  },
];

const checklistSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const noteSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const projectSortOptions: SortOption[] = [
  {
    field: 'startDateInner',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const callMemoSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const salesOpportunitiesSortOptions: SortOption[] = [
  {
    field: 'dateCreated',
    direction: SortDirection.DESCENDING,
  },
];

const contractSortOptions: SortOption[] = [
  {
    field: 'dateCreated',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const calculationSortOptions: SortOption[] = [
  {
    field: 'dateCreated',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const deliveryNoteSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const invoiceSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const visitationReportSortOptions: SortOption[] = [
  {
    field: 'creationDate',
    direction: SortDirection.DESCENDING,
  },
];

const letterSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const documentSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const emailSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const demandSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const orderSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const travelExpensesSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const travelReceiptsSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

const reSortOptions: SortOption[] = [
  {
    field: 'invoiceDate',
    direction: SortDirection.DESCENDING,
  },
];

const raSortOptions: SortOption[] = [
  {
    field: 'invoiceDate',
    direction: SortDirection.DESCENDING,
  },
];

const inboundDeliveryNoteSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
  {
    field: 'number',
    direction: SortDirection.DESCENDING,
  },
];

const timeTrackingSortOptions: SortOption[] = [
  {
    field: 'date',
    direction: SortDirection.DESCENDING,
  },
];

export const widgetSortOptionsByEntity: Partial<
  Record<Entities, SortOption[]>
> = {
  [Entities.task]: taskSortOptions,
  [Entities.ticket]: ticketSortOptions,
  [Entities.offer]: offerSortOptions,
  [Entities.order]: orderSortOptions,
  [Entities.productionContract]: productionContractSortOptions,
  [Entities.appointment]: appointmentSortOptions,
  [Entities.checkList]: checklistSortOptions,
  [Entities.note]: noteSortOptions,
  [Entities.project]: projectSortOptions,
  [Entities.callMemo]: callMemoSortOptions,
  [Entities.salesOpportunities]: salesOpportunitiesSortOptions,
  [Entities.contract]: contractSortOptions,
  [Entities.calculation]: calculationSortOptions,
  [Entities.deliveryNote]: deliveryNoteSortOptions,
  [Entities.invoice]: invoiceSortOptions,
  [Entities.visitationReport]: visitationReportSortOptions,
  [Entities.letter]: letterSortOptions,
  [Entities.document]: documentSortOptions,
  [Entities.eMail]: emailSortOptions,
  [Entities.demand]: demandSortOptions,
  [Entities.travelExpenses]: travelExpensesSortOptions,
  [Entities.travelReceipts]: travelReceiptsSortOptions,
  [Entities.reViewModel]: reSortOptions,
  [Entities.raViewModel]: raSortOptions,
  [Entities.inboundDeliveryNote]: inboundDeliveryNoteSortOptions,
  [Entities.timeTracking]: timeTrackingSortOptions,
};
